import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { Card, Button, Table, Dropdown, ButtonGroup, Col, Row } from '@themesberg/react-bootstrap';
import { confirmArchiveDialog, confirmArchiveDialogEmptyCallback } from '../components/Alerts';
import { faEdit, faEllipsisH, faEye, faArchive, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalPagination from '../components/PortalPagination';
import clientApi from '../services/client-service';
import { showError } from '../components/Alerts';
import ClientForm from './clientform';
import { ClientProfileCardWidget } from '../components/Widgets';
import { hasPermission } from '../auth/UserPermissionTypes';
import { ClientUploadPhoto } from './clientuploadphoto';
import ClientSpecialRates from './clientspecialrates';
import { CLIENT_MANAGER_DELETE, CLIENT_MANAGER_MANAGE_AGREEMENTS, CLIENT_MANAGER_UPDATE, CLIENT_MANAGER_VIEW_PAYMENT_TERMS, CLIENT_MANAGER_VIEW_SPECIAL_RATES } from '../utils/constants';
import ClientArchiveReason from './clientarchivereason';
import ClientAddSpecialRate from './clientaddspecialrate';
import './styles.scss'
import ClientPaymentTerms from './clientpaymentterms';

const ClientDatatable = (props) => {
  const { data, selectedPage, pagingEvent, refreshList } = props;
  const loading = useSelector((state) => state.blanket.showAdminLoader);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);
  const totalRecords = useSelector((state) => state.clientState.count) || 0;
  const { 
    getClientInfo, 
    getClientAgreements, 
    setClientStatus, 
    getClientSpecialRates, 
    setSpecialRateStatus,
    getActivePaymentTerms,
    getClientPaymentTerms
  } = clientApi;
  const [clientProfile, setClientProfile] = useState(null);
  const [clientAgreements, setClientAgreements] = useState([]);
  const [showArchiveReason, setShowArchiveReason] = useState(false);
  const [clientToArchive, setClientToArchive] = useState(null);
  const [showAddClientSpecialRate, setShowAddClientSpecialRate] = useState(false);
  const [clientSpecialRates, setClientSpecialRates] = useState([]);
  const [toEditSpecialRate, setToEditSpecialRate] = useState(null);
  const [clientPaymentTerms, setClientPaymentTerms] = useState([]);
  const [activePaymentTermsForClient, setActivePaymentTermsForClient] = useState([]);

  const handleViewClientInfo = (id) => {
    setSelectedClientId(id);
    setIsEditMode(false);

    getClientInfo(id).then((data) => {
      if (data.error) {
        showError(data.error);
      }

      setClientProfile(data);
      fetchClientDocuments(id);
    });
  };

  const handleEditClient = (id) => {
    setSelectedClientId(id);
    setIsEditMode(true);

    getClientInfo(id).then((data) => {
      if (data.error) {
        showError(data.error);
      }

      setClientProfile(data);
      fetchClientDocuments(id);
    });
  };

  const handleCancelClientProfile = () => {
    setSelectedClientId(null);
    setClientProfile(null);
  };

  const handleSetClientStatus = async (id) => {
    let text = 'Do you want to archive this client?';
    let confirmBtnText = 'Yes, archive it!';

    var statusConfirm = await confirmArchiveDialogEmptyCallback(text, confirmBtnText);
    if (statusConfirm) {
      setShowArchiveReason(true);
      setClientToArchive(id);
    }
  };

  const handleCancelArchive = () => {
    setShowArchiveReason(false);
    setClientToArchive(null);
  };

  const handleReactivateClient = async (id, status) => {
    let text = 'Do you want to re-activate this client?';
    let confirmBtnText = 'Yes, re-activate it!';
    let callbackTitle = 'Activated!';
    let callbackText = 'client has been Activated!';

    var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
    if (statusConfirm) {
      let clientData = new FormData();

      clientData.append('clientId', id);
      clientData.append('status', status);

      setClientStatus(clientData).then(() => {
        refreshList();
      });
    }
  };

  const setFullname = (data) => {
    return `${data.firstName} ${data.lastName}`;
  };

  const getFullName = () => {
    if (clientProfile)
      return `${clientProfile.firstName} ${clientProfile.lastName}`;
    return '';
  };

  const fetchClientDocuments = (clientId) => {
    if(hasPermission(CLIENT_MANAGER_MANAGE_AGREEMENTS)){
      getClientAgreements(clientId)
      .then((data) => {
        setClientAgreements(data);
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  const handleAddClientSpecialRate = () => {
    setShowAddClientSpecialRate(true);
  };

  const cancelAddSpecialRate = () => {
    setShowAddClientSpecialRate(false);
  };

  const fetchClientSpecialRates = useCallback(
    (clientId) => {
      getClientSpecialRates(clientId)
        .then((data) => {
          setClientSpecialRates(data.result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [getClientSpecialRates] // this ensures that the dependency is stable
  );

  const fetchActivePaymentTerms = useCallback(
    (clientId) => {
      getActivePaymentTerms(clientId)
        .then((data) => {
          setActivePaymentTermsForClient(data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [setActivePaymentTermsForClient] // this ensures that the dependency is stable
  );

  const fetchClientPaymentTerms = useCallback(
    (clientId) => {
      getClientPaymentTerms(clientId)
        .then((data) => {
          setClientPaymentTerms(data.result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [setClientPaymentTerms] // this ensures that the dependency is stable
  ); 

  const handleEditSpecialRate = (rateData) => {
    setShowAddClientSpecialRate(true);
    setToEditSpecialRate(rateData);
  };

  const specialRateStatusUpdate = (clientSpecialRateId, status) => {
    let formData = new FormData();

    formData.append('clientSpecialRateId', clientSpecialRateId);
    formData.append('status', status);

    setSpecialRateStatus(formData).then(() => {
      fetchClientSpecialRates(selectedClientId);
    });
  };

  useEffect(() => {
    if (selectedClientId) {
      fetchClientSpecialRates(selectedClientId);
    }
  }, [selectedClientId, fetchClientSpecialRates]);

  useEffect(() => {
    if (selectedClientId) {
      fetchActivePaymentTerms(selectedClientId);
    }
  }, [selectedClientId, fetchActivePaymentTerms]);

  useEffect(() => {
    if (selectedClientId) {
      fetchClientPaymentTerms(selectedClientId);
    }
  }, [selectedClientId, fetchClientPaymentTerms]);

  return (
    <>
      {showArchiveReason && (
        <ClientArchiveReason clientId={clientToArchive} cancelEvent={handleCancelArchive} refreshList={refreshList} />
      )}

      {showAddClientSpecialRate && (
        <ClientAddSpecialRate
          clientRateData={toEditSpecialRate}
          clientId={selectedClientId}
          cancelEdit={cancelAddSpecialRate}
          refreshList={fetchClientSpecialRates}
        />
      )}
      <Card border="light" className="table-wrapper table-responsive shadow-sm no-border-radius">
        <Card.Body className="pt-0">
          {data.length === 0 ? (
            <div className="no-result-box">{loading ? <span>Please wait...</span> : <span>No records found</span>}</div>
          ) : (
            <>
              <Table hover className="client-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Referrence No.</th>
                    <th className="border-bottom">Name</th>
                    <th className="border-bottom">Email</th>
                    <th className="border-bottom">Display Name</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Phone Number</th>
                    <th className="border-bottom">Company Name</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <Fragment key={i}>
                      <tr>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedClientId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              item.referenceNumber
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedClientId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              setFullname(item)
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedClientId == null ? <Skeleton wrapper={SkeletonWrapper} /> : item.email}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedClientId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              item.displayName || ''
                            )}
                          </span>
                        </td>
                        <td>
                          <span className={`fw-normal text-success ${item.isActive ? 'text-success' : 'text-danger'}`}>
                            {loading && selectedClientId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : item.isActive ? (
                              'Active'
                            ) : (
                              'In-Active'
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                              {loading && selectedClientId == null ? (
                                <Skeleton wrapper={SkeletonWrapper} />
                              ) : (
                                item.phoneNumber || ''
                              )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                              {loading && selectedClientId == null ? (
                                <Skeleton wrapper={SkeletonWrapper} />
                              ) : (
                                item.companyName || ''
                              )}
                          </span>
                        </td>
                        <td>
                          {loading && selectedClientId == null ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                          ) : (
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle as={Button} split={true} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm">
                                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="more-left-dropdown-menu">
                                <Dropdown.Item onSelect={() => handleViewClientInfo(item._id)}>
                                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                </Dropdown.Item>
                                {hasPermission(CLIENT_MANAGER_UPDATE) && (
                                  <Dropdown.Item onSelect={() => handleEditClient(item._id)}>
                                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                  </Dropdown.Item>
                                )}
                                {hasPermission(CLIENT_MANAGER_DELETE) && item.isActive && (
                                  <Dropdown.Item
                                    className="text-danger"
                                    onSelect={() => handleSetClientStatus(item._id)}
                                  >
                                    <FontAwesomeIcon icon={faArchive} className="me-2" /> Archive
                                  </Dropdown.Item>
                                )}
                                {hasPermission(CLIENT_MANAGER_DELETE) && !item.isActive && (
                                  <Dropdown.Item
                                    className="text-success"
                                    onSelect={() => handleReactivateClient(item._id, true)}
                                  >
                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> Activate
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                      {selectedClientId === item._id && (
                        <tr>
                          <td colSpan={8}>
                            <Row>
                              <Col md={8} className="mb-3">
                                {clientProfile && (
                                  <ClientForm
                                    isEditMode={isEditMode}
                                    client={clientProfile}
                                    cancelEdit={handleCancelClientProfile}
                                    clientAgreements={clientAgreements}
                                    refreshDocuments={fetchClientDocuments}
                                  />
                                )}
                              </Col>
                              <Col md={4} className="mb-3">
                                <Row>
                                  <Col xs={12}>
                                    {clientProfile && (
                                      <ClientProfileCardWidget
                                        fullName={getFullName()}
                                        displayName={clientProfile.displayName}
                                        companyName={clientProfile.companyName}
                                        photoUrl={clientProfile.photoUrl}
                                      />
                                    )}
                                  </Col>
                                  <Col xs={12}>
                                    {clientProfile && isEditMode && (
                                      <ClientUploadPhoto clientId={clientProfile._id} photoUrl={clientProfile.photoUrl} isEditMode={isEditMode}/>
                                    )}
                                  </Col>
                                  {/* <Col xs={12}>
                                    <ClientFinancialSummary />
                                  </Col> */}
                                  {hasPermission(CLIENT_MANAGER_VIEW_PAYMENT_TERMS) && (
                                    <Col xs={12}>
                                      <ClientPaymentTerms 
                                        clientId={selectedClientId}
                                        activePaymentTermsForClient={activePaymentTermsForClient} 
                                        clientPaymentTerms={clientPaymentTerms}
                                        refreshList={fetchClientPaymentTerms}
                                        isEditMode={isEditMode}
                                      />
                                    </Col>
                                  )}
                                  {hasPermission(CLIENT_MANAGER_VIEW_SPECIAL_RATES) && (
                                    <Col xs={12}>
                                      <ClientSpecialRates 
                                        editSpecialRate={handleEditSpecialRate} 
                                        addSpecialRate={handleAddClientSpecialRate} 
                                        clientSpecialRates={clientSpecialRates}
                                        specialRateStatusUpdate={specialRateStatusUpdate}
                                        isEditMode={isEditMode} />
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
              {!loading && (
                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={pagingEvent} />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ClientDatatable;