import React, { useState } from "react";
import { Card, Form, Button } from '@themesberg/react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from "react-bootstrap";
import { confirmArchiveDialog, showError, showOk } from "../components/Alerts";
import { hasPermission } from "../auth/UserPermissionTypes";
import { CLIENT_MANAGER_ADD_PAYMENT_TERMS, CLIENT_MANAGER_UPDATE_PAYMENT_TERMS } from "../utils/constants";
import clientApi from '../services/client-service';

const ClientPaymentTerms = (props) => {

    const { activePaymentTermsForClient, clientPaymentTerms, clientId, refreshList, isEditMode } = props;
    const [selectedPaymentTermToSet, setSelectedPaymentTermToSet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAddPaymentTerm, setShowAddPaymentTerm] = useState(false);

    const { setClientPaymentTerm, setClientPaymentTermStatus } = clientApi;

    const handlePaymentTermChange = (event) => {
        setSelectedPaymentTermToSet(event.target.value);
    };

    const handleSaveClientPaymentTerm = () => {
        setLoading(true);
    
        let formData = new FormData();

        formData.append('clientId', clientId);
        formData.append('paymentTermId', selectedPaymentTermToSet);

        setClientPaymentTerm(formData)
            .then(() => {
                showOk('Client payment term saved');
                refreshList(clientId);
                setLoading(false);
                handleHideAddPaymentTerm();
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
                handleHideAddPaymentTerm();
            });
    };

    const handleHideAddPaymentTerm = () => {
        setSelectedPaymentTermToSet(null);
        setShowAddPaymentTerm(false);
    }

    const updatePaymentTermStatus = (id, status) => {
        let formData = new FormData();
    
        formData.append('clientPaymentTermId', id);
        formData.append('status', status);
    
        setClientPaymentTermStatus(formData).then(() => {
          refreshList(clientId);
        });
    };

    const handleSetPaymentTermStatus = async (id, status) => {
        let eventMessage = "Deactivate";
        if(status) eventMessage = "Re-activate"
        
        let text = `Do you want to ${eventMessage} this client payment term?`;
        let confirmBtnText = `Yes, ${eventMessage} it!`;
        let callbackTitle = !status ? 'Deactivated!': 'Activated!';
        let callbackText = `Client payment term has been ${!status ? 'Deactivated!': 'Activated!'}`;
    
        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
            updatePaymentTermStatus(id, status);
        }
    };

    return (
        <Card border="light" className="shadow-sm mt-4">
          <Card.Body>
            <Row>
                <Col md={10} className="mb-2">
                    <h5>Client Payment Terms</h5>
                </Col>
                {isEditMode && hasPermission(CLIENT_MANAGER_ADD_PAYMENT_TERMS) && (
                    <Col md={2} className="mb-2">
                        <Button variant="primary" size="sm" onClick={() => setShowAddPaymentTerm(!showAddPaymentTerm)}>Add</Button>
                    </Col>
                )}
            </Row>
            {showAddPaymentTerm && (
                <div className="d-block">
                    <Form.Group id="active-payment-term">
                        <Form.Label className="light-font">Set Payment Term</Form.Label>
                        <Form.Select onChange={handlePaymentTermChange}>
                            <option value="" selected={true}>Select Payment Term</option>
                            {activePaymentTermsForClient.map((item, i) => (
                                <option key={i} value={item._id}>{item.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Button 
                        variant="secondary" 
                        size="sm" 
                        className="mt-2 me-1" 
                        disabled={!selectedPaymentTermToSet || loading}
                        onClick={() => handleSaveClientPaymentTerm()}
                    >Save</Button>
                    <Button 
                        variant="primary" 
                        size="sm" 
                        className="mt-2" 
                        onClick={() => handleHideAddPaymentTerm()}
                    >Cancel</Button>
                </div>
            )}
            <div className="d-block">
                {clientPaymentTerms.map((item, index) => (
                    <div key={index} className="d-flex align-items-center border-bottom border-light py-3">
                        <div className="payment-term-name">
                            <h6 className="mb-0">
                                {item?.paymentTermData?.name || ''}
                            </h6>
                        </div>
                        <div>
                            {isEditMode && hasPermission(CLIENT_MANAGER_UPDATE_PAYMENT_TERMS) ? (
                                <Form.Group id="payment-term-switch">
                                    <Form.Check
                                        defaultChecked={item.isActive}
                                        type="switch"
                                        name="client-payment-terms"
                                        id="client-payment-terms-01"
                                        htmlFor="client-payment-terms-01"
                                        readOnly={true}
                                        onChange={() => handleSetPaymentTermStatus(item._id, !item.isActive)}
                                    />
                                </Form.Group>
                            ) : (
                                <>
                                    {item.isActive && (
                                        <h6 className="fw-normal text-gray">
                                            <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs text-success w-20 me-1 mt-2" />
                                        </h6>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
          </Card.Body>
        </Card>
    );
}

export default ClientPaymentTerms;