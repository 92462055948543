import React, { useState, useRef, useEffect } from 'react';
import { Card, Image, Button, Form } from '@themesberg/react-bootstrap';
import productResourceApi from '../services/product-resource-service';
import { showOk, showError, confirmDeleteDialog } from '../components/Alerts';
import { hasPermission } from '../auth/UserPermissionTypes';
import { PRODUCT_MANAGER_DELETE_PRODUCT_RESOURCE } from '../utils/constants';

export const ProductUploadPhoto = (props) => {
  const { uploadResource, setPrimaryToResource, deleteResource } = productResourceApi;
  const { productId, sequence, data, refreshList, isPrimary, onPrimaryChange } = props;

  const [resourceData, setResourceData] = useState(null);
  const [resource, setResource] = useState(null);
  const [resourceType, setResourceType] = useState('');
  const [imgData, setImgData] = useState(process.env.REACT_APP_NO_IMAGE);
  const [uploading, setUploading] = useState(false);

  const fileInputRef = useRef(null);

  const onChangeResource = (e) => {
    if (e.target.files[0]) {
        const file = e.target.files[0];

        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');

        if (!isImage && !isVideo) {
            showError('File does not support. You must select an image or video file');
            return false;
        }

        if (isImage && file.size > 5 * 1024 * 1024) {
            showError('Please upload an image smaller than 5 MB');
            return false;
        }

        if (isVideo && file.size > 30 * 1024 * 1024) {
            showError('Please upload a video smaller than 30 MB');
            return false;
        }

        setResource(file);
        setResourceType(isImage ? 'Photo' : 'Video');

        if (isImage) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(file);
        } else {
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            videoElement.addEventListener('loadeddata', () => {
                videoElement.currentTime = 1;

                videoElement.addEventListener('seeked', () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = videoElement.videoWidth;
                    canvas.height = videoElement.videoHeight;
                    canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                    setImgData(canvas.toDataURL('image/png'));
                    URL.revokeObjectURL(videoElement.src);
                });
            });
        }
    }
  };

  const handleUploadResource = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('productId', productId);
    formData.append('sequence', sequence);

    const resourceId = data ? data._id : null;

    formData.append('resourceId', resourceId);
    formData.append('isPrimary', isPrimary);

    if (resourceType === 'Video') {
        formData.append('video', resource);

        const canvas = document.createElement('canvas');
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(resource);

        videoElement.addEventListener('loadeddata', () => {
            videoElement.currentTime = 1;

            videoElement.addEventListener('seeked', () => {
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                canvas.toBlob((blob) => {
                    formData.append('thumbnail', blob, 'thumbnail.png');
                    URL.revokeObjectURL(videoElement.src);

                    uploadResource(formData)
                        .then((res) => {
                            showOk(`${resourceType} uploaded successfully`);
                            setUploading(false);
                            refreshList(); // Refresh the list after upload
                        })
                        .catch((error) => {
                            showError(error.response?.data?.message || 'An error occurred during the upload');
                            setUploading(false);
                        });
                }, 'image/png');
            });
        });
    } else {
        formData.append('file', resource);
        uploadResource(formData)
            .then((res) => {
                showOk(`${resourceType} uploaded successfully`);
                setUploading(false);
                refreshList(); // Refresh the list after upload
            })
            .catch((error) => {
                showError(error.response?.data?.message || 'An error occurred during the upload');
                setUploading(false);
            });
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handlePrimaryChange = async (e) => {
    const checked = e.target.checked;
    if (checked && data && data._id) {
        try {
            const formData = new FormData();
            formData.append('productId', productId);
            formData.append('resourceId', data._id);

            await setPrimaryToResource(formData);
            showOk('Resource has been set as primary');
            refreshList();
            onPrimaryChange(sequence); // Notify parent of primary change
        } catch (error) {
            showError('Failed to set resource as primary');
        }
    }
  };

  const handleDeleteResource = async () => {
    const confirmDelete = await confirmDeleteDialog();

    if (confirmDelete) {
        try {
            if (data && data._id) {
                await deleteResource(data._id);
                showOk('Resource deleted successfully');
                
                // Reset the component to show "No Photo" after deletion
                setResource(null);
                setResourceType('');
                setImgData(process.env.REACT_APP_NO_IMAGE);
                setResourceData(null)
                refreshList(); // Refresh the list after deletion
            }
        } catch (e) {
            showError(e?.data?.error || 'Failed to delete resource');
        }
    }
  };

  useEffect(() => {
    if (data) {
      setResourceData(data)
      setResourceType(data.resourceType);
      setImgData(data.thumbnail || process.env.REACT_APP_NO_IMAGE);
    } else {
      // Reset to "No Photo" when there is no data
      setImgData(process.env.REACT_APP_NO_IMAGE);
    }
  }, [data]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div className="d-xl-flex align-items-center">
            <div className="user-avatar xl-avatar" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
              <Image fluid rounded src={imgData} />
            </div>
            <div className="file-field" style={{ display: 'none' }}>
              <input
                type="file"
                accept="image/*,video/*"
                name="resource"
                onChange={onChangeResource}
                ref={fileInputRef}
              />
            </div>
            <div className="d-md-block text-start ms-xl-3">
              <div className="fw-normal text-dark mb-1">Choose File</div>
              <div className="fw-normal text-dark mb-1">Resource Type: <b>{resourceType}</b></div>
              {resourceType === 'video' && data?.resourceUrl && (
                <div className="fw-normal text-dark mb-1">
                  <a href={data.resourceUrl} target="_blank" rel="noopener noreferrer">Play Video</a>
                </div>
              )}
            </div>
          </div>
          <div className="d-xl-flex align-items-center mt-2">
            <Button
              disabled={!resource || uploading}
              onClick={handleUploadResource}
              variant="primary"
              size="sm"
              className="me-1"
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
            {resourceData && (
              <>
                {hasPermission(PRODUCT_MANAGER_DELETE_PRODUCT_RESOURCE) && (
                  <Button
                    variant="danger"
                    size="sm"
                    className="me-1"
                    onClick={() => handleDeleteResource()} // Show delete confirmation modal
                  >
                    Delete
                  </Button>
                )}
                <Form.Check 
                  label="Set as primary" 
                  id={`set-default-photo-${sequence}`} 
                  htmlFor={`set-default-photo-${sequence}`} 
                  checked={isPrimary} // Bind the checkbox to isPrimary state
                  onChange={handlePrimaryChange} // Handle checkbox change
                />
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
