import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { Card, Button, Table, Dropdown, ButtonGroup, Col, Row } from '@themesberg/react-bootstrap';
import { confirmArchiveDialog, showOk } from '../components/Alerts';
import { faEdit, faEllipsisH, faEye, faArchive, faPaperPlane, faLock, faUserCheck, faCheck, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalPagination from '../components/PortalPagination';
import userApi from '../services/user-service';
import provinceApi from '../services/province-service';
import { showError } from '../components/Alerts';
import GeneralInfoForm from './generalinfoForm';
import { ProfileCardWidget } from '../components/Widgets';
import { UploadPhoto } from '../components/UploadPhoto';
import moment from 'moment-timezone';
import { hasPermission, isSuperAdmin } from '../auth/UserPermissionTypes';
import UserAccessManagement from '../accessmanagement/useraccessmanagement';
import { USER_MANAGER_CAN_ACTIVATE, USER_MANAGER_DELETE, USER_MANAGER_UPDATE } from '../utils/constants';
import UserSalesAgent from './usersalesagent';

export function UserDatatable(props) {
  const { data, selectedPage, pagingEvent, refreshList } = props;
  const loading = useSelector((state) => state.blanket.showAdminLoader);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);
  const totalRecords = useSelector((state) => state.userState.count) || 0;
  const { setUserStatus, getProfileInfo, sendAccountActivation } = userApi;
  const { getProvinces } = provinceApi;
  const [userProfile, setUserProfile] = useState(null);
  const [userAccessManagement, setUserAccessManagement] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [showSetSalesAgent, setShowSetSalesAgent] =  useState(false);
  const [salesAgentAssignedProvinces, setSalesAgentAssignedProvinces] =  useState([]);
  const [isActiveSalesAgent, setIsActiveSalesAgent] =  useState(false);
  

  const handleViewUserInfo = (id) => {
    setSelectedUserId(id);
    setIsEditMode(false);
    setUserAccessManagement(null);

    getProfileInfo(id).then((data) => {
      if (data.error) {
        showError(data.error);
      }
      setUserProfile(data);
    });
  };

  const handleEditUser = (id) => {
    setSelectedUserId(id);
    setIsEditMode(true);
    setUserAccessManagement(null);

    getProfileInfo(id).then((data) => {
      if (data.error) {
        showError(data.error);
      }
      setUserProfile(data);
    });
  };

  const handleCancelUserProfile = () => {
    setSelectedUserId(null);
    setUserProfile(null);
  };

  const handleSetUserStatus = async (id, status) => {
    let text = 'Do you want to archive this user?';
    let confirmBtnText = 'Yes, archive it!';
    let callbackTitle = 'Archived!';
    let callbackText = 'User has been archived!';

    var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
    if (statusConfirm) {
      let userData = new FormData();

      userData.append('userId', id);
      userData.append('status', status);

      setUserStatus(userData).then(() => {
        refreshList();
      });
    }
  };

  const setFullname = (data) => {
    return `${data.firstname} ${data.lastname}`;
  };

  const getFullName = () => {
    if (userProfile)
      return `${userProfile.user?.firstname} ${userProfile.user?.middlename} ${userProfile.user?.lastname}`;
    return '';
  };

  const getJobTitle = () => {
    if (userProfile) return userProfile?.jobTitle;
    return '';
  };

  const getDateHired = () => {
    if (userProfile) return moment(userProfile?.dateHired).format('ll');
    return '';
  };

  const isUserActive = () => {
    if (userProfile) return userProfile.user?.isActive;
    return false;
  };

  const handleSendAccountActivation = async (id) => {
    let userData = new FormData();

    userData.append('userId', id);

    sendAccountActivation(userData)
      .then(() => {
        showOk('Invitation sent');
      })
      .catch((e) => {
        showError(data.error);
      });
  };

  const handleUserAccessManagement = async (id) => {
    setSelectedUserId(null);
    setUserAccessManagement(id);
  };

  const handleCancelUserAccessManagement = () => {
    setUserAccessManagement(null);
  }

  const fetchProvinces = useCallback(
    () => {
      getProvinces()
        .then((data) => {
          setProvinces(data.provinces);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [setProvinces, getProvinces]
  );

  const handleHideSetSalesAgent = () => {
    setSelectedUserId(null);
    setShowSetSalesAgent(false);
    setSalesAgentAssignedProvinces([]);
    setIsActiveSalesAgent(false);
  }

  const handleSetSalesAgent = (id, isSalesAgent, assignedProvinces) => {
    setSelectedUserId(id);
    setShowSetSalesAgent(true);
    setSalesAgentAssignedProvinces(assignedProvinces)
    setIsActiveSalesAgent(isSalesAgent);
  }

  useEffect(() => {
    fetchProvinces();
  }, [fetchProvinces]);

  return (
    <>
      {showSetSalesAgent && (
        <UserSalesAgent 
          userId={selectedUserId} 
          provinces={provinces} 
          cancelEvent={handleHideSetSalesAgent} 
          refreshList={refreshList}
          assignedProvinces={salesAgentAssignedProvinces}
          isActiveSalesAgent={isActiveSalesAgent}
          viewOnly={false}
        />
      )}
      
      <Card border="light" className="table-wrapper table-responsive shadow-sm no-border-radius">
        <Card.Body className="pt-0">
          {data.length === 0 ? (
            <div className="no-result-box">{loading ? <span>Please wait...</span> : <span>No records found</span>}</div>
          ) : (
            <>
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Employee Id</th>
                    <th className="border-bottom">Name</th>
                    <th className="border-bottom">Email</th>
                    <th className="border-bottom">Contact Number</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Is Sales Agent</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <Fragment key={i}>
                      <tr>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedUserId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              item.employeeId || 'N/A'
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedUserId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              setFullname(item)
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedUserId == null ? <Skeleton wrapper={SkeletonWrapper} /> : item.email}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {loading && selectedUserId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              item.profileData?.contactNumber || ''
                            )}
                          </span>
                        </td>
                        <td>
                          <span className={`fw-normal text-success ${item.isActive ? 'text-success' : 'text-danger'}`}>
                            {loading && selectedUserId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : item.isActive ? (
                              'Active'
                            ) : (
                              'In-Active'
                            )}
                          </span>
                        </td>
                        <td>
                          {loading && selectedUserId == null ? (
                              <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                              <>
                                {item.isSalesAgent && (
                                    <FontAwesomeIcon icon={faCheck} className={`icon icon-xs text-success w-20 me-1`} />
                                )}
                              </>
                            )}
                          </td>
                        <td>
                          {loading && selectedUserId == null ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                          ) : (
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle as={Button} split={true} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm">
                                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="more-left-dropdown-menu">
                                <Dropdown.Item onSelect={() => handleViewUserInfo(item._id)}>
                                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                </Dropdown.Item>
                                {hasPermission(USER_MANAGER_UPDATE) && (
                                  <Dropdown.Item onSelect={() => handleEditUser(item._id)}>
                                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                  </Dropdown.Item>
                                )}
                                {hasPermission(USER_MANAGER_CAN_ACTIVATE) && !item.isActive && (
                                  <Dropdown.Item onSelect={() => handleSendAccountActivation(item._id)}>
                                    <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send Activation
                                  </Dropdown.Item>
                                )}
                                {isSuperAdmin() && item.isActive && (
                                  <Dropdown.Item
                                    className="text-success" 
                                    onSelect={() => handleUserAccessManagement(item._id)}>
                                    <FontAwesomeIcon icon={faLock} className="me-2" /> Access Management
                                  </Dropdown.Item>
                                )}
                                {hasPermission(USER_MANAGER_DELETE) && item.isActive && (
                                  <Dropdown.Item
                                    className="text-danger"
                                    onSelect={() => handleSetUserStatus(item._id, false)}
                                  >
                                    <FontAwesomeIcon icon={faArchive} className="me-2" /> Archive
                                  </Dropdown.Item>
                                )}
                                {item.isActive && !item.isSalesAgent && (
                                  <Dropdown.Item onClick={() => handleSetSalesAgent(item._id, item.isSalesAgent, item.assignedProvinces)}>
                                    <FontAwesomeIcon icon={faUserCheck} className="me-2" /> Set as agent
                                  </Dropdown.Item>
                                )}
                                {item.isActive && item.isSalesAgent && (
                                  <Dropdown.Item onClick={() => handleSetSalesAgent(item._id, item.isSalesAgent, item.assignedProvinces)}>
                                    <FontAwesomeIcon icon={faUserCog} className="me-2" /> Manage Sales Agent
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                      {selectedUserId === item._id && (
                        <tr>
                          <td colSpan={7}>
                            <Row>
                              <Col md={8} className="mb-3">
                                {userProfile && (
                                  <GeneralInfoForm
                                    isEditMode={isEditMode}
                                    userProfile={userProfile}
                                    cancelEdit={handleCancelUserProfile}
                                    isManager={isEditMode}
                                    showCancelButton={true}
                                  />
                                )}
                              </Col>
                              <Col md={4} className="mb-3">
                                <Row>
                                  <Col xs={12}>
                                    {userProfile && (
                                      <ProfileCardWidget
                                        fullName={getFullName()}
                                        jobTitle={getJobTitle()}
                                        dateHired={getDateHired()}
                                        isActive={isUserActive()}
                                        photoUrl={userProfile.photoUrl}
                                      />
                                    )}
                                  </Col>
                                  <Col xs={12}>
                                    {userProfile && isEditMode && (
                                      <UploadPhoto userProfileId={userProfile._id} photoUrl={userProfile.photoUrl} />
                                    )}
                                  </Col>
                                  {/* {isSuperAdmin() && (
                                    <Col xs={12}>
                                      {userProfile && isEditMode && (
                                        <UserPermissions userId={selectedUserId} cancelEdit={handleCancelUserProfile} />
                                      )}
                                    </Col>
                                  )} */}
                                </Row>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      )}
                      {userAccessManagement === item._id && (
                        <tr>
                          <td colSpan={7}>
                            <Row>
                              <Col md={12} className="mb-3">
                                <UserAccessManagement userId={item._id} handleClose={handleCancelUserAccessManagement}/>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
              {!loading && (
                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={pagingEvent} />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
