import { 
  CLIENT_BILLINGS_COUNT, 
  CLIENT_BILLINGS_TOTAL_PAGES,
  CLIENT_SALES_QUOTATION_COUNT,
  CLIENT_SALES_QUOTATION_TOTAL_PAGES
 } from '../actions/types';

const initialState = {
  billingCount: 0,
  billingTotalPages: 0,
  salesQuotationCount: 0,
  salesQuotationTotalPages: 0,
};

const clientTransactionReducer = (clientTransactionState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_BILLINGS_COUNT:
      return { ...clientTransactionState, billingCount: payload };
    case CLIENT_BILLINGS_TOTAL_PAGES:
      return { ...clientTransactionState, billingTotalPages: payload };
      case CLIENT_SALES_QUOTATION_COUNT:
      return { ...clientTransactionState, salesQuotationCount: payload };
    case CLIENT_SALES_QUOTATION_TOTAL_PAGES:
      return { ...clientTransactionState, salesQuotationTotalPages: payload };
    default:
      return clientTransactionState;
  }
};

export default clientTransactionReducer;
