import React, { Fragment } from "react";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from 'moment-timezone';
import { formatCurrency } from "../utils/formatter";

const CbmRateCountsSummaryPreview = (props) => {
    const { 
        startDate, 
        endDate, 
        selectedYears, 
        selectedMonths, 
        reportList, 
        overallTotalBillableAmount, 
        groupByAppliedRate 
    } = props;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Rate Counts (With Total CBM, Amount & Percentage) Summary Report</h1>
                    <div className="left">
                        <p>Start Date: <b>{formattedStartDate}</b></p>
                        <p>End Date: <b>{formattedEndDate}</b></p>
                    </div>
                </div>
                <>
                    {selectedYears.map((year, yearIndex) => (
                        <Fragment key={yearIndex}>
                            <Table className="main-tbl-summary mt-5">
                                {selectedMonths.map((monthObj, index) => {
                                    const { hasResult, grouped } = groupByAppliedRate(reportList, year, monthObj.month);

                                    return (
                                        <Fragment key={index}>
                                            <thead>
                                                <tr>
                                                    <th className="border-bottom month-indicator">
                                                        <h5>{monthObj.label} {year}</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border-bottom">
                                                        {hasResult ? (
                                                            <Table hover className="billing-table-list align-items-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="border-bottom">Applied Rate</th>
                                                                        <th className="border-bottom">Total Count</th>
                                                                        <th className="border-bottom">Total CBM</th>
                                                                        <th className="border-bottom">Total Billed Amount</th>
                                                                        <th className="border-bottom">Total Percentage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {grouped.map((entry, index) => {
                                                                        const filteredItems = reportList.filter(item =>
                                                                            moment(item.createdDate).year() === year &&
                                                                            moment(item.createdDate).month() + 1 === monthObj.month &&
                                                                            item.appliedRate === entry.appliedRate
                                                                        );

                                                                        const totalCBM = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalCBM), 0).toFixed(2);
                                                                        const totalBilledAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.grossAmount), 0).toFixed(2);
                                                                        const result = (parseFloat(totalBilledAmount) / parseFloat(overallTotalBillableAmount)) * 100;
                                                                        const percentage = result.toFixed(2);

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{formatCurrency(parseFloat(entry.appliedRate))}</td>
                                                                                <td>{entry.count}</td>
                                                                                <td>{totalCBM}</td>
                                                                                <td>{formatCurrency(parseFloat(totalBilledAmount))}</td>
                                                                                <td>{percentage}%</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <div>*** No Records Found ***</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Fragment>
                                    );
                                })}
                            </Table>
                        </Fragment>
                    ))}
                </>
            </Card.Body>
        </Card>
    )
}

export default CbmRateCountsSummaryPreview;