import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { Form, Button, ButtonGroup, InputGroup, Card, Row, Col } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faFileImport, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import ClientForm from './clientform';
import ClientDatatable from './clientdatatable';
import clientApi from '../services/client-service';
import { CLIENT_MANAGER_ADD, defaultPageNumber } from '../utils/constants';
import { setClientCount } from '../actions/client';
import { hasPermission } from '../auth/UserPermissionTypes';
import ClientImportData from './clientimportdata';

function ClientManager() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const { getCount, list } = clientApi;
  const handleCancelForm = () => setShowAddUserForm(false);
  const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
  const [clientList, setClientList] = useState([]);
  const [showImportData, setShowImportData] = useState(false);

  const onkeyPressSearch = (e) => {
    if (e.key === 'Enter') {
      onSearchClient();
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  const handleSearchClients = useCallback(
    async (pageNumber = 1, searchValue = '') => {
      setSelectedPage(pageNumber);

      try {
        const countRes = await getCount(searchValue);

        dispatch(setClientCount(countRes.result));

        const listRes = await list(pageNumber, searchValue);

        setClientList(listRes.result);
      } catch (error) {
        console.error('Error while searching clients:', error);
      }
    },
    [getCount, dispatch, list],
  );

  const onSearchClient = async () => {
    const countRes = await getCount(searchTerm);

    dispatch(setClientCount(countRes.result));

    const listRes = await list(1, searchTerm);

    setClientList(listRes.result);
  };

  const handlePageEvent = (pageNumber) => {
    handleSearchClients(pageNumber, searchTerm);
  };

  const handleRefetchClientList = () => {
    setSearchTerm('');
    handleSearchClients(1);
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BULK_CLIENT_IMPORT);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'Client_bulk_import_template.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    handleSearchClients(1);
  }, [handleSearchClients]);

  useEffect(() => {
    dispatch(setPortalPage('Client Management'));
  }, [dispatch]);

  useEffect(() => {
    getCount().then((res) => {
      dispatch(setClientCount(res.result));
      list(1).then((res) => {
        setClientList(res.result);
      });
    });
  }, [getCount, dispatch, list]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0 search-block-width-400">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              onKeyPress={(e) => onkeyPressSearch(e)}
              value={searchTerm}
              onChange={onSearchChange}
              placeholder="Search"
            />
            <Button size="sm" onClick={() => onSearchClient()}>
              Search
            </Button>
          </InputGroup>
        </div>
        {hasPermission(CLIENT_MANAGER_ADD) && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Button variant="outline-primary" size="sm" onClick={() => setShowAddUserForm(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> New
              </Button>
              <Button variant="outline-primary" size="sm" onClick={() => handleDownloadTemplate()}>
                  <FontAwesomeIcon icon={faFileDownload} className="me-2" /> Download Template
              </Button>
              <Button variant="outline-primary" size="sm" onClick={() => setShowImportData(true)}>
                  <FontAwesomeIcon icon={faFileImport} className="me-2" /> Bulk Import
                </Button>
            </ButtonGroup>
          </div>
        )}
      </div>

      {showImportData && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <ClientImportData showImportClientEvent={setShowImportData} refreshList={handleRefetchClientList}/>
        </div>
      )}

      {showAddUserForm && (
        <Card className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Row>
              <Col xs={12} xl={12}>
                <ClientForm isEditMode={true} client={null} cancelEdit={handleCancelForm} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <Card border="light" className="shadow-sm no-border-radius">
        <ClientDatatable
          data={clientList}
          selectedPage={selectedPage}
          pagingEvent={handlePageEvent}
          refreshList={handleRefetchClientList}
        />
      </Card>
    </>
  );
}

export default ClientManager;
