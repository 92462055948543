import { 
    AUDIT_LOGS_COUNT, 
    AUDIT_LOGS_TOTAL_PAGES
} from '../actions/types';

const initialState = {
    auditLogsCount: 0,
    auditLogsTotalPages: 0,
};

const auditLogsReducer = (auditLogsState = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case AUDIT_LOGS_COUNT:
        return { ...auditLogsState, auditLogsCount: payload };
      case AUDIT_LOGS_TOTAL_PAGES:
        return { ...auditLogsState, auditLogsTotalPages: payload };
      default:
        return auditLogsState;
    }
  };
  
  export default auditLogsReducer;
  