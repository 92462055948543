import { 
    QUOTATION_TOTAL_PAGES, 
    QUOTATION_COUNT,
} from '../actions/types';
  
const initialState = {
  PI: { count: 0, totalPages: 0 },
  PGMA: { count: 0, totalPages: 0 },
  FQ: { count: 0, totalPages: 0 },
  SQ: { count: 0, totalPages: 0 },
  PCSQ: { count: 0, totalPages: 0 },
  CANCELLED: { count: 0, totalPages: 0 },
  CNR: { count: 0, totalPages: 0 },
};
  
const quotationReducer = (quotationState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case QUOTATION_COUNT:
      return {
        ...quotationState,
        [payload.status]: {
          ...quotationState[payload.status],
          count: payload.count,
        },
      };

    case QUOTATION_TOTAL_PAGES:
      return {
        ...quotationState,
        [payload.status]: {
          ...quotationState[payload.status],
          totalPages: payload.totalPages,
        },
      };

    default:
      return quotationState;
  }
};
  
export default quotationReducer;
