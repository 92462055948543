import React, { Fragment, useState } from "react";
import { Table, Card, Button } from "@themesberg/react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../billingprocessor/styles.scss';
import { formatCurrency } from "../utils/formatter";
import BillingItemDetailForm from "../billingprocessor/billingitemdetailform";

const BillingItems = (props) => {
    const { billingItems } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const handleSelectItem = (data, editMode) => {
        setSelectedItem(data);
        setIsEditMode(editMode);
    };

    const handleCancelEdit = () => {
        setSelectedItem(null);
        setIsEditMode(false);
    };

    return (
        <Card border="light" className="shadow-sm mt-2 billing-items-card">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <h5 className="mb-0">Items</h5>
            </Card.Header>
            <Card.Body>
                <div className="table-responsive">
                    <Table className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Description</th>
                                <th className="border-0">No. Of Package</th>
                                <th className="border-0">Volume</th>
                                <th className="border-0">Gross Wt</th>
                                <th className="border-0">Sensitive Charge</th>
                                <th className="border-0">Forklift Charge</th>
                                <th className="border-0">Inland Transportation</th>
                                <th className="border-0">Total Amount</th>
                                <th className="border-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingItems && billingItems.map((item, index) => (
                                <Fragment key={index}>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.itemDescription}</td>
                                        <td>{item.numberOfPackage}</td>
                                        <td>{item.cbm}</td>
                                        <td>{item.grossWeight}</td>
                                        <td>{formatCurrency(parseFloat(item.itemSensitiveCharge))}</td>
                                        <td>{formatCurrency(parseFloat(item.forkliftCharge))}</td>
                                        <td>{formatCurrency(parseFloat(item.inlandTransportation))}</td>
                                        <td>{formatCurrency(parseFloat(item.totalAmount))}</td>
                                        <td>
                                            <Button variant="primary" size="sm" className="me-1" title="View More" onClick={() => handleSelectItem(item, false)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </td>
                                    </tr>
                                    {selectedItem && selectedItem._id === item._id && (
                                        <tr>
                                            <td colSpan={10}>
                                                <BillingItemDetailForm data={item} 
                                                isEditMode={isEditMode} 
                                                cancelEdit={handleCancelEdit} />
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default BillingItems;
