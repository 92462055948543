import { requests } from './api-service';

const auditApi = {
    getAuditPerformers: () => requests.get('audit/distinct-changed-by'),
    getAuditDocuments: () => requests.get('audit/documents'),
    getAllOperations: () => requests.get('audit/operations'),
    getCount: (auditFilterPerformerId, auditFilterDocument, auditFilterOperation, auditFilterDate) => 
        requests.get('audit/count', setAuditLogCountParams(auditFilterPerformerId, auditFilterDocument, auditFilterOperation, auditFilterDate)),
    list: (pageNumber, auditFilterPerformerId, auditFilterDocument, auditFilterOperation, auditFilterDate) => 
        requests.get('audit/logs', setAuditLogListParams(pageNumber, auditFilterPerformerId, auditFilterDocument, auditFilterOperation, auditFilterDate)),
};

export const setAuditLogCountParams = (auditFilterPerformerId, auditFilterDocument, auditFilterOperation, auditFilterDate) => {
    const countParams = new URLSearchParams();
    if (auditFilterPerformerId) countParams.append('auditFilterPerformerId', auditFilterPerformerId);
    if (auditFilterDocument) countParams.append('auditFilterDocument', auditFilterDocument);
    if (auditFilterOperation) countParams.append('auditFilterOperation', auditFilterOperation);
    if (auditFilterDate) countParams.append('auditFilterDate', auditFilterDate);
    return countParams;
};

export const setAuditLogListParams = (pageNumber = 1, auditFilterPerformerId = '', auditFilterDocument = '', auditFilterOperation = '', auditFilterDate = '') => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    if (auditFilterPerformerId) params.append('auditFilterPerformerId', auditFilterPerformerId);
    if (auditFilterDocument) params.append('auditFilterDocument', auditFilterDocument);
    if (auditFilterOperation) params.append('auditFilterOperation', auditFilterOperation);
    if (auditFilterDate) params.append('auditFilterDate', auditFilterDate);
    return params;
};

export default auditApi;