import { combineReducers } from 'redux';
import blanket from './blanket';
import userState from './userReducer';
import clientState from './clientReducer';
import rateBracketState from './rateBracketReducer';
import billingDocumentState from './billingDocumentReducer';
import clientTransactionState from './clientTransactionsReducer';
import auditLogsState from './auditLogsReducer';
import productState from './productReducer';
import paymentTermState from './paymentTermReducer';
import quotationState from './quotationReducer';
import reportState from './reportreducer';

export default combineReducers({
  blanket,
  userState,
  clientState,
  rateBracketState,
  billingDocumentState,
  clientTransactionState,
  auditLogsState,
  productState,
  paymentTermState,
  quotationState,
  reportState
});
