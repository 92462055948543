import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, ListGroup, Row, Col, Image, ButtonGroup } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import { confirmDeleteDialog, showError } from "../components/Alerts";
import clientApi from '../services/client-service';
import { CLIENT_MANAGER_DOWNLOAD_ATTACHMENTS, friendlyErrorMessage } from "../utils/constants";
import { hasPermission } from "../auth/UserPermissionTypes";

const ClientAgreements = (props) => {
    const {
        clientId, 
        isEditMode, 
        showUploadDocument, 
        clientAgreements, 
        refreshDocuments,
        handleEditDocument
    } = props;
    const { deleteClientAgreement } = clientApi;

    const displayFileTypeIcon = (type) => {
        if(type === 'pdf') return process.env.REACT_APP_PDF_DOCUMENT_IMAGE;

        return process.env.REACT_APP_WORD_DOCUMENT_IMAGE;
    }

    const handleDownloadDocument = async (documentUrl) => {
        const link = document.createElement('a');
        link.href = documentUrl;
        
        link.setAttribute('download', document.original_filename);

        link.setAttribute('target', '_blank');
        
        document.body.appendChild(link);
        link.click();
        
        document.body.removeChild(link);
    }

    const handleDeleteClientAgreement = async (id) => {
        var confirmDelete = await confirmDeleteDialog();
        if (confirmDelete) {
            deleteClientAgreement(id).then(() => {
                refreshDocuments(clientId);
            })
            .catch(() => {
                showError(friendlyErrorMessage)
            });
        }
      };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Body>
                <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                    <div>
                        <h5>Attached Documents</h5>
                    </div>
                    {isEditMode && (
                        <div>
                            <Button variant="primary" size="sm" onClick={() => showUploadDocument(true)}>Add</Button>
                        </div>
                    )}
                </div>
                {clientAgreements.map((item, i) => (
                    <ListGroup.Item key={i} className="px-0">
                        <Row className="align-items-center">
                        <Col className="col-auto">
                            <Image src={displayFileTypeIcon(item.fileType)} style={{width: '24px'}}/>
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0">{item.contractDetail}</h4>
                            <small>Upload date: <b>{moment(item.uploadDate).format('MMMM DD, YYYY')}</b></small>
                        </Col>
                        <Col className="col-auto">
                            <ButtonGroup size="sm" aria-label="Client Agreements Actions">
                                {hasPermission(CLIENT_MANAGER_DOWNLOAD_ATTACHMENTS) && (
                                    <Button variant="primary" onClick={() => handleDownloadDocument(item.attachmentLink)}><FontAwesomeIcon icon={faDownload} /></Button>
                                )}
                                {isEditMode && (
                                    <>
                                        <Button variant="success" onClick={() => handleEditDocument(item)}><FontAwesomeIcon icon={faEdit} /></Button>
                                        <Button variant="danger" onClick={() => handleDeleteClientAgreement(item._id)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                                    </>
                                )}
                            </ButtonGroup>
                        </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </Card.Body>
        </Card>
    )
}

export default ClientAgreements;