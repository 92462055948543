import React, { useCallback, useEffect, useState } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card, Row, Col } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import AddRoleAndPermissions from './addroleandpermissions';
import roleApi from '../services/roles-service';

function AccessManagement() {
  const [showAddRoleAndPermission, setShowAddRoleAndPermission] = useState(false);
  const [rolesAndPermissions, setRolesAndPermissions] = useState([]);
  const [selectedRolesAndPermission, setSelectedRoleAndPermission] = useState(null);
  const dispatch = useDispatch();

  const { getRolesAndPermissions } = roleApi;

  useEffect(() => {
    dispatch(setPortalPage('Access Management'));
  }, [dispatch]);

  const handleSelectRole = (role) => {
    setSelectedRoleAndPermission(role);
    setShowAddRoleAndPermission(true)
  }

  const handleCancelEdit = () => {
    setShowAddRoleAndPermission(false);
    setSelectedRoleAndPermission(null)
  }

  const handleFetchRolesAndPermissions = useCallback(() => {
    getRolesAndPermissions()
      .then(data => {
        setRolesAndPermissions(data.rolesAndPermissions);
      })
      .catch(e => console.error(e));
  },[getRolesAndPermissions]);

  useEffect(() => {
    handleFetchRolesAndPermissions();
  }, [handleFetchRolesAndPermissions]);

  return (
    <>
      {showAddRoleAndPermission && (
        <AddRoleAndPermissions roleAndPermissionsData={selectedRolesAndPermission} cancelEdit={handleCancelEdit} refreshList={handleFetchRolesAndPermissions}/>
      )}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0 search-block-width-400">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => setShowAddRoleAndPermission(true)} size="sm" >
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Create Role
            </Button>
          </ButtonGroup>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>
      <Card border="light" className="shadow-sm no-border-radius">
        <Card.Body>
          <Row xs={1} md={2} lg={3} xl={4} className="g-4">
            {rolesAndPermissions && rolesAndPermissions.map((role, roleIndex) => (
              <Col key={roleIndex}>
                <Card border="light" className="h-100">
                  <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">{role.name}</h5>
                    <ButtonGroup size="sm" aria-label="Client Agreements Actions">
                        <Button variant="primary" onClick={() => handleSelectRole(role)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </ButtonGroup>
                  </Card.Header>
                  {role.permissions && (
                    <Card.Body>
                      {role.permissions.map((item, index) => (
                        <Row key={index} className="align-items-center border-bottom border-light mb-2">
                          <Col className="ms--2">
                            <h4 className="h6 mb-2">{item.name}</h4>
                          </Col>
                        </Row>
                      ))}
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default AccessManagement;
