import { requests } from './api-service';

const productCategoryApi = {
    createProductCategory: (categoryData) => requests.post('product-category/save', categoryData),
    updateProductCategory: (categoryData) => requests.put('product-category/save', categoryData),
    getProductCategories: () => requests.get('product-categories'),
    deleteProductCategory: (id) => requests.delete(`product-category/${id}`),
    getProductCategoryNames: () => requests.get('product-category/names'),
};

export default productCategoryApi;