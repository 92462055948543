import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { showOk, showError } from '../components/Alerts';
import { Button, Modal } from '@themesberg/react-bootstrap';
import clientApi from '../services/client-service';

const ClientImportData = (props) => {
  const { showImportClientEvent, refreshList } = props;
  const clientExcelImg = process.env.REACT_APP_EXCEL_IMAGE;
  const [showDefault, setShowDefault] = useState(true);
  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [imgExcelData, setImgExcelData] = useState('');
  const [clientExcelFile, setClientExcelFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { uploadClientMigration } = clientApi;

  const handleClose = () => {
    setShowDefault(false);
    showImportClientEvent(false);
  };

  const onUploadDataChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      if (!file.name.toLowerCase().match(/\.(xls|xlsx)$/i)) {
        showError('File is not supported. You must select excel file.');
        return false;
      }

      setClientExcelFile(e.target.files[0]);

      setImgExcelData(clientExcelImg);
    }
  };

  const handleUploadClientMigration = () => {
    const formData = new FormData();
    formData.append('file', clientExcelFile);
    uploadClientMigration(formData)
      .then((res) => {
        refreshList();
        if (res.result.hasError) {
          showError(res.result.message);
          handleClose();
        } else {
          showOk('Client bulk import successful');
          handleClose();
        }

        setLoading(false);
      })
      .catch((error) => {
        showError(error.data.error);
        setLoading(false);
      });
  };

  return (
    <Modal as={Dialog} backdropClassName="" centered show={showDefault}>
        <Header>
          <Title className="h6">Please select client excel file</Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Header>
        <Body>
            <div className="d-xl-flex align-items-center">
                {imgExcelData && (
                    <div className="user-avatar xl-avatar">
                        <Image fluid rounded src={imgExcelData} />
                    </div>
                )}
                <div className="file-field">
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                        <div className="d-flex">
                            <span className="icon icon-md">
                                <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                            </span>
                            <input type="file" accept=".xls,.xlsx" name="uploadDtrFile" onChange={onUploadDataChange} />
                            <div className="d-md-block text-start">
                                <div className="fw-normal text-dark mb-1">Choose Excel File</div>
                                <div className="text-gray small">xls or xlsx file</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Body>
        <Footer>
          <Button type="button" onClick={() => handleUploadClientMigration()} disabled={!clientExcelFile || loading} variant="secondary">
            Upload
          </Button>
        </Footer>
    </Modal>
  );
};

export default ClientImportData;
