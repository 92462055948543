import { requests } from './api-service';

const rolesApi = {
  roleList: () => requests.get('roles/list'),
  userRolesAndPermissions: (userId) => requests.get('user/roles/permissions', setGetUserRolesParams(userId)),
  saveRoleAndPermissions: (formData) => requests.postForm('role/permissions', formData),
  getRolesAndPermissions: () => requests.get('roles/permissions/list'),
  updateRoleAndPermissions: (formData) => requests.putForm('role/permissions', formData),
  saveUserRole: (userRoleData) => requests.put('user/add/role', userRoleData),
  deleteUserRole: (userRoleData) => requests.put('user/delete/role', userRoleData),
  saveUserPermission: (userPermissionData) => requests.put('user/add/permission', userPermissionData),
  deleteUserPermission: (userPermissionData) => requests.put('user/delete/permission', userPermissionData),
  setMultipleUserPermission: (userPermissionsData) => requests.put('user/update/multiple/permission', userPermissionsData),
};

export const setGetUserRolesParams = (userId) => {
  const params = new URLSearchParams();
  params.append('userId', userId);
  return params;
};

export default rolesApi;
