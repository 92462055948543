import React from "react";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from 'moment-timezone';
import { formatCurrency } from "../utils/formatter";

const SalesAgentTransactionsPreview = (props) => {
    const { startDate, endDate, reportList } = props;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Sales Agent Transactions Report</h1>
                    <div className="left">
                        <p>Start Date: <b>{formattedStartDate}</b></p>
                        <p>End Date: <b>{formattedEndDate}</b></p>
                    </div>
                </div>
                <Table hover className="billing-table-list align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation Number</th>
                                <th className="border-bottom">Quotation Date</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Total Base Amount</th>
                                <th className="border-bottom">Total Markup Amount</th>
                                <th className="border-bottom">Client Name</th>
                                <th className="border-bottom">Shipment Type</th>
                                <th className="border-bottom">Delivery Period</th>
                                <th className="border-bottom">Approved Date</th>
                                <th className="border-bottom">Agent Name</th>
                                <th className="border-bottom">Agent Email</th>
                                <th className="border-bottom">Agent Employee ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="fw-normal">{item.quotationNumber}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{moment(item.quotationDate).format('YYYY-MM-DD')}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.status}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{formatCurrency(parseFloat(item.totalBaseAmount)) || 0.00}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{formatCurrency(parseFloat(item.totalMarkUpAmount)) || 0.00}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.clientName}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.shipmentType}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.deliveryPeriod}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.approvedDate ? moment(item.approvedDate).format('YYYY-MM-DD') : ''}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.agent.name}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.agent.email}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">{item.agent.employeeId}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            </Card.Body>
        </Card>
    )
}

export default SalesAgentTransactionsPreview;