import React, { useState } from 'react';
import { authenticate, isAuthenticated } from '../auth';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert, Image } from '@themesberg/react-bootstrap';
import SeaRoadBrand from '../assets/img/searoadtraidinglogo.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import authApi from '../services/auth-api-service';
import { isSuperAdmin, regularUserOnly } from '../auth/UserPermissionTypes';

function Signin() {
  const { signIn } = authApi;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirectToReferer, setRedirectToReferer] = useState(false);
  const [accountIsLocked, setAccountIsLocked] = useState(null);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Invalid email address'),
    password: Yup.string().required('Password is required'),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  };
  const { register, handleSubmit, getValues, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = () => {
    setLoading(true);
    setError('');

    const email = getValues('email');

    const password = getValues('password');

    let signInFormData = new FormData();

    signInFormData.append('email', email);

    signInFormData.append('password', password);

    signIn(signInFormData)
      .then((data) => {
        if (!data) {
          setError('Invalid username or password.');
          setLoading(false);
        } else {
          if (data.error) {
            setError(data.error);
            setLoading(false);
          } else {
            setLoading(false);
            authenticate(data, () => {
              setRedirectToReferer(true);
            });
          }
        }
      })
      .catch((e) => {
        setError(e?.data?.error);
        if (e.data?.locked) {
          setAccountIsLocked(e.data);
        }
        setLoading(false);
      });
  };

  const signinForm = () => (
    <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group id="email" className="mb-4">
        <Form.Label>Your Email</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
          <Form.Control autoFocus type="email" name="email" {...register('email')} placeholder="example@searoad.com" />
        </InputGroup>

        {errors.email?.message && <small className="invalid-fields">{errors.email.message}</small>}
      </Form.Group>
      <Form.Group>
        <Form.Group id="password" className="mb-4">
          <Form.Label>Your Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
            <Form.Control type="password" name="password" {...register('password')} placeholder="Password" />

            {errors.password?.message && <small className="invalid-fields">{errors.password.message}</small>}
          </InputGroup>
        </Form.Group>
        <Form.Group></Form.Group>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <Card.Link href="/forgot-password" className="small text-end">
            Forgot password
          </Card.Link>
        </div>
      </Form.Group>
      {!accountIsLocked && (
        <Button disabled={loading} variant="primary" type="submit" className="w-100">
          Login
        </Button>
      )}
    </Form>
  );

  if(isAuthenticated()){
    if(!isSuperAdmin()) return <Redirect to="/myboard/profile" />;
    return <Redirect to="/myboard/dashboard" />;
  }

  if (redirectToReferer) {
    if(regularUserOnly()) return <Redirect to="/myboard/profile" />;
    return <Redirect to="/myboard/dashboard" />;
  } else {
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-3 mt-md-0">
                    <Image src={SeaRoadBrand} className="navbar-brand-light" />
                  </div>
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h6 className="mb-0">Please enter your credentials</h6>
                  </div>
                  {error !== '' && error !== undefined && <Alert variant="danger">{error}</Alert>}
                  {accountIsLocked && <Alert variant="danger">{accountIsLocked.message}</Alert>}
                  {signinForm()}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}

export default Signin;
