import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { Form, Button, ButtonGroup, InputGroup, Card, Row, Col } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import GeneralInfoForm from './generalinfoForm';
import { UserDatatable } from './userdatatable';
import userApi from '../services/user-service';
import { setUserCount } from '../actions/users';
import { USER_MANAGER_ADD, defaultPageNumber } from '../utils/constants';
import { hasPermission } from '../auth/UserPermissionTypes';

function UserManager() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const { getCount, list } = userApi;
  const handleCancelForm = () => setShowAddUserForm(false);
  const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
  const [userList, setUserList] = useState([]);

  const onkeyPressSearch = (e) => {
    if (e.key === 'Enter') {
      onSearchUser();
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  const handleSearchUsers = useCallback(
    async (pageNumber = 1, searchValue = '') => {
      setSelectedPage(pageNumber);

      try {
        const countRes = await getCount(searchValue);

        dispatch(setUserCount(countRes.result));

        const listRes = await list(pageNumber, searchValue);

        setUserList(listRes.result);
      } catch (error) {
        console.error('Error while searching users:', error);
      }
    },
    [getCount, dispatch, list],
  );

  const onSearchUser = async () => {
    const countRes = await getCount(searchTerm);

    dispatch(setUserCount(countRes.result));

    const listRes = await list(1, searchTerm);

    setUserList(listRes.result);
  };

  const handlePageEvent = (pageNumber) => {
    handleSearchUsers(pageNumber, searchTerm);
  };

  const handleRefetchUserList = () => {
    setSearchTerm('');
    handleSearchUsers(1);
  };

  useEffect(() => {
    handleSearchUsers(1);
  }, [handleSearchUsers]);

  useEffect(() => {
    dispatch(setPortalPage('User Manager'));
  }, [dispatch]);

  useEffect(() => {
    getCount().then((res) => {
      dispatch(setUserCount(res.result));
      list(1).then((res) => {
        setUserList(res.result);
      });
    });
  }, [getCount, dispatch, list]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0 search-block-width-400">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              onKeyPress={(e) => onkeyPressSearch(e)}
              value={searchTerm}
              onChange={onSearchChange}
              placeholder="Search"
            />
            <Button size="sm" onClick={() => onSearchUser()}>
              Search
            </Button>
          </InputGroup>
        </div>
        {hasPermission(USER_MANAGER_ADD) && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Button variant="outline-primary" size="sm" onClick={() => setShowAddUserForm(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> New
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>

      {showAddUserForm && (
        <Card className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Row>
              <Col xs={12} xl={12}>
                <GeneralInfoForm isEditMode={true} userProfile={null} cancelEdit={handleCancelForm} isManager={true} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <Card border="light" className="shadow-sm no-border-radius">
        <UserDatatable
          data={userList}
          selectedPage={selectedPage}
          pagingEvent={handlePageEvent}
          refreshList={handleRefetchUserList}
        />
      </Card>
    </>
  );
}

export default UserManager;
