import { requests } from './api-service';

const authApi = {
  signIn: (formData) => requests.postForm('/signin', formData),
  validateActivation: (token) => requests.get('/validate-activation-request', setValidateActivationParams(token)),
  activateAccount: (formData) => requests.putForm('/activate-account', formData),
  confirmActivateAccount: (formData) => requests.putForm('/confirm-activate-account', formData),
  checkToken: () => requests.get('/check-token'),
  validateToken: () => requests.get('/isvalid-token'),
  signOut: () => requests.get('/signout'),
  sendResetPassword: (formData) => requests.putForm('/reset-password-request', formData),
  validateResetPassword: (token) =>
    requests.get('/validate-reset-password-request', setValidateResetPasswordParams(token)),
  accountResetPassword: (formData) => requests.putForm('/account-reset-password', formData),
  confirmResetPassword: (formData) => requests.putForm('/confirm-reset-password', formData),
};

const setValidateActivationParams = (token) => {
  const params = new URLSearchParams();
  params.append('activationToken', token);
  return params;
};

const setValidateResetPasswordParams = (token) => {
  const params = new URLSearchParams();
  params.append('token', token);
  return params;
};

export default authApi;
