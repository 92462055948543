import { 
    SALES_AGENT_TRANSACTION_REPORT_COUNT, 
    SALES_AGENT_TRANSACTION_REPORT_TOTAL_PAGES,
    TRANSACTION_PER_LOADING_REPORT_COUNT,
    TRANSACTION_PER_LOADING_REPORT_TOTAL_PAGE
} from '../actions/types';

const initialState = {
    salesAgentTransactionReportCount: 0,
    salesAgentTransactionReportTotalPages: 0,
    transactionPerLoadingReportCount:0,
    transactionPerLoadingReportTotalPages:0
};

const reportReducer = (reportState = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case SALES_AGENT_TRANSACTION_REPORT_COUNT:
        return { ...reportState, salesAgentTransactionReportCount: payload };
      case SALES_AGENT_TRANSACTION_REPORT_TOTAL_PAGES:
        return { ...reportState, salesAgentTransactionReportTotalPages: payload };
      case TRANSACTION_PER_LOADING_REPORT_COUNT:
        return { ...reportState, transactionPerLoadingReportCount: payload };
      case TRANSACTION_PER_LOADING_REPORT_TOTAL_PAGE:
        return { ...reportState, transactionPerLoadingReportTotalPages: payload };
      default:
        return reportState;
    }
  };
  
  export default reportReducer;
  