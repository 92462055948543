import React, { useEffect, useState } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import GeneralInfoForm from './generalinfoForm';
import { ProfileCardWidget } from '../components/Widgets';
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import userApi from '../services/user-service';
import { isAuthenticated } from '../auth';
import { showError } from '../components/Alerts';
import moment from 'moment-timezone';
import { UploadPhoto } from '../components/UploadPhoto';

function Profile() {
  const [userProfile, setUserProfile] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const { getProfileInfo } = userApi;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPortalPage('Profile'));

    const userId = isAuthenticated().user?._id;

    getProfileInfo(userId).then((data) => {
      if (data.error) {
        showError(data.error);
      }
      setUserProfile(data);
    });
  }, [dispatch, getProfileInfo]);

  const getFullName = () => {
    if (userProfile)
      return `${userProfile.user?.firstname} ${userProfile.user?.middlename} ${userProfile.user?.lastname}`;
    return '';
  };

  const getJobTitle = () => {
    if (userProfile) return userProfile?.jobTitle;
    return '';
  };

  const getDateHired = () => {
    if (userProfile) return moment(userProfile?.dateHired).format('ll');
    return '';
  };

  const isUserActive = () => {
    if (userProfile) return userProfile.user?.isActive;
    return false;
  };

  return (
    <Row>
      <Col xs={12} xl={8}>
        {userProfile && (
          <GeneralInfoForm
            isEditMode={editProfile}
            userProfile={userProfile}
            cancelEdit={setEditProfile}
            isManager={false}
          />
        )}
      </Col>

      <Col xs={12} xl={4}>
        <Row>
          <Col xs={12}>
            <ProfileCardWidget
              fullName={getFullName()}
              jobTitle={getJobTitle()}
              dateHired={getDateHired()}
              isActive={isUserActive()}
              photoUrl={isAuthenticated().user.photoUrl}
            />
          </Col>
          <Col xs={12}>
            {userProfile && editProfile && (
              <UploadPhoto userProfileId={userProfile._id} photoUrl={userProfile.photoUrl} />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Profile;
