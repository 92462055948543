import { CLIENT_COUNT, CLIENT_TOTAL_PAGES } from './types';

export const setClientCount = (clientCount) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(clientCount / limit);

    dispatch({
      type: CLIENT_COUNT,
      payload: clientCount,
    });

    dispatch(setClientTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setClientTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: CLIENT_TOTAL_PAGES,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
