import { 
    QUOTATION_TOTAL_PAGES, 
    QUOTATION_COUNT,
} from './types';

export const setQuotationCount = (status, count) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
    const totalPages = Math.ceil(count / limit);

    dispatch({
      type: QUOTATION_COUNT,
      payload: { status, count },
    });

    dispatch(setQuotationTotalPages(status, totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setQuotationTotalPages = (status, totalPages) => (dispatch) => {
  try {
    dispatch({
      type: QUOTATION_TOTAL_PAGES,
      payload: { status, totalPages },
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
