import React, { useState } from "react";
import { Card, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { faCheckCircle, faFilePdf, faEye, faTimesCircle, faFileDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import BillingDocumentDetail from "./billingdocumentdetail";
import BillingDocumentPreview from "./billingdocumentpreview";
import { confirmBillingDocumentDialog, confirmArchiveDialogEmptyCallback, showOk } from "../components/Alerts";
import billingApi from '../services/billing-service';
import BillingCancelReason from "./billingcancelreason";
import { generatePdf } from '../pdfGenerator';
import { hasPermission } from "../auth/UserPermissionTypes";
import { BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING, BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING, PROCESS_BILLING_MANAGER_APPROVED_BILLING, PROCESS_BILLING_MANAGER_CANCEL_BILLING, PROCESS_BILLING_MANAGER_PREVIEW_BILLING } from "../utils/constants";

const BillingDocumentHolder = (props) => {
    const { 
        pendingBillingList,
        hideEvent,
        billingDocumentData, 
        refreshList, 
        canModify, 
        donwloadPdf,
        sendBillingDocument
    } = props;
    const [documentViewState, setDocumentViewState] = useState('Billing Details');
    const { approveBillingData } = billingApi
    const [showCancelReason, setShowCancelReason] = useState(false);

    const handleApproveBilling = async (elementId) => {
        let text = 'Do you want to approve this billing document?';
        let confirmBtnText = 'Yes, approve it!';
        let callbackTitle = 'Billing approved!';
        let callbackText = 'Billing document approved!';
    
        var statusConfirm = await confirmBillingDocumentDialog(text, confirmBtnText, callbackTitle, callbackText);

        if (statusConfirm) {
            let formData = new FormData();

            const pdfContent = document.getElementById(elementId).innerHTML;
            const pdfBlob = await generatePdf(pdfContent);

            formData.append('generatedBillingId', billingDocumentData._id);
            formData.append('pdfData', pdfBlob, 'billing_statement.pdf');
    
            approveBillingData(formData)
                .then(() => {
                    showOk('Billing document approved');
                    refreshList();
                    hideEvent();
                })
                .catch((e) => {
                    console.log(e)
                });
        }
    };

    const handleCancelBillingDocument = async () => {
        let text = 'Do you want to reject this billing?';
        let confirmBtnText = 'Yes, reject it!';
    
        var statusConfirm = await confirmArchiveDialogEmptyCallback (text, confirmBtnText);
        if (statusConfirm) {
            setShowCancelReason(true);
        }
    };

    return (
        <>
            {showCancelReason && (
                <BillingCancelReason generatedBillingId={billingDocumentData._id} cancelEvent={setShowCancelReason} refreshList={refreshList} />
            )}
            <Card border="light" className="shadow-sm document-details">
                <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">{documentViewState}</h5>
                    <ButtonGroup>
                        <Button className={documentViewState === 'Billing Details' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Billing Details')}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Show Details
                        </Button>
                        {hasPermission(PROCESS_BILLING_MANAGER_PREVIEW_BILLING) && (
                            <Button className={documentViewState === 'Billing Preview' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Billing Preview')}>
                                <FontAwesomeIcon icon={faFilePdf} className="me-2" /> Preview
                            </Button>
                        )}
                        {billingDocumentData.status === 'APPROVED' && (
                            <>
                                {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => donwloadPdf(`element-to-print_${billingDocumentData._id}`, billingDocumentData._id)}>
                                        <FontAwesomeIcon icon={faFileDownload} className="me-2" /> Download
                                    </Button>
                                )}
                                {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => sendBillingDocument(billingDocumentData._id)}>
                                        <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send
                                    </Button>
                                )}
                            </>
                        )}
                        {canModify && (
                            <>
                                {hasPermission(PROCESS_BILLING_MANAGER_APPROVED_BILLING) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => handleApproveBilling(`element-to-print_${billingDocumentData._id}`)}>
                                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Approve
                                    </Button>
                                )}
                                {hasPermission(PROCESS_BILLING_MANAGER_CANCEL_BILLING) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => handleCancelBillingDocument()}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" /> Reject
                                    </Button>
                                )}
                            </>
                        )}
                    </ButtonGroup>
                </Card.Header>
            </Card>
            <Card.Body>
                {documentViewState === 'Billing Details' && (
                    <BillingDocumentDetail 
                        billingDocumentData={billingDocumentData} 
                        refreshList={refreshList} 
                        canModify={canModify}
                        pendingBillingList={pendingBillingList}
                    />
                )}

                {documentViewState === 'Billing Preview' && (
                    <BillingDocumentPreview billingDocumentData={billingDocumentData}/>
                )}
            </Card.Body>
            <Card.Footer>
                <Button variant="primary" className="ms-auto pull-right" onClick={() => hideEvent()}>
                    Hide
                </Button>
            </Card.Footer>

            <div className='element-to-print' id={`element-to-print_${billingDocumentData._id}`}>
              <BillingDocumentPreview billingDocumentData={billingDocumentData}/>
            </div>
        </>
    );
};

export default BillingDocumentHolder;
