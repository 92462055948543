import { 
  APPROVE_BILLINGS_COUNT, 
  APPROVE_BILLINGS_TOTAL_PAGES,
  CANCELLED_BILLINGS_COUNT,
  CANCELLED_BILLINGS_TOTAL_PAGES
 } from '../actions/types';

const initialState = {
  approvedCount: 0,
  approvedTotalPages: 0,
  cancelledCount: 0,
  cancelledTotalPages: 0,
};

const billingDocumentReducer = (billingDocumentState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APPROVE_BILLINGS_COUNT:
      return { ...billingDocumentState, approvedCount: payload };
    case APPROVE_BILLINGS_TOTAL_PAGES:
      return { ...billingDocumentState, approvedTotalPages: payload };
    case CANCELLED_BILLINGS_COUNT:
      return { ...billingDocumentState, cancelledCount: payload };
    case CANCELLED_BILLINGS_TOTAL_PAGES:
      return { ...billingDocumentState, cancelledTotalPages: payload };
    default:
      return billingDocumentState;
  }
};

export default billingDocumentReducer;
