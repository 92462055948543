import React, { useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { showOk } from '../components/Alerts';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import rateBracketApi from '../services/rate-bracket-service';

const RateBracketArchiveReason = (props) => {
  const { 
    rateBracketId,
    cancelEvent,
    refreshList
  } = props;

  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [loading, setLoading] = useState(false);
  const { setRateBracketStatus } = rateBracketApi;

  const archiveRateBracketSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
  });

  const formOptions = {
    resolver: yupResolver(archiveRateBracketSchema),
    mode: 'all',
  };

  const { register, handleSubmit, getValues, formState, reset } = useForm(formOptions);

  const { errors } = formState;

  const handleArchiveRateBracket = () => {
    setLoading(true);

    let rateBracketData = new FormData();

    const reason = getValues('reason');

    rateBracketData.append('rateBracketId', rateBracketId);
    rateBracketData.append('status', false);
    rateBracketData.append('reason', reason);

    setRateBracketStatus(rateBracketData).then(() => {
        showOk('Rate bracket archived!');
        reset();
        cancelEvent();
        refreshList();
    });
  }

  return (
    <Modal as={Dialog} backdropClassName="" centered show={true}>
      <Form onSubmit={handleSubmit(handleArchiveRateBracket)}>
        <Header>
          <Title className="h6">Provide reason why your archive rate bracket</Title>
          <Button variant="close" aria-label="Close" onClick={() => cancelEvent()} />
        </Header>
        <Body>
            <Row className='align-items-start'>
                <Col sm={12} className="mb-3">
                    <Form.Group id="reason">
                        <Form.Label className="light-font">Reason</Form.Label>
                        <Form.Control as="textarea" placeholder='Please add reason' {...register('reason')} rows="3" />
                        {errors.reason?.message && <small className="invalid-fields">{errors.reason.message}</small>}
                    </Form.Group>
                </Col>
            </Row>
        </Body>
        <Footer>
          <Button type="submit" disabled={loading} variant="secondary">
            Submit
          </Button>
          <Button variant="primary" className="ms-auto" onClick={() => cancelEvent()}>
            Cancel
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default RateBracketArchiveReason;
