import React, { useEffect, useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert, Image } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import authApi from '../services/auth-api-service';
import SeaRoadBrand from '../assets/img/searoadtraidinglogo.png';

const ResetPassword = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const [validResetPasswordKey, setValidResetPasswordKey] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [accountPasswordResetOk, setAccountPasswordResetOk] = useState(false);
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const { validateResetPassword, accountResetPassword, confirmResetPassword } = authApi;

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Password is required')
      .min(8, 'Password must have at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Minimum eight characters, at least one letter, one number and one special character',
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, getValues, setValue, formState, setError } = useForm(formOptions);
  const { errors } = formState;

  const onSubmitOtp = (e) => {
    e.preventDefault();

    const otp = getValues('yourOTP');

    if (!otp) {
      setError('yourOTP', {
        type: 'manual',
        message: 'OTP is required. Please check your email.',
      });

      return;
    }

    setLoading(true);

    setErrorMessage('');

    let confirmFormData = new FormData();

    const newPassword = getValues('newPassword');

    confirmFormData.append('otp', otp);
    confirmFormData.append('newPassword', newPassword);
    confirmFormData.append('token', getTokenFromURL());

    confirmResetPassword(confirmFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setPasswordResetComplete(true);
          setLoading(false);
        } else {
          setErrorMessage('The provided OTP is invalid.');
          setValue('yourOTP', '');
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage('The provided OTP is invalid.');
        setValue('yourOTP', '');
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    setErrorMessage('');
    setAccountPasswordResetOk(false);

    const newPassword = getValues('newPassword');
    const tokenKey = getTokenFromURL();

    let resetPasswordFormData = new FormData();

    resetPasswordFormData.append('newPassword', newPassword);
    resetPasswordFormData.append('token', tokenKey);

    accountResetPassword(resetPasswordFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setVerifyOtp(true);
          setLoading(false);
          setAccountPasswordResetOk(true);
        } else {
          setAccountPasswordResetOk(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage(e.data.error);
        setLoading(false);
      });
  };

  const getTokenFromURL = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('key');
  }, [location]);

  useEffect(() => {
    const tokenKey = getTokenFromURL();

    if (tokenKey) {
      validateResetPassword(tokenKey)
        .then((data) => {
          const isValid = data.isValidKey;
          setValidResetPasswordKey(isValid);
          setLoading(false);
        })
        .catch((ex) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [getTokenFromURL, location, validateResetPassword, setValidResetPasswordKey, setLoading]);

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 d-flex align-items-center">
        <Container>
          <Row
            className="justify-content-center"
          >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-5 mt-md-0">
                  <Image src={SeaRoadBrand} className="navbar-brand-light" />
                </div>
                {loading ? (
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Validating your request. Please wait..</h3>
                  </div>
                ) : (
                  <>
                    {passwordResetComplete ? (
                      <p className="text-center">
                        <Card.Link as={Link} to={'/'} className="text-gray-700">
                          <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Go to login page
                        </Card.Link>
                      </p>
                    ) : (
                      <p className="text-center">
                        <Card.Link as={Link} to="/" className="text-gray-700">
                          <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back home page
                        </Card.Link>
                      </p>
                    )}
                    {passwordResetComplete ? (
                      <Alert variant="success">Password reset compelete. Please go to login page</Alert>
                    ) : (
                      <>
                        {!accountPasswordResetOk && !validResetPasswordKey ? (
                          <Alert variant="danger">
                            Oops, looks like there's an issue with your reset password request. Just get in touch with your Sea Road Trading Inc. administrator and they'll sort it out for you.
                          </Alert>
                        ) : (
                          <>
                            <Col xs={12} className="d-flex align-items-center justify-content-center">
                              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <h3 className="mb-4">Reset password</h3>
                                {errorMessage !== '' && <Alert variant="danger">{errorMessage}</Alert>}
                                {verifyOtp ? (
                                  <Form className="mt-4" onSubmit={onSubmitOtp}>
                                    <Form.Group id="yourOTP" className="mb-4">
                                      <Form.Label>Please enter OTP sent in your email</Form.Label>
                                      <InputGroup>
                                        <Form.Control autoFocus name="yourOTP" type="text" {...register('yourOTP')} />

                                        {errors.yourOTP?.message && (
                                          <small className="invalid-fields">{errors.yourOTP.message}</small>
                                        )}
                                      </InputGroup>
                                    </Form.Group>
                                    <Button variant="primary" disabled={loading} type="submit" className="w-100">
                                      Submit
                                    </Button>
                                  </Form>
                                ) : (
                                  <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group id="newPassword" className="mb-4">
                                      <Form.Label>New Password</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <FontAwesomeIcon icon={faUnlockAlt} />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="password"
                                          name="new-password"
                                          {...register('newPassword')}
                                          placeholder="New Password"
                                        />

                                        {errors.newPassword?.message && (
                                          <small className="invalid-fields">{errors.newPassword.message}</small>
                                        )}
                                      </InputGroup>
                                    </Form.Group>
                                    <Form.Group id="confirmPassword" className="mb-4">
                                      <Form.Label>Confirm Password</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <FontAwesomeIcon icon={faUnlockAlt} />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="password"
                                          name="confirmpassword"
                                          {...register('confirmPassword')}
                                          placeholder="Confirm Password"
                                        />
                                        {errors.confirmPassword?.message && (
                                          <small className="invalid-fields">{errors.confirmPassword.message}</small>
                                        )}
                                      </InputGroup>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100">
                                      Reset password
                                    </Button>
                                  </Form>
                                )}
                              </div>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;
