import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEye, faPaperPlane, faCalendar, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, ButtonGroup, InputGroup, Form, Row, Col } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setApproveBillingCount } from '../actions/billingdocument';
import { BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING, defaultPageNumber } from '../utils/constants';
import billingApi from '../services/billing-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { formatCurrency } from "../utils/formatter";
import PortalPagination from '../components/PortalPagination';
import './styles.scss'
import { generatePdf } from '../pdfGenerator';
import { confirmBillingDocumentDialog, showError, showOk } from '../components/Alerts';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import BillingHolder from './billingholder';
import BillingPreview from './billingpreview';
import { hasPermission } from '../auth/UserPermissionTypes';

const ApprovedBillings = () => {
    const dispatch = useDispatch();
    const { getApprovedCount, getApprovedList, uploadPdfDocument, sendBillingDocument } = billingApi;
    const totalRecords = useSelector((state) => state.billingDocumentState.approvedCount) || 0;
    const [selectedBillingDocument, setSelectedBillingDocument] = useState(null);
    const [approvedList, setApprovedList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState();
    const [endDateFilter, setEndDateFilter] = useState();
    const [searchContainerNumber, setSearchContainerNumber] = useState('');
    const [resetTriggered, setResetTriggered] = useState(false);

    const handleSelecteBillingDocument = (id) => {
        setSelectedBillingDocument(id);
    }

    const handlePageEvent = (pageNumber) => {
      handleFetchApprovedBillings(pageNumber);
    };

    const handleReset = () => {
      setSearchTerm('');
      setStartDateFilter(null);
      setEndDateFilter(null);
      setSearchContainerNumber('');
      setResetTriggered(true); 
    };

    const onSearchChange = (e) => {
      setSearchTerm(e.target.value || '');
    };

    const onkeyPressSearch = (e) => {
      if (e.key === 'Enter') {
        handleFetchApprovedBillings();
      }
    };
  
    const onFilterContainerChange = (e) => {
      setSearchContainerNumber(e.target.value || '');
    };

    const handleSearchDocument = () => {
      if (startDateFilter && endDateFilter && moment(startDateFilter).isAfter(endDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }

      handleFetchApprovedBillings(defaultPageNumber);
    }

    const handleFetchApprovedBillings = async (pageNumber = 1) => {
      setSelectedPage(pageNumber);

      try {
        const countRes = await getApprovedCount(searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        dispatch(setApproveBillingCount(countRes.totalCount));

        const listRes = await getApprovedList(pageNumber, searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        setApprovedList(listRes.results);
      } catch (error) {
        console.error('Error while searching approved list:', error);
      }
    }

    const handleCancelView = () => {
        setSelectedBillingDocument(null);
    }

    const handleStartDateChange = (date) => {
      if (endDateFilter && date.isAfter(endDateFilter)) {
        showError("Start date cannot be later than end date.")
        return;
      }
      setStartDateFilter(date);
    };
  
    const handleEndDateChange = (date) => {
      if (startDateFilter && date.isBefore(startDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }
      setEndDateFilter(date);
    };

    useEffect(() => {
      getApprovedCount(searchTerm, startDateFilter, endDateFilter).then((res) => {
        dispatch(setApproveBillingCount(res.totalCount));
        getApprovedList(1, searchTerm, startDateFilter, endDateFilter).then((res) => {
          setApprovedList(res.results);
        }); 
      });
    }, [getApprovedCount, dispatch, getApprovedList, endDateFilter, searchTerm, startDateFilter]);

    useEffect(() => {
      handleFetchApprovedBillings(defaultPageNumber);
      setResetTriggered(false);
    }, [resetTriggered]);

    const handleDownloadPdf = async (elementId, billingId) => {
      try {
        const pdfContent = document.getElementById(elementId).innerHTML;
        const pdfBlob = await generatePdf(pdfContent, 'billing-statement.pdf');
    
        // Prepare FormData
        const formData = new FormData();
        formData.append('pdfData', pdfBlob, 'document.pdf'); // Include file name if desired
        formData.append('billingId', billingId);
    
        // Send the PDF to the backend for upload
        const apiResponse = await uploadPdfDocument(formData);
    
        if (apiResponse) {
          // Create a link and open the file in a new tab
          const link = document.createElement('a');
          link.href = apiResponse.downloadUrl;
          link.target = '_blank'; // Open in a new tab
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error('Error generating or uploading PDF:', error);
      }
    };

    const handleSendBillingDocument = async (billingId) => {
      let text = 'Do you want to send this billing document to client?';
      let confirmBtnText = 'Yes, send it!';
  
      var statusConfirm = await confirmBillingDocumentDialog(text, confirmBtnText);

      if (statusConfirm) {
        let formData = new FormData();
        formData.append('billingId', billingId);

        sendBillingDocument(formData)
            .then(() => {
                showOk('Billing document sent to client');
            })
            .catch((e) => {
                console.log(e)
            });
      }
    };

    const TableRow = (document) => {
      const { displayName } = document.clientData;
      const { 
          _id,
          sumOfCBM, 
          sumOfSensitiveCharge, 
          sumOfForklift, 
          sumOfInland, 
          sumOfGrossWeight,
          grossAmount,
          totalBillableAmount,
      } = document;

      let rate = 0;

      if(document.appliedRateData) rate = document.appliedRateData.rate;

      if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
        const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
        rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
      }

      return (
        <>
          <tr>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    displayName || ''
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfCBM)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfForklift)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfInland)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfGrossWeight)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(rate)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(grossAmount)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(totalBillableAmount)) || 0.00
                  )}
              </span>
            </td>
            <td>
              {loading && selectedBillingDocument == null ? (
                  <Skeleton wrapper={SkeletonWrapper} />
              ) : (
                  <span className="fw-normal text-success">APPROVED</span>
              )}
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="more-left-dropdown-menu">
                  <Dropdown.Item onClick={() => handleSelecteBillingDocument(_id)}>
                      <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                  </Dropdown.Item>
                  {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING) && (
                    <Dropdown.Item onClick={() => handleSendBillingDocument(_id)}>
                      <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          {selectedBillingDocument === _id && (
            <tr>
              <td colSpan={11}>
                <BillingHolder
                  hideEvent={handleCancelView} 
                  billingDocumentData={document} 
                  donwloadPdf={handleDownloadPdf}
                  sendBillingDocument={handleSendBillingDocument}
                />
                <div className='element-to-print' id={`element-to-print_${_id}`}>
                  <BillingPreview billingDocumentData={document}/>
                </div>
              </td>
            </tr>
          )}
        </>
      );
  };


    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0 search-block-width-400">
                <p className="mb-0">Search and filter approved billings</p>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0"></div>
            </div>
            <div className="table-settings mb-4">
              <Row className="justify-content-between align-items-center">
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      onKeyPress={onkeyPressSearch}
                      value={searchTerm}
                      onChange={onSearchChange}
                      placeholder="Search"
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <Datetime
                    timeFormat={false}
                    onChange={handleStartDateChange}
                    closeOnSelect={true}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="startDateFilter"
                          value={startDateFilter ? moment(startDateFilter).format('MM/DD/YYYY') : ''}
                          placeholder="Filter start date"
                          onFocus={openCalendar}
                          onChange={(e) => setStartDateFilter(e.target.value)}
                        />
                      </InputGroup>
                    )}
                  />
                </Col>
                <Col md={3}>
                  <Datetime
                    timeFormat={false}
                    onChange={handleEndDateChange}
                    closeOnSelect={true}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="endDateFilter"
                          value={endDateFilter ? moment(endDateFilter).format('MM/DD/YYYY') : ''}
                          placeholder="Filter end date"
                          onFocus={openCalendar}
                          onChange={(e) => setEndDateFilter(e.target.value)}
                          disabled={!startDateFilter}
                        />
                      </InputGroup>
                    )}
                  />
                </Col>
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faTruck} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      onKeyPress={onkeyPressSearch}
                      value={searchContainerNumber}
                      onChange={onFilterContainerChange}
                      placeholder="Filter container number"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="justify-content-between align-items-center mt-3">
                <Col md={3}>
                  <Button variant="primary" type="button" onClick={() => handleSearchDocument()}>
                    Search
                  </Button>
                  <Button className='ms-1' variant="secondary" type="button" onClick={() => handleReset()}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
            <Table hover className="billing-table-list align-items-center mt-3">
                <thead>
                    <tr>
                        <th className="border-bottom">Display Name</th>
                        <th className="border-bottom">Sum of CBM</th>
                        <th className="border-bottom">Sum of Sensitive Charge</th>
                        <th className="border-bottom">Sum of Forklift</th>
                        <th className="border-bottom">Sum of Inland</th>
                        <th className="border-bottom">Sum of Gross Wt</th>
                        <th className="border-bottom">Rate</th>
                        <th className="border-bottom">Freight Amount</th>
                        <th className="border-bottom">Billable Amount</th>
                        <th className="border-bottom">Status</th>
                        <th className="border-bottom"></th>
                    </tr>
                </thead>
                <tbody>
                    {approvedList && approvedList.map((t, i) => <TableRow key={`approved-billing-${i}`} {...t} />)}
                    {approvedList &&approvedList.length === 0 && (
                      <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                    )}
                </tbody>
            </Table>
            {!loading && (
                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
            )}
          </Card.Body>
      </Card>
    )
}

export default ApprovedBillings;