import React, { useState } from 'react';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showOk, showError } from '../components/Alerts';
import userApi from '../services/user-service';

const GeneralInfoForm = (props) => {
  const { update, create } = userApi;
  const { isEditMode, userProfile, cancelEdit, showCancelButton } = props;

  const [loading, setLoading] = useState(false);
  const [birthday, setBirthday] = useState(userProfile?.birthDate);
  const [datehired, setDateHired] = useState(userProfile?.dateHired);

  const formSchema = Yup.object().shape({
    employeeId: Yup.string().required('Employee id is required'),
    firstname: Yup.string().required('First name is required'),
    middlename: Yup.string().required('Middle name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Invalid email address'),
    gender: Yup.string().required('Gender is required'),
    contactnumber: Yup.string().required('Contact number is required'),
    civilstatus: Yup.string().required('Civil status is required'),
    employmentstatus: Yup.string().required('Employment status is required'),
    permanentaddress: Yup.string().required('Permanent address is required'),
    permanentcity: Yup.string().required('Permanent city is required'),
    permanentprovince: Yup.string().required('Permanent province is required'),
    permanentpostalcode: Yup.string().required('Permanent postal code is required'),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      employeeId: userProfile?.user.employeeId,
      firstname: userProfile?.user.firstname,
      middlename: userProfile?.user.middlename,
      lastname: userProfile?.user.lastname,
      email: userProfile?.user.email,
      gender: userProfile?.gender,
      contactnumber: userProfile?.contactNumber,
      civilstatus: userProfile?.civilStatus,
      employmentstatus: userProfile?.employmentStatus,
      permanentaddress: userProfile?.permanentAddress,
      permanentcity: userProfile?.permanentCity,
      permanentprovince: userProfile?.permanentProvince,
      permanentpostalcode: userProfile?.permanentPostalCode,
      presentaddress: userProfile?.presentAddress,
      presentcity: userProfile?.presentCity,
      presentprovince: userProfile?.presentProvince,
      presentpostalcode: userProfile?.presentPostalCode,
    },
  };
  const { register, handleSubmit, getValues, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = () => {
    if (birthday === '') {
      showError('Please add birth date');
      return;
    } else if (datehired === '') {
      showError('Please add date hired');
      return;
    }

    setLoading(true);

    let userData = new FormData();

    var formValues = getValues();

    for (const key in formValues) {
      if (key === 'field') {
        let formValue = formValues[key][1];

        if (key === 'birthdate') {
          formValue = moment(birthday).format('MM/DD/YYYY');
        } else if (key === 'datehired') {
          formValue = moment(datehired).format('MM/DD/YYYY');
        }

        userData.append(key, formValue);
      } else {
        let formValue = formValues[key];

        if (key === 'birthdate') {
          formValue = moment(birthday).format('MM/DD/YYYY');
        } else if (key === 'datehired') {
          formValue = moment(datehired).format('MM/DD/YYYY');
        }
        userData.append(key, formValue);
      }
    }

    if (userProfile) {
      userData.append('userId', userProfile?.user._id);

      update(userData)
        .then((data) => {
          if (data.error) {
            showError(data.error);
            setLoading(false);
          } else {
            showOk('User profile saved');
            cancelEdit();
            setLoading(false);
          }
        })
        .catch((e) => {
          showError(e?.data?.error);
          setLoading(false);
        });
    } else {
      create(userData)
        .then((data) => {
          if (data.error) {
            showError(data.error);
            setLoading(false);
          } else {
            showOk('User profile saved');
            setLoading(false);
            cancelEdit();
            reset();
          }
        })
        .catch((e) => {
          showError(e?.data?.error);
          setLoading(false);
        });
    }
  };

  return (
    <Card border="dark" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h3 className="mb-4 border-bottom border-light pb-md-3 mb-md-5">Personal Information</h3>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="employeeId">
                <Form.Label className="light-font">Employee Id</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="employeeId" placeholder="Employee Id" {...register('employeeId')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.user.employeeId || '--'}</p>
                )}
                {errors.employeeId?.message && <small className="invalid-fields">{errors.employeeId.message}</small>}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label className="light-font">First Name</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="firstname" placeholder="First name" {...register('firstname')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.user.firstname || ''}</p>
                )}
                {errors.firstname?.message && <small className="invalid-fields">{errors.firstname.message}</small>}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="middlename">
                <Form.Label className="light-font">Middle Name</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="middlename" placeholder="Middle name" {...register('middlename')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.user.middlename || ''}</p>
                )}
                {errors.middlename?.message && <small className="invalid-fields">{errors.middlename.message}</small>}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={4} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="light-font">Last Name</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="lastname" placeholder="Last name" {...register('lastname')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.user.lastname || ''}</p>
                )}
                {errors.lastname?.message && <small className="invalid-fields">{errors.lastname.message}</small>}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="email">
                <Form.Label className="light-font">Email</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="email" placeholder="Email" {...register('email')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.user.email || ''}</p>
                )}
                {errors.email?.message && <small className="invalid-fields">{errors.email.message}</small>}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label className="light-font">Birthday</Form.Label>
                {isEditMode ? (
                  <Datetime
                    timeFormat={false}
                    onChange={setBirthday}
                    closeOnSelect={true}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="birthdate"
                          value={birthday ? moment(birthday).format('MM/DD/YYYY') : ''}
                          placeholder="mm/dd/yyyy"
                          onFocus={openCalendar}
                          {...register('birthdate')}
                          onChange={setBirthday}
                        />
                      </InputGroup>
                    )}
                  />
                ) : (
                  <p className="mb-0 bold-700">{moment(userProfile?.birthDate).format('ll') || ''}</p>
                )}
                {errors.birthdate?.message && <small className="invalid-fields">{errors.birthdate.message}</small>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label className="light-font">Gender</Form.Label>
                {isEditMode ? (
                  <Form.Select name="gender" defaultValue="" {...register('gender')}>
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.gender || ''}</p>
                )}
                {errors.gender?.message && <small className="invalid-fields">{errors.gender.message}</small>}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="contactnumber">
                <Form.Label className="light-font">Contact Number</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="contactnumber"
                    placeholder="Contact number"
                    {...register('contactnumber')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.contactNumber || ''}</p>
                )}
                {errors.contactnumber?.message && (
                  <small className="invalid-fields">{errors.contactnumber.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="civilstatus">
                <Form.Label className="light-font">Civil Status</Form.Label>
                {isEditMode ? (
                  <Form.Select defaultValue="" name="civilstatus" {...register('civilstatus')}>
                    <option value="">Status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Separated">Separated</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.civilStatus || ''}</p>
                )}
                {errors.civilstatus?.message && <small className="invalid-fields">{errors.civilstatus.message}</small>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="employment-status">
                <Form.Label className="light-font">Employment Status</Form.Label>
                {isEditMode ? (
                  <Form.Select defaultValue="" name="employmentstatus" {...register('employmentstatus')}>
                    <option value="">Status</option>
                    <option value="Regular">Regular</option>
                    <option value="Casual">Casual</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="Fixed-term">Fixed-term</option>
                    <option value="Probationary">Probationary</option>
                  </Form.Select>
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.employmentStatus || ''}</p>
                )}
                {errors.employmentstatus?.message && (
                  <small className="invalid-fields">{errors.employmentstatus.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="date-hired">
                <Form.Label className="light-font">Date Hired</Form.Label>
                {isEditMode ? (
                  <Datetime
                    timeFormat={false}
                    onChange={setDateHired}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="datehired"
                          value={datehired ? moment(datehired).format('MM/DD/YYYY') : ''}
                          placeholder="mm/dd/yyyy"
                          onFocus={openCalendar}
                          {...register('datehired')}
                        />
                      </InputGroup>
                    )}
                  />
                ) : (
                  <p className="mb-0 bold-700">{moment(userProfile?.dateHired).format('ll') || ''}</p>
                )}
                {errors.datehired?.message && <small className="invalid-fields">{errors.datehired.message}</small>}
              </Form.Group>
            </Col>
          </Row>
          <h3 className="mb-4 border-bottom border-light mt-5 pb-md-3 mb-md-5">Permanent address</h3>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="permanent-address">
                <Form.Label className="light-font">Address</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="permanentaddress"
                    placeholder="Enter permanent address"
                    {...register('permanentaddress')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.permanentAddress || ''}</p>
                )}
                {errors.permanentaddress?.message && (
                  <small className="invalid-fields">{errors.permanentaddress.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="permanent-city">
                <Form.Label className="light-font">City</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="permanentcity" placeholder="City" {...register('permanentcity')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.permanentCity || ''}</p>
                )}
                {errors.permanentcity?.message && (
                  <small className="invalid-fields">{errors.permanentcity.message}</small>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="permanent-province">
                <Form.Label className="light-font">Province</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="permanentprovince"
                    placeholder="Province"
                    {...register('permanentprovince')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.permanentProvince || ''}</p>
                )}
                {errors.permanentprovince?.message && (
                  <small className="invalid-fields">{errors.permanentprovince.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group id="permanent-postal-code">
                <Form.Label className="light-font">Postal Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    name="permanentpostalcode"
                    placeholder="Postal Code"
                    {...register('permanentpostalcode')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.permanentPostalCode || ''}</p>
                )}
                {errors.permanentpostalcode?.message && (
                  <small className="invalid-fields">{errors.permanentpostalcode.message}</small>
                )}
              </Form.Group>
            </Col>
          </Row>
          <h3 className="mb-4 border-bottom border-light mt-5 pb-md-3 mb-md-5">Present address</h3>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="present-address">
                <Form.Label className="light-font">Address</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="presentaddress"
                    placeholder="Enter present address"
                    {...register('presentaddress')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.presentAddress || ''}</p>
                )}
                {errors.presentaddress?.message && (
                  <small className="invalid-fields">{errors.presentaddress.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="present-city">
                <Form.Label className="light-font">City</Form.Label>
                {isEditMode ? (
                  <Form.Control type="text" name="presentcity" placeholder="City" {...register('presentcity')} />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.presentCity || ''}</p>
                )}
                {errors.presentcity?.message && <small className="invalid-fields">{errors.presentcity.message}</small>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="present-province">
                <Form.Label className="light-font">Province</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="presentprovince"
                    placeholder="Province"
                    {...register('presentprovince')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.presentProvince || ''}</p>
                )}
                {errors.presentprovince?.message && (
                  <small className="invalid-fields">{errors.presentprovince.message}</small>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group id="present-postal-code">
                <Form.Label className="light-font">Postal Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    name="presentpostalcode"
                    placeholder="Postal Code"
                    {...register('presentpostalcode')}
                  />
                ) : (
                  <p className="mb-0 bold-700">{userProfile?.presentPostalCode || ''}</p>
                )}
                {errors.presentpostalcode?.message && (
                  <small className="invalid-fields">{errors.presentpostalcode.message}</small>
                )}
              </Form.Group>
            </Col>
          </Row>
          {isEditMode && (
            <div className="mt-3">
              <Button variant="primary" type="submit" disabled={loading} className="me-1">
                Save
              </Button>
              <Button variant="primary" type="button" disabled={loading} onClick={() => cancelEdit(false)}>
                Cancel
              </Button>
            </div>
          )}

          {!isEditMode && showCancelButton && (
            <Button variant="primary" type="button" disabled={loading} onClick={() => cancelEdit(false)}>
              Cancel
            </Button>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default GeneralInfoForm;
