import { 
  APPROVE_BILLINGS_COUNT, 
  APPROVE_BILLINGS_TOTAL_PAGES, 
  CANCELLED_BILLINGS_COUNT, 
  CANCELLED_BILLINGS_TOTAL_PAGES 
} from './types';

export const setApproveBillingCount = (count) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(count / limit);

    dispatch({
      type: APPROVE_BILLINGS_COUNT,
      payload: count,
    });

    dispatch(setApproveBillingsTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setApproveBillingsTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: APPROVE_BILLINGS_TOTAL_PAGES,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setCancelledBillingCount = (count) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(count / limit);

    dispatch({
      type: CANCELLED_BILLINGS_COUNT,
      payload: count,
    });

    dispatch(setCancelledBillingsTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setCancelledBillingsTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: CANCELLED_BILLINGS_TOTAL_PAGES,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
