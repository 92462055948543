import { requests } from './api-service';

const productResourceApi = {
  uploadResource: (formData) => requests.putForm('product/resource/upload', formData),
  productResources: (productId, isActive) => requests.get('product/resources', setProductResourcesParams(productId, isActive)),
  setPrimaryToResource: (formData) => requests.putForm('product/resource/set-primary', formData),
  deleteResource: (id) => requests.delete(`product/resource/${id}`),
};

export const setProductResourcesParams = (productId, isActive) => {
  const params = new URLSearchParams();
  if (productId) params.append('productId', productId);
  if (isActive) params.append('isActive', isActive);
  return params;
};

export default productResourceApi;
