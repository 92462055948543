import axios from 'axios';
import { isAuthenticated } from '../auth';
import { history } from '..';
import { toast } from 'react-toastify';
import reduxStore from '../store';
import { hideLoader, showLoader } from '../actions/blanket';

// const sleep = () => new Promise((resolve) => setTimeout(resolve, 500));

const { dispatch } = reduxStore;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

const responseBody = (response) => response.data;

axios.interceptors.request.use((config) => {
  dispatch(showLoader());
  const token = isAuthenticated().token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // if (process.env.NODE_ENV === 'development') await sleep();
    dispatch(hideLoader());
    // Extract the new token from the response headers
    const newToken = response.headers['refresh-token'];

    // Extract the new token from the response body

    if (newToken) {
      // Retrieve the jwt from local storage
      const jwtString = localStorage.getItem('jwt');

      // Parse the jwt string into an object
      const jwtObject = JSON.parse(jwtString);

      if(jwtObject !== null)
      {
        // Update the token property
        jwtObject.token = newToken;

        // Stringify the updated object back into a JSON string
        const updatedJwtString = JSON.stringify(jwtObject);

        // Store the updated jwt back in local storage
        localStorage.setItem('jwt', updatedJwtString);
      }
    }

    return response;
  },
  (error) => {
    dispatch(hideLoader());
    if(!error) return Promise.reject(); 

    const { data, status } = error.response;
    switch (status) {
      case 400:
        toast.error("Sorry, system encountered bad request.");
        break;
      case 401:
        localStorage.removeItem('jwt');
        history.push({
          pathname: '/',
        });
        break;
      case 403:
        // toast.error("Sorry, you don't have permission to access this resource.");
        break;
      case 500:
        history.push({
          pathname: '/server-error',
          state: { error: data },
        });
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  },
);

export const requests = {
  get: (url, params) => axios.get(url, { params }).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
  postForm: (url, data) =>
    axios
      .post(url, data, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody),
  putForm: (url, data) =>
    axios
      .put(url, data, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody),
  putJson: (url, body) =>
    axios
      .put(url, body, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(responseBody),
};
