import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { Card } from '@themesberg/react-bootstrap';
import AuditDataTable from "./auditdatatable";

const AuditManager = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Audit Manager'));
    }, [dispatch]);

    return (
        <Card border="light" className="shadow-sm no-border-radius">
            <AuditDataTable />
        </Card>
    )
}

export default AuditManager;