import { RATE_BRACKET_COUNT, RATE_BRACKET_TOTAL_PAGES } from './types';

export const setRateBracketCount = (count) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(count / limit);

    dispatch({
      type: RATE_BRACKET_COUNT,
      payload: count,
    });

    dispatch(setRateBracketTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setRateBracketTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: RATE_BRACKET_TOTAL_PAGES,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
