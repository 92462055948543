import { JWT_TOKEN } from '../utils/constants';
import authApi from '../services/auth-api-service';
const { signOut } = authApi;

export const signup = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signin = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const authenticate = (jwt, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(JWT_TOKEN, JSON.stringify(jwt));
    next();
  }
};

export const setName = (name, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('username', JSON.stringify(name));
    next();
  }
};

export const signoutUser = (next) => {
  signOut().then(() => {
    localStorage.removeItem(JWT_TOKEN);
    next();
  }).catch(e => {
    console.log(e)
  });
};

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem(JWT_TOKEN)) {
    return JSON.parse(localStorage.getItem(JWT_TOKEN));
  } else {
    return false;
  }
};

export const forgotPassword = (email) => {
  return fetch(`${process.env.REACT_APP_API_URL}/forgot-password/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      console.log('forgot password response: ', response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (resetInfo) => {
  return fetch(`${process.env.REACT_APP_API_URL}/reset-password/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(resetInfo),
  })
    .then((response) => {
      console.log('forgot password response: ', response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const socialLogin = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/social-login/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // credentials: "include", // works only in the same origin
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log('signin response: ', response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const authenticatedUser = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem(JWT_TOKEN)) {
    var sessionData = JSON.parse(localStorage.getItem(JWT_TOKEN));
    return sessionData.user;
  } else {
    return null;
  }
};

//User permission type functions
