import { requests } from './api-service';

const clientApi = {
    getClientInfo: (clientId) => requests.get(`client/${clientId}`),
    create: (client) => requests.post('client', client),
    update: (client) => requests.put('client', client),
    uploadPhoto: (formData) => requests.putForm('client/photo/upload', formData),
    getCount: (search) => requests.get('client/count', setClientCountParams(search)),
    list: (pageNumber, search) => requests.get('clients', setClientListParams(pageNumber,search)),
    setClientStatus: (data) => requests.put('client/setstatus', data),
    uploadDocument: (formData) => requests.postForm('client/document/upload', formData),
    getClientAgreements: (clientId) => requests.get(`client/agreements/${clientId}`),
    deleteClientAgreement: (id) => requests.delete(`client/agreements/${id}`),
    updateDocument: (formData) => requests.putForm('client/agreements/update', formData),
    clientSetNotificationChannel: (formData) => requests.putForm('client/set/notificationchannel', formData),
    createSpecialRate: (specialRate) => requests.post('client/specialrate', specialRate),
    updateSpecialRate: (specialRate) => requests.put('client/specialrate', specialRate),
    getClientSpecialRates: (clientId) => requests.get(`client/specialrates/${clientId}`),
    setSpecialRateStatus: (data) => requests.put('client/specialrates/setstatus', data),
    uploadClientMigration: (formData) => requests.putForm('client/upload/migration', formData),
    getBillingCount: (clientId) => requests.get('client/billing/count', setBillingListCountParams(clientId)),
    billingTransactions: (clientId, pageNumber) => requests.get('client/billing/transactions', setBillingtListParams(clientId, pageNumber)),
    getActivePaymentTerms: (clientId) => requests.get('client/active/payment-terms', setParamWithClientId(clientId)),
    setClientPaymentTerm: (data) => requests.put('client/set/payment-term', data),
    getClientPaymentTerms: (clientId) => requests.get(`client/payment-terms/${clientId}`),
    setClientPaymentTermStatus: (data) => requests.put('client/payment-terms/setstatus', data),
    getClientNames: () => requests.get('client/active/list'),
    getSalesQuotationCount: (clientId) => requests.get('client/salesquotation/count', setBillingListCountParams(clientId)),
    salesQuotations: (clientId, pageNumber) => requests.get('client/salesquotation/transactions', setBillingtListParams(clientId, pageNumber)),
};

export const setClientCountParams = (search) => {
  const countParams = new URLSearchParams();
  if (search) countParams.append('search', search);
  return countParams;
};

export const setClientListParams = (pageNumber = 1,search = '') => {
  const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
  const params = new URLSearchParams();
  params.append('pageNumber', pageNumber.toString());
  params.append('pageSize', limit.toString());
  if (search) params.append('search', search);
  return params;
};

export const setBillingListCountParams = (clientId) => {
  const countParams = new URLSearchParams();
  countParams.append('clientId', clientId.toString());
  return countParams;
};

export const setBillingtListParams = (clientId, pageNumber = 1) => {
  const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
  const params = new URLSearchParams();
  params.append('clientId', clientId);
  params.append('pageNumber', pageNumber.toString());
  params.append('pageSize', limit.toString());
  return params;
};

export const setParamWithClientId = (clientId) => {
  const params = new URLSearchParams();
  params.append('clientId', clientId.toString());
  return params;
};

export default clientApi;