import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Image, Button } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import userApi from '../services/user-service';
import { showOk, showError } from '../components/Alerts';

export const UploadPhoto = (props) => {
  const { uploadPhoto } = userApi;
  const { userProfileId, photoUrl } = props;
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(photoUrl ? photoUrl : process.env.REACT_APP_DEFAULT_IMAGE);
  const [uploading, setUploading] = useState(false);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/i)) {
        showError('File does not support. You must select .png, .jpg or .gif files');
        return false;
      }

      if (file.size > 800000) {
        showError('Please upload a file smaller than 800 KB');
        return false;
      }
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUploadPhoto = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('userProfileId', userProfileId);
    formData.append('file', picture);
    uploadPhoto(formData)
      .then((res) => {
        showOk('Photo uploaded successfully');
        setUploading(false);
      })
      .catch((error) => {
        showError(error.data.error);
        setUploading(false);
      });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Select profile photo</h5>
        <div className="d-xl-flex align-items-center">
          <div className="user-avatar xl-avatar">
            <Image fluid rounded src={imgData} />
          </div>
          <div className="file-field">
            <div className="d-flex justify-content-xl-center ms-xl-3">
              <div className="d-flex">
                <span className="icon icon-md">
                  <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                </span>
                <input type="file" accept=".png,.jpg,.jpeg,.gif" name="avatar" onChange={onChangePicture} />
                <div className="d-md-block text-start">
                  <div className="fw-normal text-dark mb-1">Choose Image</div>
                  <div className="text-gray small">JPG, GIF or PNG. Max size of 800 KB</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-xl-flex align-items-center mt-2">
          <Button
            disabled={!picture || uploading}
            onClick={handleUploadPhoto}
            variant="primary"
            size="sm"
            className="me-2"
          >
            Upload
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
