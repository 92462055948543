import React, { useEffect, useCallback, useState } from 'react';
import { Card, Table } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setClientSalesQuotationCount } from '../actions/clientTransactions';
import { CANCELLED, DEFAULT_TIMZONE, defaultPageNumber } from '../utils/constants';
import clientApi from '../services/client-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { formatCurrency } from "../utils/formatter";
import PortalPagination from '../components/PortalPagination';
import moment from 'moment-timezone';

const ClientSalesQuotation = (props) => {
    const { clientId } = props;
    const dispatch = useDispatch();
    const { getSalesQuotationCount, salesQuotations } = clientApi;
    const totalRecords = useSelector((state) => state.clientTransactionState.salesQuotationCount) || 0;
    const [clientSalesQuotations, setClientSalesQuotations] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);

    const handlePageEvent = (pageNumber) => {
        handleFetchClientSalesQuotations(clientId, pageNumber);
    };

    const handleFetchClientSalesQuotations = useCallback(
        async (clientIdValue, pageNumber = 1) => {
          setSelectedPage(pageNumber);
    
          try {
            const countRes = await getSalesQuotationCount(clientIdValue);
    
            dispatch(setClientSalesQuotationCount(countRes.result));
    
            const listRes = await salesQuotations(clientIdValue);
    
            setClientSalesQuotations(listRes.result);
          } catch (error) {
            console.error('Error while fetching client sales quotations', error);
          }
        },
        [getSalesQuotationCount, dispatch, salesQuotations],
    );

    useEffect(() => {
        getSalesQuotationCount(clientId).then((res) => {
            dispatch(setClientSalesQuotationCount(res.result));
            salesQuotations(clientId, 1).then((res) => {
                setClientSalesQuotations(res);
            });
        });
    }, [getSalesQuotationCount, dispatch, setClientSalesQuotations, salesQuotations, clientId]);

    const TableRow = (document) => {
        const {
            quotationNumber, 
            totalBaseAmount, 
            totalMarkUpAmount, 
            validDateUntil,
            issueDate,
            paymentTermData,
            shipmentType,
            cancelReason,
            status
         } = document;

            const validUntilDate = moment(validDateUntil).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY');

            const paymentTermName = paymentTermData.name;

            const formatIssueDate = moment(issueDate).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY');
    
        return (
            <tr>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            quotationNumber
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(totalBaseAmount)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(totalMarkUpAmount)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatIssueDate
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            validUntilDate
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            paymentTermName
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            shipmentType
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            status
                        )}
                    </span>
                </td>
                <td>
                    {status === CANCELLED && (
                        <span className="fw-normal">
                            {loading ? (
                                <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                                <div className="truncated-text" title={cancelReason}>
                                    {cancelReason.length > 10 ? cancelReason.substring(0, 10) + '...' : cancelReason}
                                </div>
                            )}
                        </span>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <Table className="sales-quotation-table-list align-items-center mt-3">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation No.</th>
                                <th className="border-bottom">Total Amount</th>
                                <th className="border-bottom">Total Mark-up Amount</th>
                                <th className="border-bottom">Issue Date</th>
                                <th className="border-bottom">Valid Until</th>
                                <th className="border-bottom">Payment Term</th>
                                <th className="border-bottom">Shipment Type</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientSalesQuotations && clientSalesQuotations.map((t, i) => <TableRow key={`sales-quotation-${i}`} {...t} />)}
                            {clientSalesQuotations.length === 0 && (
                                <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {!loading && (
                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
            )}
        </>
        
    )
}

export default ClientSalesQuotation;