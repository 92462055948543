import React from 'react';

export const SkeletonWrapper = ({ children }) => {
  return (
    <div
      style={{
        lineHeight: 1,
        height: '10px !important',
        zIndex: 1,
      }}
    >
      {children}
    </div>
  );
};
