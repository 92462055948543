import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

// Use composeWithDevTools only in development mode
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools
    : (f) => f; // In production, use a no-op function for enhancers

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
