import { ADMIN_PORTAL_PAGE, HIDE_ADMIN_PORTAL_LOADER, SHOW_ADMIN_PORTAL_LOADER } from '../actions/types';

const initialState = {
  portalPage: '',
  showAdminLoader: false,
};

const blanketReducer = (appBlanket = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_PORTAL_PAGE:
      return { ...appBlanket, portalPage: payload };
    case SHOW_ADMIN_PORTAL_LOADER:
      return { ...appBlanket, showAdminLoader: true };
    case HIDE_ADMIN_PORTAL_LOADER:
      return { ...appBlanket, showAdminLoader: false };
    default:
      return appBlanket;
  }
};

export default blanketReducer;
