import React, { useState } from "react";
import { Card, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { faBan, faCheckCircle, faCheckDouble, faEye, faFileDownload, faFilePdf, faPaperPlane, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SalesQuotation from "./salesquotation";
import SalesQuotationPreview from "./salesquotationpreview";
import { confirmQuotationDocumentDialog, showOk, confirmArchiveDialogEmptyCallback } from "../components/Alerts";
import { CLIENT_NO_RESPONSE, FINAL_QUOTATION, PENDING_CLIENT_SIGNATURE_QUOTATION, PENDING_GENERAL_MANAGER_APPROVAL, PROFORMA_INVOICE, QUOTATION_LIST_AND_HISTORY_CANCEL_QUOTATION, QUOTATION_LIST_AND_HISTORY_DOWNLOAD_QUOTATION, QUOTATION_LIST_AND_HISTORY_MOVE_TO_NO_RESPONSE, QUOTATION_LIST_AND_HISTORY_MOVE_TO_SIGNED_QUOTATION, QUOTATION_LIST_AND_HISTORY_PREVIEW_QUOTATION, QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT, SIGNED_QUOTATION } from "../utils/constants";
import quotationApi from '../services/quotation-service';
import { generatePdf } from "../pdfGenerator";
import { checkQuotationForDownload, checkQuotationForPreview, checkQuotationShowCancel, checkQuotationStatusChange } from "../utils/helpers";
import SalesQuotationCancelReason from "./salesquotationcancelreason";
import { hasPermission } from "../auth/UserPermissionTypes";

const SalesQuotationHolder = (props) => {
    
    const {
        quotationData, 
        clientCollection,
        productCollection,
        clientWithPaymentTermsCollection,
        viewMode,
        hideEvent,
        refreshList,
        productCategoryCollection,
        refreshProductList,
        handleProductDetailFromDatatable,
        donwloadPdf,
        sendQuotationDocument
    } = props

    const { changeQuotationStatus } = quotationApi;

    const [documentViewState, setDocumentViewState] = useState('Quotation Details');

    const [showCancelReason, setShowCancelReason] = useState(false);
    
    const quotationStatusValue = (status) => {
        if(status === PROFORMA_INVOICE) return PENDING_GENERAL_MANAGER_APPROVAL;
        else if(status === PENDING_GENERAL_MANAGER_APPROVAL) return FINAL_QUOTATION;
        else if(status === FINAL_QUOTATION) return PENDING_CLIENT_SIGNATURE_QUOTATION;
        else if(status === PENDING_CLIENT_SIGNATURE_QUOTATION) return SIGNED_QUOTATION;
    }

    const handleApproveQuotation = async (elementId) => {
        let text = 'Do you want to approve this quotation?';
        let confirmBtnText = 'Yes, approve it!';
        let callbackTitle = 'Quotation approved!';
        let callbackText = 'Quotation document approved!';
    
        var statusConfirm = await confirmQuotationDocumentDialog(text, confirmBtnText, callbackTitle, callbackText);

        if (statusConfirm) {
            let formData = new FormData();

            if(quotationData.status === FINAL_QUOTATION) {
                const pdfContent = document.getElementById(elementId).innerHTML;
                const pdfBlob = await generatePdf(pdfContent, 'quotation-statement.pdf');
                formData.append('pdfData', pdfBlob, 'quotation_statement.pdf');
            }

            formData.append('quotationId', quotationData._id);
            formData.append('status', quotationStatusValue(quotationData.status));
    
            changeQuotationStatus(formData)
                .then(() => {
                    showOk('Quotation document approved');
                    refreshList();
                    hideEvent();
                })
                .catch((e) => {
                    console.log(e)
                });
        }
    };

    const handleCloseQuotation = async (status) => {

        let text = 'Do you want to update the quotation status?';
        let confirmBtnText = 'Yes, update it!';
        let callbackTitle = 'Quotation updated!';
        let callbackText = 'Quotation status updated!';
    
        var statusConfirm = await confirmQuotationDocumentDialog(text, confirmBtnText, callbackTitle, callbackText);

        if (statusConfirm) {
            let formData = new FormData();

            formData.append('quotationId', quotationData._id);
            formData.append('status', status);

            changeQuotationStatus(formData)
                .then(() => {
                    showOk('Quotation status updated.');
                    refreshList();
                    hideEvent();
                })
                .catch((e) => {
                    console.log(e)
                });
        }
    };
    

    const handleCancelQuotationDocument = async () => {
        let text = 'Do you want to cancel this quotation?';
        let confirmBtnText = 'Yes, cancel it!';
    
        var statusConfirm = await confirmArchiveDialogEmptyCallback (text, confirmBtnText);
        if (statusConfirm) {
            setShowCancelReason(true);
        }
    };
    
    return (
        <>
            {showCancelReason && (
                <SalesQuotationCancelReason quotationId={quotationData._id} cancelEvent={setShowCancelReason} refreshList={refreshList} />
            )}

            <Card border="light" className="shadow-sm document-details">
                <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">{documentViewState}</h5>
                    <ButtonGroup>
                        <Button className={documentViewState === 'Quotation Details' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Quotation Details')}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Show Details
                        </Button>
                        {checkQuotationStatusChange(quotationData.status) && (
                            <Button variant="outline-primary" size="sm" 
                                onClick={() => handleApproveQuotation(`element-to-print_${quotationData._id}`)}
                            >
                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Approve
                            </Button>
                        )}
                        {checkQuotationForPreview(quotationData.status) && hasPermission(QUOTATION_LIST_AND_HISTORY_PREVIEW_QUOTATION) && (
                            <Button className={documentViewState === 'Quotation Preview' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Quotation Preview')}>
                                <FontAwesomeIcon icon={faFilePdf} className="me-2" /> Preview
                            </Button>
                        )}
                        {checkQuotationForDownload(quotationData.status) && hasPermission(QUOTATION_LIST_AND_HISTORY_DOWNLOAD_QUOTATION) && (
                            <Button variant="outline-primary" size="sm" onClick={() => donwloadPdf(quotationData._id)}>
                                <FontAwesomeIcon icon={faFileDownload} className="me-2" /> Download
                            </Button>
                        )}
                        {quotationData.status === PENDING_CLIENT_SIGNATURE_QUOTATION && (
                            <>
                                {hasPermission(QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => sendQuotationDocument(quotationData._id)}>
                                        <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send
                                    </Button>
                                )}
                                {hasPermission(QUOTATION_LIST_AND_HISTORY_MOVE_TO_SIGNED_QUOTATION) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => handleCloseQuotation(SIGNED_QUOTATION)}>
                                        <FontAwesomeIcon icon={faCheckDouble} className="me-2" /> Signed Quotation
                                    </Button>
                                )}
                                {hasPermission(QUOTATION_LIST_AND_HISTORY_MOVE_TO_NO_RESPONSE) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => handleCloseQuotation(CLIENT_NO_RESPONSE)}>
                                        <FontAwesomeIcon icon={faBan} className="me-2" /> No Response
                                    </Button>
                                )}
                            </>
                        )}
                        {quotationData.status === CLIENT_NO_RESPONSE && hasPermission(QUOTATION_LIST_AND_HISTORY_MOVE_TO_SIGNED_QUOTATION) && (
                            <Button variant="outline-primary" size="sm" onClick={() => handleCloseQuotation(SIGNED_QUOTATION)}>
                                <FontAwesomeIcon icon={faCheckDouble} className="me-2" /> Signed Quotation
                            </Button>
                        )}
                        {checkQuotationShowCancel(quotationData.status) && hasPermission(QUOTATION_LIST_AND_HISTORY_CANCEL_QUOTATION) && (
                            <Button variant="outline-primary" size="sm" onClick={() => handleCancelQuotationDocument()}>
                                <FontAwesomeIcon icon={faTimesCircle} className="me-2" /> Cancel Quotation
                            </Button>
                        )}
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {documentViewState === 'Quotation Details' && (
                        <SalesQuotation
                            hideEvent={hideEvent} 
                            quotationData={quotationData} 
                            clientCollection={clientCollection} 
                            productCollection={productCollection}
                            clientWithPaymentTermsCollection={clientWithPaymentTermsCollection}
                            viewMode={viewMode}
                            refreshList={refreshList}
                            productCategoryCollection={productCategoryCollection}
                            refreshProductList={refreshProductList}
                            handleProductDetailFromDatatable={handleProductDetailFromDatatable}
                        />
                    )}

                    {quotationData && documentViewState === 'Quotation Preview' && (
                        <SalesQuotationPreview quotationData={quotationData} productCollection={productCollection}/>
                    )}
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" className="ms-auto pull-right" onClick={() => hideEvent()}>
                        Hide
                    </Button>
                </Card.Footer>
                <div className='element-to-print' id={`element-to-print_${quotationData._id}`}>
                    <SalesQuotationPreview quotationData={quotationData} productCollection={productCollection} />
                </div>
            </Card>
        </>
    )
}

export default SalesQuotationHolder;

