import React from "react";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from 'moment-timezone';
import { formatCurrency } from "../utils/formatter";

const TransactionsPerLoadingPreview = (props) => {
    const { startDate, endDate, reportList } = props;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    return (
        <Card className="bg-white shadow-sm mb-4" style={{ width: '100%' }}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Transactions Per Loading or Container Number Report</h1>
                    <div className="left">
                        <p>Start Date: <b>{formattedStartDate}</b></p>
                        <p>End Date: <b>{formattedEndDate}</b></p>
                    </div>
                </div>
                <Table hover className="billing-table-list align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">Container Number</th>
                            <th className="border-bottom">Client Name</th>
                            <th className="border-bottom">Sum of CBM</th>
                            <th className="border-bottom">Gross Amount</th>
                            <th className="border-bottom">Freight Amount</th>
                            <th className="border-bottom">Total Billable Amount</th>
                            <th className="border-bottom">Applied Rate</th>
                            <th className="border-bottom">Created Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="fw-normal">{item.containerNumber}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{item.clientName}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(item.sumOfCBM)}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(item.grossAmount)}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(item.totalFreightAmount)}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(item.totalBillableAmount)}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(item.appliedRate)}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{moment(item.createdDate).format('YYYY-MM-DD')}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default TransactionsPerLoadingPreview;
