import { PAYMENT_TERM_COUNT, PAYMENT_TERM_TOTAL_PAGES } from '../actions/types';

const initialState = {
  count: 0,
  totalPages: 0,
};

const paymentTermReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_TERM_COUNT:
      return { ...state, count: payload };
    case PAYMENT_TERM_TOTAL_PAGES:
      return { ...state, totalPages: payload };
    default:
      return state;
  }
};

export default paymentTermReducer;
