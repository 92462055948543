import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { showOk, showError } from '../components/Alerts';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clientApi from '../services/client-service';
import rateCategoryApi from '../services/rate-category-service';

const ClientAddSpecialRate = (props) => {
  const { 
    clientRateData, 
    clientId,
    refreshList,
    cancelEdit
  } = props;

  const { getRateCategories } = rateCategoryApi;
  const { createSpecialRate, updateSpecialRate } = clientApi;
  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [loading, setLoading] = useState(false);
  const [rateCategories, setRateCategories] = useState([]);
  const [specialTypeRate, setSpecialTypeRate] = useState(clientRateData ? (clientRateData.fixRate > 0 ? 'FixRate' : 'BracketRate') : 'FixRate');

  const fixRateSchema = Yup.object().shape({
    rateCategoryId: Yup.string().required('Rate category is required'),
    fixRate: Yup.number().typeError('Fix rate must be a number').min(0, 'Fix rate must be non-negative').required('Fix rate is required'),
  });

  const bracketRateSchema = Yup.object().shape({
    rateCategoryId: Yup.string().required('Rate category is required'),
    startQuantity: Yup.number().typeError('Start quantity must be a number').min(0, 'Start quantity must be non-negative').required('Start quantity is required'),
    endQuantity: Yup.number()
      .typeError('End quantity must be a number')
      .min(0, 'End quantity must be non-negative')
      .required('End quantity is required')
      .test('is-greater', 'End quantity must be greater than or equal to start quantity', function (value) {
        const { startQuantity } = this.parent;
        return value >= startQuantity;
      }),
    bracketRate: Yup.number().typeError('Bracket rate must be a number').min(0, 'Bracket rate must be non-negative').required('Bracket rate is required'),
  });



  const getSchema = useCallback(() => {
    return specialTypeRate === 'FixRate' ? fixRateSchema : bracketRateSchema
  }, [specialTypeRate]);

  const formOptions = {
    resolver: yupResolver(getSchema()),
    mode: 'all',
    defaultValues: {
        rateCategoryId: clientRateData?.rateCategory._id || '',
        fixRate: clientRateData?.fixRate || 0,
        bracketRate: clientRateData?.bracketRate || 0,
        startQuantity: clientRateData?.startQuantity || 0,
        endQuantity: clientRateData?.endQuantity || 0,
    }
  };

  const { register, handleSubmit, getValues, formState, setValue, reset } = useForm(formOptions);

  const { errors } = formState;

  const handleSubmitSpecialRate = () => {
    setLoading(true);

    let formData = new FormData();

    var formValues = getValues();

    for (const key in formValues) {
        if (key === 'field') {
            let formValue = formValues[key][1];
            formData.append(key, formValue);
        } else {
            let formValue = formValues[key];
            formData.append(key, formValue);
        }
    }

    formData.append('clientId', clientId);

    if(clientRateData){
        formData.append('clientSpecialRateId', clientRateData._id);
        updateSpecialRate(formData)
            .then(() => {
                showOk('Client special rate updated');
                reset();
                refreshList(clientId);
                cancelEdit();
                setLoading(false);
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
    }
    else
    {
        createSpecialRate(formData)
            .then(() => {
                showOk('Client special rate saved');
                reset();
                refreshList(clientId);
                cancelEdit();
                setLoading(false);
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
    }
  };

  const handleChangeSpecialRateType = (type) => {
    setSpecialTypeRate(type);
    if(type === 'FixRate'){
        setValue('startQuantity', 0);
        setValue('endQuantity', 0);
        setValue('bracketRate', 0);
    }
    else
    {
        setValue('fixRate', 0);
    }
  };

  const handleRateChange = (e, rateProp) => {
    const value = e.target.value;
    if (value === '') {
      setValue(rateProp, null);
    } else if (!isNaN(value) && parseFloat(value) >= 0) {
      setValue(rateProp, parseFloat(value));
    } else {
      setValue(rateProp, '');
    }
  };

  useEffect(() => {
    getRateCategories().then(data => {
      setRateCategories(data);
    })
    .catch(e => console.log(e));
  }, [getRateCategories]);

  useEffect(() => {
    reset({}, {
      resolver: yupResolver(getSchema())
    });
  }, [specialTypeRate, reset, getSchema]);

  return (
    <Modal as={Dialog} backdropClassName="" centered show={true}>
      <Form onSubmit={handleSubmit(handleSubmitSpecialRate)}>
        <Header>
          <Title className="h6">Add - Client special rate</Title>
          <Button variant="close" aria-label="Close" onClick={() => cancelEdit()} />
        </Header>
        <Body>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Group id="special-rate-specific-rate">
                <Form.Check
                  defaultChecked={clientRateData ? clientRateData.fixRate > 0 : false} 
                  type="radio"
                  label="Fix Rate"
                  name="client-special-rate"
                  id="client-specific-rate-option"
                  htmlFor="client-specific-rate-option"
                  onChange={() => handleChangeSpecialRateType('FixRate')}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-4">
              <Form.Group id="special-rate-bracket-rate">
                <Form.Check
                  defaultChecked={clientRateData ? clientRateData.bracketRate > 0 : false} 
                  type="radio"
                  label="Bracket Rate"
                  name="client-special-rate"
                  id="special-rate-bracket-rate-option"
                  htmlFor="special-rate-bracket-rate-option"
                  onChange={() => handleChangeSpecialRateType('BracketRate')}
                />
              </Form.Group>
            </Col>
          </Row>
          {rateCategories.length > 0 && (
            <Row>
              <Col md={12} className="mb-2">
                <Form.Group id="rate-category">
                  <Form.Label className="light-font">Rate Category</Form.Label>
                  <Form.Select {...register('rateCategoryId')}>
                    <option value="">Select rate category</option>
                    {rateCategories.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name} - {item.categoryUnit}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.rateCategoryId?.message && (
                    <small className="invalid-fields">{errors.rateCategoryId.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
          {specialTypeRate === 'FixRate' ? (
            <Row>
              <Col md={12} className="mb-2">
                <Form.Group id="rate-fix-rate">
                  <Form.Label className="light-font">Fix Rate</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="fixRate"
                    placeholder="0"
                    {...register('fixRate', { onChange: (e) => handleRateChange(e, 'fixRate') })}
                    min="0"
                  />
                  {errors.fixRate?.message && (
                    <small className="invalid-fields">{errors.fixRate.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col md={12} className="mb-2">
                  <Form.Group id="rate-bracket-start-quantity">
                    <Form.Label className="light-font">Start Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.01"
                      name="startQuantity"
                      placeholder="0"
                      {...register('startQuantity', { onChange: (e) => handleRateChange(e, 'startQuantity') })}
                      min="0"
                    />
                    {errors.startQuantity?.message && (
                      <small className="invalid-fields">{errors.startQuantity.message}</small>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-2">
                  <Form.Group id="rate-bracket-end-quantity">
                    <Form.Label className="light-font">End Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.01"
                      name="endQuantity"
                      placeholder="0"
                      {...register('endQuantity', { onChange: (e) => handleRateChange(e, 'endQuantity') })}
                      min="0"
                    />
                    {errors.endQuantity?.message && (
                      <small className="invalid-fields">{errors.endQuantity.message}</small>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-2">
                  <Form.Group id="rate-bracket-rate">
                    <Form.Label className="light-font">Rate</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.01"
                      name="bracketRate"
                      placeholder="00.00"
                      {...register('bracketRate', { onChange: (e) => handleRateChange(e, 'bracketRate') })}
                      min="0"
                    />
                    {errors.bracketRate?.message && (
                      <small className="invalid-fields">{errors.bracketRate.message}</small>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Body>
        <Footer>
          <Button type="submit" disabled={loading} variant="secondary">
            Save
          </Button>
          <Button variant="primary" className="ms-auto" onClick={() => cancelEdit()}>
            Close
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default ClientAddSpecialRate;
