import { RATE_BRACKET_COUNT, RATE_BRACKET_TOTAL_PAGES } from '../actions/types';

const initialState = {
  count: 0,
  totalPages: 0,
};

const rateBracketReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RATE_BRACKET_COUNT:
      return { ...state, count: payload };
    case RATE_BRACKET_TOTAL_PAGES:
      return { ...state, totalPages: payload };
    default:
      return state;
  }
};

export default rateBracketReducer;
