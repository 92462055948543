import React, { useState } from "react";
import { Card, Form, Row, Col, Button, Alert } from "react-bootstrap";
import { formatCurrency } from "../utils/formatter";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showOk, showError } from '../components/Alerts';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import billingApi from '../services/billing-service';
import './styles.scss';

const BillingItemDetailForm = (props) => {

    const { data, isEditMode, cancelEdit, refreshList } = props;

    const { updateItemDetail } = billingApi

    const formSchema = Yup.object().shape({
        itemDescription: Yup.string().required('Item description is required'),
        numberOfPackage: Yup.string().required('No. of package is required'),
        cbm: Yup.string().required('CBM is required'),
        grossWeight: Yup.string().required('Gross weight is required'),
        sensitiveCharge: Yup.string().required('Sensitive charge is required'),
        forklift: Yup.string().required('Forklift is required'),
        inland: Yup.string().required('Inland is required'),
        cbmOceanFreight: Yup.string().required('CBM Ocean Freight is required'),
        additionalCharge: Yup.string().required('Additional charge is required'),
        forkliftCharge: Yup.string().required('Additional charge is required'),
        inlandTransportation: Yup.string().required('Inland transportation is required'),
        customsClearance: Yup.string().required('Customs clearance is required'),
        oc4d: Yup.string().required('OC4D is required')
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues:{
            itemDescription: data?.itemDescription,
            numberOfPackage: data?.numberOfPackage,
            cbm: data?.cbm,
            grossWeight: data?.grossWeight,
            sensitiveCharge: data?.sensitiveCharge,
            itemSensitiveRate: data?.itemSensitiveRate,
            manualSensitiveAmount: data?.manualSensitiveAmount || 0,
            manualSensitiveRemarks: data?.manualSensitiveRemarks || '',
            forklift: data?.forklift,
            inland: data?.inland,
            cbmOceanFreight: data?.cbmOceanFreight,
            additionalCharge: data?.additionalCharge,
            forkliftCharge: data?.forkliftCharge,
            inlandTransportation: data?.inlandTransportation,
            customsClearance: data?.customsClearance,
            oc4d: data?.oc4d,
            totalAmount: data?.totalAmount
        }
    };

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, getValues, formState, reset, setValue, setError, clearErrors } = useForm(formOptions);

    const { errors } = formState;

    const handleNumberChange = (e, control) => {
        const value = e.target.value;
        if(control === 'manualSensitiveAmount'){
            clearErrors('')
        }
        if (value === '') {
            setValue(control, null);
        } else if (!isNaN(value) && parseFloat(value) >= 0) {
            setValue(control, parseFloat(value));
        } else {
            setValue(control, '');
        }
    };

    const handleSaveItemDetail = () => {
        setLoading(true);

        const manualSensitiveRate = getValues('manualSensitiveAmount');
        const manualSensitiveRemarks = getValues('manualSensitiveRemarks');

        if(parseFloat(manualSensitiveRate) > 0 && manualSensitiveRemarks.trim() === ''){
            setError('manualSensitiveRemarks', {
                type: 'manual',
                message: 'Manual sensitive remarks is required',
            });
            setLoading(false);
            return;
        }

        let formData = new FormData();

        var formValues = getValues();

        for (const key in formValues) {
            if (key === 'field') {
                let formValue = formValues[key][1];
                formData.append(key, formValue);
            } else {
                let formValue = formValues[key];
                formData.append(key, formValue);
            }
        }

        formData.append('itemDetailId', data._id);
        formData.append('generatedBillingId', data.generatedBillingData);

        updateItemDetail(formData)
            .then(() => {
                showOk('Billing detail updated');
                reset();
                refreshList();
                cancelEdit();
                setLoading(false);
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
    }

    return (
        <Card className="bg-white shadow-sm mb-4">
            <Card.Body>
                <Row className="border-bottom border-light ">
                    <Col md={6}>
                        <h3 className="mt-3 pb-md-3">Item Details - Total Amount ({formatCurrency(parseFloat(data?.totalAmount)) || 0.00})</h3>
                    </Col>
                    {isEditMode && (
                        <Col md={6}>
                            <Alert variant="warning">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <FontAwesomeIcon icon={faInfoCircle} className="me-1" />
                                        All fields are required. If no value is available, please set the value to 0.
                                    </div>
                                </div>
                            </Alert>
                        </Col>
                    )}
                </Row>
                <Form onSubmit={handleSubmit(handleSaveItemDetail)}>
                    <Row className='align-items-start'>
                        <Col md={3} className="mb-3">
                            <Form.Group id="itemDescription">
                                <Form.Label className="light-font">Description</Form.Label>
                                {isEditMode ? (
                                    <Form.Control 
                                        type="text" 
                                        name="itemDescription" 
                                        placeholder="Item Description"
                                    {...register('itemDescription')} />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.itemDescription || '--'}</p>
                                )}
                                {errors.itemDescription?.message && <small className="invalid-fields">{errors.itemDescription.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="numberOfPackage">
                                <Form.Label className="light-font">No. of Package</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="numberOfPackage"
                                        {...register('numberOfPackage', { onChange: (e) => handleNumberChange(e, 'numberOfPackage') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.numberOfPackage || 0}</p>
                                )}
                                {errors.numberOfPackage?.message && <small className="invalid-fields">{errors.numberOfPackage.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="CBM">
                                <Form.Label className="light-font">CBM</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="cbm"
                                        {...register('cbm', { onChange: (e) => handleNumberChange(e, 'cbm') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.cbm || 0}</p>
                                )}
                                {errors.cbm?.message && <small className="invalid-fields">{errors.cbm.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="grossWeight">
                                <Form.Label className="light-font">Gross Weight</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="grossWeight"
                                        {...register('grossWeight', { onChange: (e) => handleNumberChange(e, 'grossWeight') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.grossWeight || 0}</p>
                                )}
                                {errors.grossWeight?.message && <small className="invalid-fields">{errors.grossWeight.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='align-items-start'>
                        <Col md={3} className="mb-3">
                            <Form.Group id="sensitiveCharge">
                                <Form.Label className="light-font">Sensitive</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="sensitiveCharge"
                                        {...register('sensitiveCharge', { onChange: (e) => handleNumberChange(e, 'sensitiveCharge') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.sensitiveCharge || 0))}</p>
                                )}
                                {errors.sensitiveCharge?.message && <small className="invalid-fields">{errors.sensitiveCharge.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="itemSensitiveRate">
                                <Form.Label className="light-font">Sensitive Rate</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="itemSensitiveRate"
                                        {...register('itemSensitiveRate')}
                                        readOnly={true}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.itemSensitiveRate || 0))}</p>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="manualSensitiveAmount">
                                <Form.Label className="light-font">Manual Sensitive Amount</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="manualSensitiveAmount"
                                        {...register('manualSensitiveAmount', { onChange: (e) => handleNumberChange(e, 'manualSensitiveAmount') })}
                                        min="0"
                                        step="0.00001"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.manualSensitiveAmount || 0))}</p>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="manualSensitiveRemarks">
                                <Form.Label className="light-font">Manual Sensitive Remarks</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="text"
                                        name="manualSensitiveRemarks"
                                        {...register('manualSensitiveRemarks')}
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.manualSensitiveRemarks || '-'}</p>
                                )}
                                {errors.manualSensitiveRemarks?.message && <small className="invalid-fields">{errors.manualSensitiveRemarks.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-3">
                            <Form.Group id="forklift">
                                <Form.Label className="light-font">Forklift</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="forklift"
                                        placeholder=""
                                        {...register('forklift', { onChange: (e) => handleNumberChange(e, 'forklift') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.forklift || 0}</p>
                                )}
                                {errors.forklift?.message && <small className="invalid-fields">{errors.forklift.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="forkliftCharge">
                                <Form.Label className="light-font">Forklift Charge</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="forkliftCharge"
                                        {...register('forkliftCharge', { onChange: (e) => handleNumberChange(e, 'forkliftCharge') })}
                                        min="0"
                                        step="0.00001"
                                        readOnly={true}
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.forkliftCharge || 0))}</p>
                                )}
                                {errors.forkliftCharge?.message && <small className="invalid-fields">{errors.forkliftCharge.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="inland">
                                <Form.Label className="light-font">Inland</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="inland"
                                        {...register('inland', { onChange: (e) => handleNumberChange(e, 'inland') })}
                                        min="0"
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{data?.inland || 0}</p>
                                )}
                                {errors.inland?.message && <small className="invalid-fields">{errors.inland.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="inlandTransportation">
                                <Form.Label className="light-font">Inland Transportation</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="inlandTransportation"
                                        {...register('inlandTransportation', { onChange: (e) => handleNumberChange(e, 'inlandTransportation') })}
                                        min="0"
                                        step="0.00001"
                                        readOnly={true}
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.inlandTransportation || 0))}</p>
                                )}
                                {errors.inlandTransportation?.message && <small className="invalid-fields">{errors.inlandTransportation.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='align-items-start'>
                        <Col md={3} className="mb-3">
                            <Form.Group id="additionalCharge">
                                <Form.Label className="light-font">Additional Charge</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="additionalCharge"
                                        {...register('additionalCharge', { onChange: (e) => handleNumberChange(e, 'additionalCharge') })}
                                        min="0"
                                        readOnly={true}
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.additionalCharge || 0))}</p>
                                )}
                                {errors.additionalCharge?.message && <small className="invalid-fields">{errors.additionalCharge.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="cbmOceanFreight">
                                <Form.Label className="light-font">CBM Ocean Freight</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="cbmOceanFreight"
                                        {...register('cbmOceanFreight', { onChange: (e) => handleNumberChange(e, 'cbmOceanFreight') })}
                                        min="0"
                                        readOnly={true}
                                        step="0.01"
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.cbmOceanFreight || 0))}</p>
                                )}
                                {errors.cbmOceanFreight?.message && <small className="invalid-fields">{errors.cbmOceanFreight.message}</small>}
                            </Form.Group>
                        </Col>
                        
                        <Col md={3} className="mb-3">
                            <Form.Group id="customsClearance">
                                <Form.Label className="light-font">Customs Clearance</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="customsClearance"
                                        {...register('customsClearance', { onChange: (e) => handleNumberChange(e, 'customsClearance') })}
                                        min="0"
                                        step="0.01"
                                        readOnly={true}
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.customsClearance || 0))}</p>
                                )}
                                {errors.customsClearance?.message && <small className="invalid-fields">{errors.customsClearance.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="oc4d">
                                <Form.Label className="light-font">OC4D</Form.Label>
                                {isEditMode ? (
                                    <Form.Control
                                        type="number"
                                        name="oc4d"
                                        {...register('oc4d', { onChange: (e) => handleNumberChange(e, 'oc4d') })}
                                        min="0"
                                        step="0.01"
                                        readOnly={true}
                                    />
                                ) : (
                                    <p className="mb-0 bold-700">{formatCurrency(parseFloat(data?.oc4d || 0))}</p>
                                )}
                                {errors.oc4d?.message && <small className="invalid-fields">{errors.oc4d.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {isEditMode && (
                        <Button type="submit" variant="secondary" className="ms-auto me-1" disabled={loading}>
                            Save
                        </Button>
                    )}
                    <Button variant="primary" className="ms-auto" onClick={() => cancelEdit()} disabled={loading}>
                        Cancel
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default BillingItemDetailForm;