import React, { useEffect, useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faMobile, faUnlockAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert, Image } from '@themesberg/react-bootstrap';
import SeaRoadBrand from '../assets/img/searoadtraidinglogo.png';
import { useForm } from 'react-hook-form';
import authApi from '../services/auth-api-service';

export const ActivateAccount = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [validActivationKey, setValidActivationKey] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [accountActivated, setAccountActivated] = useState(false);
  const { validateActivation, activateAccount, confirmActivateAccount } = authApi;
  const formSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string()
      .matches(/^(\+639|\b0)(\d{10})$/, 'Invalid phone number')
      .required(),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must have at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Minimum eight characters, at least one letter, one number and one special character',
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, getValues, setValue, formState, setError } = useForm(formOptions);
  const { errors } = formState;

  const onSubmitOtp = (e) => {
    e.preventDefault();

    const otp = getValues('yourOTP');

    if (!otp) {
      setError('yourOTP', {
        type: 'manual',
        message: 'OTP is required. Please check your email.',
      });

      return;
    }

    setLoading(true);

    setErrorMessage('');

    let accountFormData = new FormData();

    accountFormData.append('otp', otp);
    accountFormData.append('token', getTokenFromURL());

    confirmActivateAccount(accountFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setAccountActivated(true);
          setLoading(false);
        } else {
          setErrorMessage('The provided OTP is invalid.');
          setValue('yourOTP', '');
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage('The provided OTP is invalid.');
        setValue('yourOTP', '');
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    setErrorMessage('');

    const phoneNumber = getValues('phoneNumber');
    const password = getValues('password');
    const activationKey = getTokenFromURL();

    let accountFormData = new FormData();

    accountFormData.append('phoneNumber', phoneNumber);
    accountFormData.append('password', password);
    accountFormData.append('token', activationKey);

    activateAccount(accountFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setVerifyOtp(true);
          setLoading(false);
        } else {
          setValidActivationKey(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage(e.data.error);
        setLoading(false);
      });
  };

  const getTokenFromURL = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('activationKey');
  }, [location]);

  useEffect(() => {
    const activationKey = getTokenFromURL();

    if (activationKey) {
      validateActivation(activationKey)
        .then((data) => {
          const isValid = data.isValidKey;
          const firstname = data.firstName;
          const lastname = data.lastName;
          setValue('firstname', firstname);
          setValue('lastname', lastname);
          setValidActivationKey(isValid);
          setLoading(false);
        })
        .catch((ex) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [getTokenFromURL, validateActivation, setValue, setValidActivationKey, setLoading]);

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-3 mt-md-0">
                  <Image src={SeaRoadBrand} className="navbar-brand-light" />
                </div>
                {loading ? (
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Validating activation invite. Please wait..</h3>
                  </div>
                ) : (
                  <>
                    {accountActivated && (
                      <p className="text-center">
                        <Card.Link as={Link} to={'/'} className="text-gray-700">
                          <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Go to login page
                        </Card.Link>
                      </p>
                    )}
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Activate your account</h3>
                    </div>
                    {accountActivated ? (
                      <Alert variant="success">Your account is now activated. Login now to get started.</Alert>
                    ) : (
                      <>
                        {!accountActivated && !validActivationKey ? (
                          <Alert variant="danger">
                            Oops, looks like there's an issue with your activation invite. Just get in touch with Sea Road Trading Inc. administrator and they'll sort it out for you.
                          </Alert>
                        ) : (
                          <>
                            {errorMessage !== '' && <Alert variant="danger">{errorMessage}</Alert>}
                            {verifyOtp ? (
                              <Form className="mt-4" onSubmit={onSubmitOtp}>
                                <Form.Group id="yourOTP" className="mb-4">
                                  <Form.Label>Please enter OTP sent in your email</Form.Label>
                                  <InputGroup>
                                    <Form.Control autoFocus name="yourOTP" type="text" {...register('yourOTP')} />

                                    {errors.yourOTP?.message && (
                                      <small className="invalid-fields">{errors.yourOTP.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Button variant="primary" disabled={loading} type="submit" className="w-100">
                                  Submit
                                </Button>
                              </Form>
                            ) : (
                              <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group id="firstname" className="mb-4">
                                  <Form.Label>First Name</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUser} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      autoFocus
                                      name="firstname"
                                      type="text"
                                      readOnly={true}
                                      {...register('firstname')}
                                      placeholder="Your first name"
                                    />

                                    {errors.firstname?.message && (
                                      <small className="invalid-fields">{errors.firstname.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group id="lastname" className="mb-4">
                                  <Form.Label>Last Name</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUser} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      {...register('lastname')}
                                      readOnly={true}
                                      placeholder="Your last name"
                                    />

                                    {errors.lastname?.message && (
                                      <small className="invalid-fields">{errors.lastname.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group id="phoneNumber" className="mb-4">
                                  <Form.Label>Phone Number</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faMobile} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      name="phoneNumber"
                                      type="tel"
                                      {...register('phoneNumber')}
                                      placeholder="+639XXXXXXXXX"
                                    />

                                    {errors.phoneNumber?.message && (
                                      <small className="invalid-fields">{errors.phoneNumber.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group id="password" className="mb-4">
                                  <Form.Label>Your Password</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="password"
                                      name="password"
                                      {...register('password')}
                                      placeholder="Password"
                                    />

                                    {errors.password?.message && (
                                      <small className="invalid-fields">{errors.password.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group id="confirmPassword" className="mb-4">
                                  <Form.Label>Confirm Password</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="password"
                                      name="confirmpassword"
                                      {...register('confirmPassword')}
                                      placeholder="Confirm Password"
                                    />
                                    {errors.confirmPassword?.message && (
                                      <small className="invalid-fields">{errors.confirmPassword.message}</small>
                                    )}
                                  </InputGroup>
                                </Form.Group>
                                <Button variant="primary" disabled={loading} type="submit" className="w-100">
                                  Submit
                                </Button>
                              </Form>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
