import { requests } from './api-service';

const rateBracketApi = {
    createRateBracket: (rateBracket) => requests.post('ratebracket', rateBracket),
    updateRateBracket: (rateBracket) => requests.put('ratebracket', rateBracket),
    deleteRateBracket: (id) => requests.delete(`ratebracket/${id}`),
    getCount: () => requests.get('ratebracket/count'),
    list: (pageNumber) => requests.get('ratebrackets', setRateBracketListParams(pageNumber)),
    setRateBracketStatus: (data) => requests.put('ratebracket/setstatus', data),
};

export const setRateBracketListParams = (pageNumber = 1) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    return params;
};

export default rateBracketApi;