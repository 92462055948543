import React, { useState, useEffect } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Multiselect from 'multiselect-react-dropdown';
import userApi from '../services/user-service';
import { confirmArchiveDialog, showError, showOk } from '../components/Alerts';

const UserSalesAgent = (props) => {
  const { 
    userId,
    provinces,
    cancelEvent,
    refreshList,
    assignedProvinces,
    isActiveSalesAgent,
    viewOnly
  } = props;

  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [loading, setLoading] = useState(false);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const { setUserAsSalesAgent, updateSalesAgentStatus } = userApi;

  const archiveClientSchema = Yup.object().shape({
    provinces: Yup.array()
      .min(1, 'At least one province is required')
      .required('Provinces are required'),
  });

  const formOptions = {
    resolver: yupResolver(archiveClientSchema),
    mode: 'all',
  };

  const { handleSubmit, getValues, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;

  // Initialize selectedProvinces with assignedProvinces when the component mounts
  useEffect(() => {
    setSelectedProvinces(assignedProvinces);
    setValue('provinces', assignedProvinces);
  }, [assignedProvinces, setValue]);

  // Handle selection of provinces
  const handleSelect = (selectedList) => {
    setSelectedProvinces(selectedList);
    setValue('provinces', selectedList);
  };

  const handleRemove = (selectedList) => {
    setSelectedProvinces(selectedList);
    setValue('provinces', selectedList);
  };

  const handleSaveUserAsAgent = () => {
    setLoading(true);

    const selectedProvincesData = getValues('provinces');
    const provinceIds = selectedProvincesData.map(province => province._id);

    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('provinces', provinceIds);

    setUserAsSalesAgent(formData)
        .then(() => {
            showOk('Sales agent saved');
            refreshList();
            reset();
            cancelEvent();
            setLoading(false);
        })
        .catch((e) => {
            showError(e?.data?.error);
            setLoading(false);
        });
  };

    const handleUpdateSalesAgentStatus = async (status) => {
        let eventMessage = "Disable";
        if(status) eventMessage = "Enable"
        
        let text = `Do you want to ${eventMessage} this sales agent?`;
        let confirmBtnText = `Yes, ${eventMessage} it!`;
        let callbackTitle = !status ? 'Disabled!': 'Enabled!';
        let callbackText = `Sales agent has been ${!status ? 'Disabled!': 'Enabled!'}`;
    
        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);

        if (statusConfirm) {
            let formData = new FormData();
            formData.append('userId', userId);
            formData.append('status', status);
            updateSalesAgentStatus(formData).then(() => {
                refreshList();
                cancelEvent();
            });
        }
    }

  return (
    <Modal as={Dialog} backdropClassName="" centered show={true}>
      <Form onSubmit={handleSubmit(handleSaveUserAsAgent)}>
        <Header>
            {viewOnly && (
                <Title className="h6">Agent province(s)</Title>
            )}
            {!viewOnly && (
                <Title className="h6">Please assign province(s) to agent</Title>
            )}
            <Button variant="close" aria-label="Close" onClick={() => cancelEvent()} />
        </Header>
        <Body>
            {!viewOnly && isActiveSalesAgent && (
                <Row>
                    <Col md={4}>
                        <Button type="button" size='sm' variant="danger" onClick={() => handleUpdateSalesAgentStatus(false)} className='mb-3'>Disable Agent</Button>
                    </Col>
                </Row>
            )}
            {!viewOnly && !isActiveSalesAgent && (
                <Row>
                    <Col md={4}>
                        <Button type="button" size='sm' variant="primary" onClick={() => handleUpdateSalesAgentStatus(true)} className='mb-3'>Enable Agent</Button>
                    </Col>
                </Row>
            )}
            <Row className='align-items-start'>
                <Col sm={12} className="mb-3">
                    {viewOnly && (
                        <div>
                            {selectedProvinces.map((province, index) => (
                                <Button type="button" size='sm' variant="outline-primary" className='me-1'>{province.name}</Button>
                            ))}
                        </div>
                    )}
                    {!viewOnly && (
                        <Form.Group id="sales-agent-provinces">
                            <Form.Label className="light-font">Select Province</Form.Label>
                            <Multiselect
                            options={provinces} // Options to display in the dropdown
                            selectedValues={selectedProvinces} // Preselected value to persist in dropdown
                            onSelect={handleSelect} // Function will trigger on select event
                            onRemove={handleRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            placeholder="Select Provinces"
                            style={{
                                chips: { background: "#007bff" },
                                searchBox: { border: errors.provinces ? '1px solid red' : '1px solid #ced4da' },
                            }}
                            />
                            {errors.provinces && <div className="text-danger">{errors.provinces.message}</div>}
                        </Form.Group>
                    )}
                    
                </Col>
            </Row>
        </Body>
        <Footer>
            {!viewOnly && (
                <>
                    <Button type="submit" disabled={loading} variant="secondary">
                        Submit
                    </Button>
                    <Button variant="primary" className="ms-auto" onClick={() => cancelEvent()}>
                        Cancel
                    </Button>
                </>
            )}
        </Footer>
      </Form>
    </Modal>
  );
};

export default UserSalesAgent;
