import { withRouter } from 'react-router-dom';
import { JWT_TOKEN } from '../utils/constants';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = ({ history }) => {
  const userJwt = JSON.parse(localStorage.getItem(JWT_TOKEN));

  if (userJwt) {
    const decodedJwt = parseJwt(userJwt.token);

    if (decodedJwt.exp * 1000 < Date.now()) {
      localStorage.removeItem(JWT_TOKEN);
      history.push('/');
    }
  }

  return null;
};

export default withRouter(AuthVerify);
