import React from 'react';
import { Card, Row, Col, Button, Form } from '@themesberg/react-bootstrap';
import { diffJson } from 'diff';
import './styles.scss';

function AuditDataHistory(props) {
    const { oldData, newData, description, hideLogs } = props;

    // Generate the diff using diffJson
    const diff = diffJson(oldData, newData);

    const formatDiff = (part) => {
        const style = {
            backgroundColor: part.added ? '#d4fcdc' : part.removed ? '#ffdce0' : 'transparent',
            textDecoration: part.removed ? 'line-through' : 'none',
        };
        return (
            <span style={style} key={Math.random()}>
                {part.value}
            </span>
        );
    };

    return (
        <>
            <Row className="align-items-start">
                <Col sm={6} className="mb-4">
                    <Form.Group id="itemDescription">
                        <Form.Label className="light-font bold-700">Description</Form.Label>
                        <p className="mb-2">{description || '--'}</p>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="align-items-start">
                <Col sm={6} className="mb-4">
                    <Card border="light" className="h-100">
                        <Card.Header className="border-bottom border-light d-flex justify-content-between role-header">
                            <h5 className="mb-0">Old Data</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="json-container">
                                <pre className="json-pre">{JSON.stringify(oldData, null, 2)}</pre>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} className="mb-1">
                    <Card border="light" className="h-100">
                        <Card.Header className="border-bottom border-light d-flex justify-content-between role-header">
                            <h5 className="mb-0">New Data</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="json-container">
                                <pre className="json-pre">{JSON.stringify(newData, null, 2)}</pre>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="align-items-start">
                <Col sm={12} className="mb-1">
                    <Card border="light" className="h-100">
                        <Card.Header className="border-bottom border-light d-flex justify-content-between role-header">
                            <h5 className="mb-0">Data Difference</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="json-container">
                                <pre className="json-pre">
                                    {diff.map((part) => formatDiff(part))}
                                </pre>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-between align-items-center mt-3">
                <Col md={3}>
                    <Button className='ms-1' variant="secondary" type="button" onClick={() => hideLogs(null)}>
                        Hide
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default AuditDataHistory;
