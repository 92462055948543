import { 
    PRODUCT_COUNT, 
    PRODUCT_TOTAL_PAGES
} from '../actions/types';

const initialState = {
    count: 0,
    totalPages: 0,
};

const productReducer = (productState = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case PRODUCT_COUNT:
        return { ...productState, count: payload };
      case PRODUCT_TOTAL_PAGES:
        return { ...productState, totalPages: payload };
      default:
        return productState;
    }
  };
  
  export default productReducer;
  