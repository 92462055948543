import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, InputGroup, Table, Row, Col } from '@themesberg/react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency } from '../utils/formatter';
import billingApi from '../services/billing-service';
import './styles.scss';
import { showOk } from '../components/Alerts';

const BillingMoveItem = (props) => {
    const { billingItem, pendingBillingList, cancelEvent, refreshList } = props;
    const { Header, Dialog, Title, Body, Footer } = Modal;
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBillingId, setSelectedBillingId] = useState(null);
    const [filteredBillingList, setFilteredBillingList] = useState([]);

    const { unlinkandmoveitem } = billingApi
    const onSearchChange = (e) => {
        const term = e.target.value || '';
        setSearchTerm(term);
        if (term === '') {
            setFilteredBillingList(filteredBillingList);
        } else {
            const filteredList = filteredBillingList.filter((item) =>
                item.clientData.displayName.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredBillingList(filteredList);
        }
    };

    const handleSaveBillingItem = async () => {
        setLoading(true);

        let formData = new FormData();

        formData.append('generatedBillingId', selectedBillingId);
        formData.append('generatedBillingItemId', billingItem._id);
        unlinkandmoveitem(formData)
            .then(() => {
                showOk('Billing unlinked and moved');
                refreshList();
                cancelEvent();
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                console.log(e)
            });
    }

    const onKeyPressSearch = (e) => {
        if (e.key === 'Enter') {
            onSearchGeneratedBilling();
        }
    };

    const onSearchGeneratedBilling = () => {
        const filteredList = filteredBillingList.filter((item) =>
            item.clientData.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBillingList(filteredList);
    };

    const handleCheckboxChange = (id) => {
        setSelectedBillingId(selectedBillingId === id ? null : id);
    };

    const TableRow = (document) => {
        const { displayName } = document.clientData;
        const { _id, sumOfCBM, sumOfSensitiveCharge, sumOfForklift, sumOfInland, sumOfGrossWeight, grossAmount, totalBillableAmount } = document;

        let rate = 0;

        if (document.appliedRateData) rate = document.appliedRateData.rate;

        if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
            const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
            rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
        }

        return (
            <tr key={_id}>
                <td>
                    <Form.Check
                        id={`billing-check-box-${_id}`}
                        checked={selectedBillingId === _id}
                        onChange={() => handleCheckboxChange(_id)}
                    />
                </td>
                <td>
                    <span className="fw-normal">{displayName || ''}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfCBM)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfForklift)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfInland)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfGrossWeight)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(rate)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(grossAmount)) || 0.0}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(totalBillableAmount)) || 0.0}</span>
                </td>
            </tr>
        );
    };

    useEffect(() => {
        const filteredList = pendingBillingList.filter(item => item._id !== billingItem.generatedBillingData);
        setFilteredBillingList(filteredList);
    }, [pendingBillingList, billingItem]);

    return (
        <Modal size="xl" as={Dialog} backdropClassName="" centered show={true}>
            <Header>
                <Title className="h6">Search pending billing to move the item</Title>
                <Button variant="close" aria-label="Close" onClick={() => cancelEvent()} />
            </Header>
            <Body>
                <Row className="align-items-start">
                    <Col sm={6} className="mb-3">
                        <Form.Group id="reason">
                            <Form.Label className="light-font">Item Id: </Form.Label>
                            <p className="mb-0 bold-700">{billingItem?._id || ''}</p>
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <Form.Group id="reason">
                            <Form.Label className="light-font">Item description: </Form.Label>
                            <p className="mb-0 bold-700">{billingItem?.itemDescription || ''}</p>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="align-items-start">
                    <Col sm={6} className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                onKeyPress={(e) => onKeyPressSearch(e)}
                                value={searchTerm}
                                onChange={onSearchChange}
                                placeholder="Search by display name"
                            />
                            <Button size="sm" onClick={() => onSearchGeneratedBilling()}>
                                Search
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="align-items-start">
                    <Col sm={12} className="table-container">
                        <Table hover className="billing-table-list align-items-center">
                            <thead>
                                <tr>
                                    <th className="border-bottom"></th>
                                    <th className="border-bottom">Display Name</th>
                                    <th className="border-bottom">CBM</th>
                                    <th className="border-bottom">Sensitive Charge</th>
                                    <th className="border-bottom">Forklift</th>
                                    <th className="border-bottom">Inland</th>
                                    <th className="border-bottom">Gross Wt</th>
                                    <th className="border-bottom">Rate</th>
                                    <th className="border-bottom">Freight Amount</th>
                                    <th className="border-bottom">Billable Amount</th>
                                </tr>
                            </thead>
                            <tbody>{filteredBillingList.map((t) => TableRow(t))}</tbody>
                        </Table>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <Button type="button" disabled={loading || !selectedBillingId} variant="secondary" onClick={() => handleSaveBillingItem()}>
                    Save
                </Button>
                <Button variant="primary" className="ms-auto" onClick={() => cancelEvent()}>
                    Cancel
                </Button>
            </Footer>
        </Modal>
    );
};

export default BillingMoveItem;
