import React, {  } from 'react';
import { Modal } from '@themesberg/react-bootstrap';
import './styles.scss';
import ProductDetail from '../products/productdetail';

const SalesProductDetail = (props) => {
    const { product, cancelEdit } = props;
    const { Dialog } = Modal;

    return (
        <Modal size="xl" as={Dialog} backdropClassName="" centered show={true}>
            <ProductDetail 
                product={product} 
                cancelEdit={cancelEdit}
            />
        </Modal>
    );
};

export default SalesProductDetail;
