import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert, Image } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import authApi from '../services/auth-api-service';
import SeaRoadBrand from '../assets/img/searoadtraidinglogo.png';

const ForgotPassword = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { sendResetPassword } = authApi;

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Invalid email address'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, getValues, setValue, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = () => {
    setLoading(true);
    setShowSuccess(false);
    setErrorMessage('');

    let resetFormData = new FormData();

    const email = getValues('email');

    resetFormData.append('email', email);

    sendResetPassword(resetFormData)
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
        setValue('email', '');
      })
      .catch((error) => {
        setErrorMessage(error.data.error);
        setLoading(false);
      });
  };

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to="/" className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-5 mt-md-0">
                  <Image src={SeaRoadBrand} className="navbar-brand-light" />
                </div>
                <h3>Forgot your password?</h3>
                <p className="mb-4">Please enter your email to reset your password</p>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {showSuccess && (
                  <Alert variant="success">
                    Password reset instructions sent! Please check your email inbox (and spam folder, just in case) for
                    further steps
                  </Alert>
                )}
                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="email"
                        name="email"
                        {...register('email')}
                        placeholder="example@searoad.com"
                      />
                    </InputGroup>

                    {errors.email?.message && <small className="invalid-fields">{errors.email.message}</small>}
                  </Form.Group>
                  <Button variant="primary" disabled={loading} type="submit" className="w-100">
                    Recover password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ForgotPassword;
