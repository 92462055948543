import React, { useEffect } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import { Nav, Tab, Row, Col } from '@themesberg/react-bootstrap';
import ApprovedBillings from './approvedbillings';
import CancelledBillings from './cancelledbillings';
import { hasPermission } from '../auth/UserPermissionTypes';
import { BILLING_TRANSACTIONS_AND_HISTORY_VIEW_APPROVED_BILLING, BILLING_TRANSACTIONS_AND_HISTORY_VIEW_CANCELLED_BILLING } from '../utils/constants';

function BillingList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPortalPage('Billing Transactions & History'));
  }, [dispatch]);

  return (
    <Tab.Container defaultActiveKey="approved">
        <Row>
          <Col lg={12}>
            <Nav className="nav-tabs">
              {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_VIEW_APPROVED_BILLING) && (
                <Nav.Item>
                  <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                    Approved
                  </Nav.Link>
                </Nav.Item>
              )}
              {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_VIEW_CANCELLED_BILLING) && (
                <Nav.Item>
                  <Nav.Link eventKey="cancelled" className="mb-sm-3 mb-md-0">
                    Cancelled
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>
          <Col lg={12}>
            <Tab.Content>
                {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_VIEW_APPROVED_BILLING) && (
                  <Tab.Pane eventKey="approved">
                    <ApprovedBillings />
                  </Tab.Pane>
                )}
                {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_VIEW_CANCELLED_BILLING) && (
                  <Tab.Pane eventKey="cancelled">
                    <CancelledBillings />
                  </Tab.Pane>
                )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
  );
}

export default BillingList;
