import { USER_COUNT, USER_TOTAL_PAGES } from './types';

export const setUserCount = (userCount) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(userCount / limit);

    dispatch({
      type: USER_COUNT,
      payload: userCount,
    });

    dispatch(setUserTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setUserTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: USER_TOTAL_PAGES,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
