
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from '@themesberg/react-bootstrap';

const AppFooter = () => {
    const currentYear = moment().get("year");
    return (
        <footer className="footer section py-5">
            <Row>
                <Col xs={12} lg={12} className="mb-4 mb-lg-0">
                    <p className="mb-0 text-center" style={{fontSize: '13px'}}>
                        Sea Road web application is Designed and Developed by
                        <Card.Link href="http://www.services-icypay.com" target="_blank" className="text-blue text-decoration-none fw-normal" style={{margin: '0 5px 0 5px'}}>
                        IcyPay Web Development Services
                        </Card.Link>
                        © {`${currentYear} `}
                    </p>
                </Col>
            </Row>
        </footer>
    );
}

export default AppFooter;