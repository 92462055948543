import { Nav, Card, Pagination } from '@themesberg/react-bootstrap';
import React, { useState, useCallback } from 'react';
import { useEffect } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const PortalPagination = (props) => {
  const { totalRecords, currentPage, pagingEvent } = props;

  const pageLimit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

  const pageNeighbours = Math.max(0, Math.min(1, 2));

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const [pageNumber, setPageNumber] = useState(currentPage);

  const [totalShowRecords, setTotalShowRecords] = useState(0);

  const renderPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;

    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = pageNumber - pageNeighbours;

      const rightBound = pageNumber + pageNeighbours;

      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;

      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = pageRange(startPage, endPage);

      const pagesCount = pages.length;

      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;

      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = pageRange(startPage - singleSpillOffset, startPage - 1);

        pages = [LEFT_PAGE, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = pageRange(endPage + 1, endPage + singleSpillOffset);

        pages = [...pages, ...extraPages, RIGHT_PAGE];
      } else if (leftSpill && rightSpill) {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }

      return [1, ...pages, totalPages];
    }

    var result = pageRange(1, totalPages);

    return result;
  };

  const pageRange = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const handleCalculateShowRecords = useCallback(() => {
    if (totalRecords <= pageLimit) return totalRecords;
    const records = pageNumber * pageLimit;
    const difference = records - (records - totalRecords);
    return records <= totalRecords ? records : difference;
  }, [pageLimit, pageNumber, totalRecords]);

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
    pagingEvent(pageNumber);
  };

  const handleMoveLeft = (e) => {
    e.preventDefault();
    handleGotoPage(pageNumber - pageNeighbours);
  };

  const handleMoveRight = (e) => {
    e.preventDefault();
    handleGotoPage(pageNumber + pageNeighbours);
  };

  const handleGotoPage = (page) => {
    const toPage = Math.max(0, Math.min(page, totalPages));

    setPageNumber(toPage);

    handlePageClick(page);
  };

  useEffect(() => {
    setTotalShowRecords(handleCalculateShowRecords());
  }, [pageNumber, handleCalculateShowRecords, totalRecords]);

  useEffect(() => {
    setPageNumber(currentPage);
  }, [currentPage]);

  return (
    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
      <Nav>
        <Pagination className="mb-2 mb-lg-0">
          {renderPageNumbers().map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <Pagination.Prev key={index} onClick={(e) => handleMoveLeft(e)}>
                  Previous
                </Pagination.Prev>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <Pagination.Next key={index} onClick={(e) => handleMoveRight(e)}>
                  Next
                </Pagination.Next>
              );
            }

            return (
              <Pagination.Item key={index} active={pageNumber === page} onClick={() => handlePageClick(page)}>
                {page}
              </Pagination.Item>
            );
          })}
        </Pagination>
      </Nav>
      {totalRecords > 0 && (
        <small className="fw-bold">
          Showing <b>{totalShowRecords}</b> out of <b>{totalRecords}</b> records
        </small>
      )}
      
    </Card.Footer>
  );
};

export default PortalPagination;
