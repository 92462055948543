import { requests } from './api-service';

const billingApi = {
    uploadBillingDocument: (formData) => requests.putForm('billing/upload', formData),
    getPendingGeneratedBillings: (search) => requests.get('billing/pending/generated', setGeneratedBillingListParams(search)),
    updateItemDetail: (itemDetail) => requests.put('billing/item/detail', itemDetail),
    approveBillingData: (formData) => requests.putForm('billing/approve', formData),
    cancelBilling: (data) => requests.put('billing/cancel', data),
    getCount: (status, search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billing/count', setBillingListCountParams(status, search, filterStartDate, filterEndDate, filterContainerNumber)),
    list: (status, pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billings', setBillingtListParams(status, pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber)),
    unlinkandmoveitem: (data) => requests.put('billing/unlink-and-move-item', data),
    uploadPdfDocument: (data) => requests.put('billing/upload/pdf', data),
    sendBillingDocument: (data) => requests.put('billing/send/document', data),
    getApprovedCount: (search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billing/approved/count', setBillingListCountParams(null, search, filterStartDate, filterEndDate, filterContainerNumber)),
    getApprovedList: (pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billing/approved/list', setBillingtListParams(null, pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber)),
    getCancelledCount: (search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billing/cancelled/count', setBillingListCountParams(null, search, filterStartDate, filterEndDate, filterContainerNumber)),
    getCancelledList: (pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('billing/cancelled/list', setBillingtListParams(null, pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber)),
};

const setGeneratedBillingListParams = (search = '') => {
    const params = new URLSearchParams();
    if (search) params.append('search', search);
    return params;
};

export const setBillingListCountParams = (status, search, filterStartDate, filterEndDate, filterContainerNumber) => {
    const countParams = new URLSearchParams();
    
    if (status) countParams.append('status', status);
    if (search) countParams.append('search', search);
    if (filterStartDate) countParams.append('filterStartDate', filterStartDate);
    if (filterEndDate) countParams.append('filterEndDate', filterEndDate);
    if (filterContainerNumber) countParams.append('search', filterContainerNumber);
    return countParams;
};
  
export const setBillingtListParams = (status, pageNumber = 1, search = '', filterStartDate = '', filterEndDate = '', filterContainerNumber = '') => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    if (status) params.append('status', status);
    if (search) params.append('search', search);
    if (filterStartDate) params.append('filterStartDate', filterStartDate);
    if (filterEndDate) params.append('filterEndDate', filterEndDate);
    if (filterContainerNumber) params.append('filterContainerNumber', filterContainerNumber);
    return params;
};

export default billingApi;