import React from "react";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from 'moment-timezone';

const ClientPercentagePreview = (props) => {
    const { 
        startDate, 
        endDate, 
        containerNumber,
        reportList,
    } = props;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Percentage of Cebu Clients & China Clients (OC4D) Report</h1>
                    <div className="left">
                        <p>Start Date: <b>{formattedStartDate}</b></p>
                        <p>End Date: <b>{formattedEndDate}</b></p>
                        {containerNumber && (
                            <p>Container Number: <b>{containerNumber}</b></p>
                        )}
                    </div>
                </div>
                <Table hover className="billing-table-list align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">Loading Date</th>
                            <th className="border-bottom">Total Clients</th>
                            <th className="border-bottom">Local Client Percentage</th>
                            <th className="border-bottom">China Client Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="fw-normal">{moment(item.loadingDate).format('YYYY-MM-DD')}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{item.totalClients}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{`${item.localClientPercentage}%`}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{`${item.chinaClientPercentage}%`}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default ClientPercentagePreview;