import React from 'react';
import MainRouter from './MainRouter';
import ScrollToTop from './components/ScrollToTop';
import { ErrorBoundary } from 'react-error-boundary';

if (process.env.NODE_ENV === 'production') {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null;
    }
  }
}

// Fallback UI for error boundary
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" style={{ padding: '1rem', textAlign: 'center' }}>
      <h2>Something went wrong</h2>
      <p>{error.message}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const App = () => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // Reset any application state if needed
      window.location.reload(); // Optional: Reload page on reset
    }}
  >
    <ScrollToTop />
    <MainRouter />
  </ErrorBoundary>
);

export default App;
