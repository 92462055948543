export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const addLeadingZeros = (number, digits) => {
  const numberString = number.toString();
  const zerosToAdd = digits - numberString.length;
  if (zerosToAdd > 0) {
    return '0'.repeat(zerosToAdd) + numberString;
  }
  return numberString;
};

export const formatCurrency = (num) => {
  if (!num && num !== 0) return '0.00';
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncateDescription = (description, length) => {
  return description.length > length ? `${description.substring(0, length)}...` : description;
};
