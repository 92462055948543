import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { faCalendarAlt, faEdit, faPlus, faSave, faTag, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Row, Col, InputGroup, Form, ButtonGroup, Table, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import './styles.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import productApi from '../services/product-service';
import clientApi from '../services/client-service';
import { confirmArchiveDialog, showError, showOk } from "../components/Alerts";
import { DEFAULT_TIMZONE, SALES_QUOTATION_ADD_PRODUCT, SALES_QUOTATION_CREATE_QUOTATION, shipmentTypes } from "../utils/constants";
import quotationApi from '../services/quotation-service';
import paymentTermsApi from '../services/payment-term-service';
import SalesAddProduct from "./salesaddproduct";
import productCategoryApi from '../services/product-category-services';
import SalesProductDetail from "./salesproductdetail";
import { hasPermission } from "../auth/UserPermissionTypes";

const SalesQuotation = ({ 
        history, 
        quotationData, 
        clientCollection,
        productCollection,
        clientWithPaymentTermsCollection,
        viewMode,
        hideEvent,
        refreshList,
        productCategoryCollection,
        refreshProductList,
        handleProductDetailFromDatatable
   }) => {

    const dispatch = useDispatch();
    const { getProductCategoryNames } = productCategoryApi;
    const { getProductOptions } = productApi;
    const { getClientNames } = clientApi;
    const { createQuotation } = quotationApi;
    const { getClientsWithTerms } = paymentTermsApi;
    const [salesQuotationProducts, setSalesQuotationProducts] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isAddingNewLine, setIsAddingNewLine] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [clients, setClients] = useState(clientCollection || []);
    const [productList, setProductList] = useState(productCollection || []);
    const [clientPaymentTerms, setClientPaymentTerms] = useState([]);
    const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
    const [issueDate, setIssueDate] = useState();
    const [validUntilDate, setValidUntilDate] = useState();
    const [createSalesQuotation, setCreateSalesQuotation] = useState(quotationData);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientWithPaymentTerms, setClientWithPaymentTerms] = useState(clientWithPaymentTermsCollection || []);
    const [isEditMode, setIsEditMode] = useState(true);
    const [productCategories, setProductCategories] = useState(productCategoryCollection || []);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [productDetailToView, setProductDetailToView] = useState(null);
    const [showProductDetail, setShowProductDetail] = useState(false);
    
    const [totals, setTotals] = useState({
        totalAmount: 0,
        totalMarkUpAmount: 0
    });

    const [overAllTotal, setOverAllTotal] = useState({
        overAllTotalAmount: 0,
        overAllTotalMarkUpAmount: 0
    });

    const salesProductSchema = Yup.object().shape({
        quotationBillingClient: Yup.string().required('Billing client is required'),
        quotationIssueDate: Yup.string().required('Issue date is required'),
        quotationValidUntil: Yup.string().required('Valid until date is required'),
        quotationPaymentTerms: Yup.string().required('Payment terms is required'),
        quotationShipmentType: Yup.string().required('Shipment type is required'),
        quotationDeliveryTerms: Yup.string().required('Delivery terms is required'),
        quotationDeliveryPeriod: Yup.string().required('Delivery period is required'),
        quotationPackage: Yup.string().required('Package description is required'),
        quotationShipmentNotes: Yup.string().required('Shipment notes is required'),
    });

    const salesAddProductSchema = Yup.object().shape({
        quotationBillingClient: Yup.string().required('Billing client is required'),
        quotationIssueDate: Yup.string().required('Issue date is required'),
        quotationValidUntil: Yup.string().required('Valid until date is required'),
        quotationPaymentTerms: Yup.string().required('Payment terms is required'),
        quotationShipmentType: Yup.string().required('Shipment type is required'),
        quotationDeliveryTerms: Yup.string().required('Delivery terms is required'),
        quotationDeliveryPeriod: Yup.string().required('Delivery period is required'),
        quotationPackage: Yup.string().required('Package description is required'),
        quotationShipmentNotes: Yup.string().required('Shipment notes is required'),
    });
    

    // Conditional schema resolver based on `isAddingNewLine`
    const conditionalResolver = (isAddingNewLine) => {
        return yupResolver(isAddingNewLine ? salesAddProductSchema : salesProductSchema);
    };

    const formOptions = {
        resolver: conditionalResolver(isAddingNewLine),
        mode: 'all',
        defaultValues: {
            quotationBillingClient: quotationData?.clientData?._id,
            quotationPaymentTerms: quotationData?.paymentTermData?._id,
            quotationIssueDate: quotationData?.issueDate,
            quotationProductNotes: quotationData?.quotationProductNotes,
            quotationDeliveryTerms: quotationData?.deliveryTerm,
            quotationShipmentNotes: quotationData?.shipmentNotes,
            quotationDeliveryPeriod: quotationData?.deliveryPeriod,
            quotationPackage: quotationData?.packageDescription,
            quotationOtherTermAndCondition: quotationData?.otherNotesForTermsAndCondition,
        }
    };

    const { register, handleSubmit, setValue, formState, getValues, trigger, reset } = useForm(formOptions);
    const { errors } = formState;
    

    const handleNumberChange = (e, fieldName) => {
        let value = e.target.value;
    
        value = value ? parseFloat(value) : null;
    
        setValue(fieldName, value);
        checkIfCanSave();
        updateProductTotals();
    };

    const handleModelChange = (e, fieldName) => {
        const value = e.target.value;
        setValue(fieldName, value);
        checkIfCanSave();
    };

    const handleChangeRemarks = () => {
        checkIfCanSave();
    };

    const handleIssueDateChange = (date) => {
        setIssueDate(date);
        setValue('quotationIssueDate', date);
        
        // Add 15 days to the issue date and set the valid until date
        const validUntilDate = moment(date).add(15, 'days');
        setValidUntilDate(validUntilDate);
        setValue('quotationValidUntil', validUntilDate);
        
        trigger('quotationIssueDate');
        trigger('quotationValidUntil');
    };
    
    const handleValidDateUntilChange = (date) => {
        setValidUntilDate(date);
        setValue('quotationValidUntil', date);
        trigger('quotationValidUntil');
    };

    const updateProductTotals = () => {
        const values = getValues();
        
        const { quotationQuantity, quotationProductPrice, quotationMarkUpPrice } = values;
    
        // Calculate totals using quantity, price, and mark-up price
        const { totalAmount, totalMarkUpAmount } = calculateTotals(
            quotationQuantity || 0,
            quotationProductPrice || 0,
            quotationMarkUpPrice || 0
        );
    
        // Update total amounts in the state directly
        setTotals({
            totalAmount,
            totalMarkUpAmount
        });
    
        // Disable save if there's no product model set
        if (!values.quotationProductModel) {
            setIsSaveDisabled(true);
        }
    };

    const calculateTotals = (quantity, price, markUpPrice) => {
        const totalAmount = quantity * price;
        const totalMarkUpAmount = quantity * markUpPrice;
        return { totalAmount, totalMarkUpAmount };
    };

    const handleSelect = (selectedOption) => {
        const selectedProductId = selectedOption.value;
    
        // Check if the selected product is already in salesQuotationProducts
        const isProductAlreadySelected = salesQuotationProducts.some(
            (product) => product.productId === selectedProductId
        );
    
        if (isProductAlreadySelected) {
            showError("Product has already been added to the quotation.");
            return;
        }
    
        setSelectedOption(selectedOption);
    
        // Find product detail from productCollection instead of making an API call
        const productDetail = productList.find((product) => product._id === selectedProductId);
    
        if (!productDetail) {
            showError("Product not found in the collection");
            return;
        }
    
        const productInfo = productDetail;

        setValue('quotationProductModel', productInfo.model);
    
        // Set quotationProductPrice to 2 decimal places
        const productPrice = parseFloat(productInfo.price.toFixed(2));
        setValue('quotationProductPrice', productPrice);
    
        // Calculate and set mark-up price to 2 decimal places
        const markUpPrice = parseFloat(((productInfo.price * productInfo.markUpPrice) / 100).toFixed(2));
        const totalPriceWithMarkUp = parseFloat((productInfo.price + markUpPrice).toFixed(2));
        setValue('quotationMarkUpPrice', totalPriceWithMarkUp);
    
        // Trigger validation for the fields
        triggerSalesProductValidation();
    
        checkIfCanSave();
        updateProductTotals();
    };
    
    
    const triggerSalesProductValidation = () => {
        trigger('quotationProductModel');
        trigger('quotationProductPrice');
        trigger('quotationMarkUpPrice');
        trigger('quotationQuantity');
    }

    const checkIfCanSave = () => {
        const values = getValues();
        const canSave = values.quotationProductModel && values.quotationQuantity && values.quotationProductPrice && values.quotationMarkUpPrice;
        setIsSaveDisabled(!canSave);
    };

    const calculateOverallTotals = (products) => {
        let overAllTotalAmount = 0;
        let overAllTotalMarkUpAmount = 0;
    
        // Calculate sum of prices and mark-up prices
        products.forEach((product) => {
            overAllTotalAmount += product.totalAmount; // Sum up all product prices
            overAllTotalMarkUpAmount += product.totalMarkUpAmount; // Sum up all mark-up prices
        });
    
        setOverAllTotal({
            overAllTotalAmount,
            overAllTotalMarkUpAmount
        });
    };

    const handleSave = () => {
        const values = getValues();
        const { quotationProductModel, quotationQuantity, quotationProductPrice, quotationMarkUpPrice } = values;
    
        if (!quotationProductModel) {
            showError('Product model is required');
            return;
        }
    
        if (!quotationQuantity || quotationQuantity < 1) {
            showError('Quantity must be at least 1');
            return;
        }
    
        if (!quotationProductPrice || quotationProductPrice < 0) {
            showError('Product price must be a positive number');
            return;
        }
    
        if (!quotationMarkUpPrice || quotationMarkUpPrice < 0) {
            showError('Mark-up price must be a positive number');
            return;
        }
    
        try {
            const productId = selectedOption.value;
    
            // Find product details from productList instead of calling an API
            const productInfo = productList.find((product) => product._id === productId);
            
            let productPhoto = process.env.REACT_APP_NO_IMAGE;
    
            if (!productInfo) {
                showError("Product not found in the collection");
                return;
            }
    
            // Find the primary resource
            const primaryResource = productInfo.resources.find(resource => resource.isPrimary);
    
            // Set the product photo to the primary resource's thumbnail if it exists
            if (primaryResource) {
                productPhoto = primaryResource.thumbnail;
            } else if (productInfo.resources.length > 0) {
                // If no primary resource, fall back to the first resource's thumbnail
                productPhoto = productInfo.resources[0].thumbnail;
            }
    
            const markUpPercent = productInfo.markUpPrice;
    
            const salesProduct = {
                productId: productId,
                product: selectedOption.label,
                model: values.quotationProductModel,
                price: parseFloat(values.quotationProductPrice),
                quantity: values.quotationQuantity,
                markUpPrice: parseFloat(values.quotationMarkUpPrice),
                markUpPercent,
                totalAmount: parseFloat(totals?.totalAmount),
                totalMarkUpAmount: parseFloat(totals?.totalMarkUpAmount),
                description: values.quotationDescription || '',
                photo: productPhoto
            };
    
            let updatedProducts = [...salesQuotationProducts];
    
            if (editRowIndex !== null) {
                // Update the existing product in the list
                updatedProducts[editRowIndex] = salesProduct;
                setSalesQuotationProducts(updatedProducts);
                setEditRowIndex(null);
            } else {
                // Add new product to the list
                updatedProducts = [...salesQuotationProducts, salesProduct];
                setSalesQuotationProducts(updatedProducts);
            }
    
            // Calculate and update overall totals (prices and mark-up prices)
            calculateOverallTotals(updatedProducts);
    
            setTotals({
                totalAmount: 0,
                totalMarkUpAmount: 0
            });
    
            resetForm();
            setIsSaveDisabled(true);
            setIsAddingNewLine(false);
        } catch (error) {
            console.error(error);
        }
    };     

    const resetForm = () => {
        setValue('quotationProductModel', '');
        setValue('quotationQuantity', 1);
        setValue('quotationProductPrice', '');
        setValue('quotationMarkUpPrice', '');
        setValue('quotationDescription', '');
        setSelectedOption(null);
    };

    const handleAddNewLine = () => {
        setIsAddingNewLine(true);
        setEditRowIndex(null);
        resetForm();

    };

    const handleEditRow = async (index) => {
        const product = salesQuotationProducts[index];
        
        try {

            // Set product details in the form
            setSelectedOption({ value: product.productId, label: product.product });
            setValue('quotationProductModel', product.model);
            setValue('quotationQuantity', product.quantity);
            setValue('quotationProductPrice', product.price);
            setValue('quotationMarkUpPrice', product.markUpPrice);
            setValue('quotationDescription', product.description);
    
            // Calculate totals using fetched product details
            const totalAmount = product.quantity * product.price;
            const totalMarkUpAmount = product.quantity * product.markUpPrice;
    
            setTotals({
                totalAmount,
                totalMarkUpAmount
            });
    
            setEditRowIndex(index);
            setIsAddingNewLine(true);

            triggerSalesProductValidation();
            
        } catch (error) {
            console.error("Failed to fetch product details for edit", error);
        }
    };

    const handleCancelEdit = () => {
        setEditRowIndex(null);
        setIsAddingNewLine(false);
        resetForm();
    };

    const handleDeleteRow = async (index) => {
        let text = 'Do you want to delete this product in quotation?';
        let confirmBtnText = 'Yes, delete it!';
        let callbackTitle = 'Deleted!';
        let callbackText = 'Quotation product has been deleted!';

        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
            const updatedProducts = salesQuotationProducts.filter((_, i) => i !== index);
            setSalesQuotationProducts(updatedProducts);
            calculateOverallTotals(updatedProducts);
        }
    };

    // Handle client selection change
    const handleClientSelectChange = (selectedOption) => {
        const clientId = selectedOption?.value;
        setSelectedClient(selectedOption);
        setValue('quotationBillingClient', clientId);

        if (clientId) {
            const clientData = clientWithPaymentTerms.find(client => client.clientId === clientId);

            if (clientData && clientData.paymentTerms.length > 0) {
                const formattedPaymentTerms = clientData.paymentTerms.map(term => ({
                    _id: term.paymentTermId,
                    name: term.name
                }));
                setClientPaymentTerms(formattedPaymentTerms);
            } else {
                setClientPaymentTerms([]);
            }

            setShowTermsAndCondition(true);
        } else {
            setClientPaymentTerms([]);
            setShowTermsAndCondition(false);
        }
    };

    const onSubmitQuotation = () => {
        if (salesQuotationProducts.length === 0) {
            showError("Please add products");
            return;
        }
    
        // Prepare the data for creating a quotation
        const formValues = getValues(); // Retrieve form values from react-hook-form

        const quotationId = quotationData?._id;
    
        const quotationFormData = {
            ...(quotationId && { id: quotationId }), // Add the id field only if quotationId has a value
            quotationNumber: formValues.quotationNumber,  // This might be generated on the server
            clientData: formValues.quotationBillingClient, // Client ID
            issueDate: formValues.quotationIssueDate,
            validDateUntil: formValues.quotationValidUntil,
            quotationProductNotes: formValues.quotationProductNotes || "",
            paymentTermData: formValues.quotationPaymentTerms, // Payment term ID
            shipmentType: formValues.quotationShipmentType,
            shipmentNotes: formValues.quotationShipmentNotes || "",
            deliveryTerm: formValues.quotationDeliveryTerms || "",
            totalBaseAmount: overAllTotal.overAllTotalAmount,  // Overall total amount calculated earlier
            totalMarkUpAmount: overAllTotal.overAllTotalMarkUpAmount,  // Overall mark-up amount calculated earlier
            deliveryPeriod: formValues.quotationDeliveryPeriod || 0,
            packageDescription: formValues.quotationPackage || "",
            otherNotesForTermsAndCondition: formValues.quotationOtherTermAndCondition || "",
            products: salesQuotationProducts.map((product) => ({
                productData: product.productId, 
                model: product.model,
                quantity: product.quantity,
                productBasePrice: product.price,
                productMarkUpPrice: product.markUpPrice,
                productMarkUpPercent: product.markUpPercent,
                totalBasePrice: product.totalAmount,
                totalMarkUpPrice: product.totalMarkUpAmount,
                remarks: product.description || ""
            })),
        };
    
        try {
            // Call the API to create the quotation
            createQuotation(quotationFormData)
                .then((response) => {
                    if (response?.error) {
                        showError(response.error);
                    } else {
                        showOk('Quotation created successfully!');  
                        reset();
                        setSalesQuotationProducts([]);
                        if(history){
                            history.push('/myboard/sales-management/sale-order-list')
                        }
                        if(refreshList) refreshList();
                        handleCancelCreateSalesQuotation();
                    }
                })
                .catch((error) => {
                    console.error("Error creating quotation", error);
                });
        } catch (error) {
            showError(error.message || "An error occurred while creating the quotation.");
        }
    };
    

    const generateLabel = (label, fieldName) => {
        const isRequired = salesProductSchema.fields[fieldName];
        return (
            <Form.Label className="light-font">
                {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Form.Label>
        );
    };

    const handleCreateSalesQuotation = () => {
        setCreateSalesQuotation(true);
        handleFetchProducts();
        handleFetchClients();
        handleFetchClientsWithPaymentterms();
    }

    const handleCancelCreateSalesQuotation = () => {
        setCreateSalesQuotation(false);
        if(quotationData) setIsEditMode(false);
        if(hideEvent) hideEvent();
    }

    const handleFetchProducts = () => {
        if(productList.length === 0){
            getProductOptions().then(data => {
                setProductList(data.products);
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    const transformProductOptions = (products) => {
        return products.map(product => ({
            value: product._id,
            label: product.name
        }))
    }

    const handleFetchClients = () => {
        if(clients.length === 0){
            getClientNames().then(data => {
                setClients(data.map(client => ({
                    value: client._id,
                    label: client.name
                })));
            }).catch(e => {
                console.log(e);
            })
        }
    }

    const handleFetchClientsWithPaymentterms = () => {
        if(clientPaymentTerms.length === 0){
            getClientsWithTerms().then(data => {
                setClientWithPaymentTerms(data);
            }).catch(e => {
                console.log(e);
            })
        }
    }

    const transformQuotationProducts = (quotationProducts) => {
        return quotationProducts.map((product) => {

            const productInfo = productList.find((p) => p._id === product.productData._id);
                
            let productPhoto = process.env.REACT_APP_NO_IMAGE;

            const primaryResource = productInfo.resources.find(resource => resource.isPrimary);

            if (primaryResource) {
                productPhoto = primaryResource.thumbnail;
            } else if (productInfo.resources.length > 0) {
                productPhoto = productInfo.resources[0].thumbnail;
            }
            
            return {
                id: product._id,
                productId: product?.productData?._id,
                product: product?.productData?.name,
                model: product.model,
                quantity: product.quantity,
                price: product.productBasePrice,
                markUpPrice: product.productMarkUpPrice,
                totalAmount: product.totalBasePrice,
                totalMarkUpAmount: product.totalMarkUpPrice,
                description: product.remarks || "",
                photo: productPhoto
            }
        });
    };

    const setQuotationHeaderText = () => {
        if (!quotationData && isEditMode) return "Create Sales Quotation";
        else if (quotationData && isEditMode) return "Update Sales Quotation";

        return "Sales Quotation Details";
    }

    const handleAddProduct = () => {
        if(!productCategories){
            getProductCategoryNames().then(data => {
                setProductCategories(data);
            })
            .catch(e => {
                console.log(e);
            })
        }
        setShowAddProduct(true);
    }

    const handleCancelAddProduct = () => setShowAddProduct(false);

    const handleShowProductDetail = (productId) => {
        if(quotationData){
            handleProductDetailFromDatatable(productId)
        }
        else
        {
            const productData = productList.find((product) => product._id === productId);
            setProductDetailToView(productData);
            setShowProductDetail(true);
        }
    }

    const handleCancelProductDetail = () => {
        setProductDetailToView(null);
        setShowProductDetail(false);
    }

    useEffect(() => {
        if(!quotationData){
            dispatch(setPortalPage('Create Sales Quotation'));
        }
    }, [quotationData, dispatch]);

    useEffect(() => {
        if (quotationData) {
            // Set client data
            if (quotationData?.clientData?._id && clients.length > 0) {
                const selectedClientFromData = clients.find(client => client.value === quotationData.clientData._id);
                if (selectedClientFromData) {
                    const clientId = selectedClientFromData.value;
                    setSelectedClient(selectedClientFromData);
                    setValue('quotationBillingClient', clientId);
    
                    // Set payment terms based on client
                    if (clientId) {
                        const clientData = clientWithPaymentTerms.find(client => client.clientId === clientId);
                        if (clientData && clientData.paymentTerms.length > 0) {
                            const formattedPaymentTerms = clientData.paymentTerms.map(term => ({
                                _id: term.paymentTermId,
                                name: term.name
                            }));
                            setClientPaymentTerms(formattedPaymentTerms);
                        } else {
                            setClientPaymentTerms([]);
                        }
                        setShowTermsAndCondition(true);
                    } else {
                        setClientPaymentTerms([]);
                        setShowTermsAndCondition(false);
                    }
                }
            }
    
            // Set issue date
            if (quotationData?.issueDate) {
                const issueDateMoment = moment(quotationData.issueDate);
                setIssueDate(issueDateMoment);  // Set issueDate state
                setValue('quotationIssueDate', issueDateMoment);  // Set value in react-hook-form
                const validUntilDate = moment(issueDateMoment).add(15, 'days');
                setValidUntilDate(validUntilDate);
                setValue('quotationValidUntil', validUntilDate);
            }
        }
    }, [quotationData, clients, setValue, clientWithPaymentTerms]);    

    useEffect(() => {
        if (quotationData?.issueDate) {
            const issueDateMoment = moment(quotationData.issueDate);
            setIssueDate(issueDateMoment);  // Set issueDate from quotationData
            setValue('quotationIssueDate', issueDateMoment);  // Set the value in react-hook-form
            const validUntilDate = moment(issueDateMoment).add(15, 'days');
            setValidUntilDate(validUntilDate);
            setValue('quotationValidUntil', validUntilDate);
        }
    }, [quotationData, setValue]);

    useEffect(() => {
        if (quotationData?.generatedQuotationProducts) {
            const transformedProducts = transformQuotationProducts(quotationData.generatedQuotationProducts);
            setSalesQuotationProducts(transformedProducts);
            calculateOverallTotals(transformedProducts);
        }
    }, [quotationData]);

    useEffect(() => {
        if(viewMode) setIsEditMode(viewMode === 'update');
    }, [viewMode]);

    return (
        <>
            {showAddProduct && (
                <SalesAddProduct 
                    productCategories={productCategories} 
                    cancelEdit={handleCancelAddProduct} 
                    refreshList={refreshProductList}
                />
            )}

            {showProductDetail && (
                <SalesProductDetail
                    product={productDetailToView}
                    cancelEdit={handleCancelProductDetail} 
                />
            )}
            <Card border="light" className="shadow-sm no-border-radius">
                <Form  onSubmit={handleSubmit(onSubmitQuotation)}>
                    <Card.Body className="pt-0">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <Row className='align-items-start search-and-filter-content'>
                                {createSalesQuotation && (
                                    <Col md={6} className="mb-3">
                                        <h3>{setQuotationHeaderText()}</h3>
                                    </Col>
                                )}
                            </Row>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>
                                    {hasPermission(SALES_QUOTATION_CREATE_QUOTATION) && !quotationData && (
                                        <Button variant="outline-primary" size="sm" onClick={() => handleCreateSalesQuotation()}>
                                            <FontAwesomeIcon icon={faPlus} className="me-2" /> New Quotation
                                        </Button>
                                    )}
                                    {hasPermission(SALES_QUOTATION_ADD_PRODUCT) && (
                                        <Button variant="outline-primary" size="sm" onClick={() => handleAddProduct()}>
                                            <FontAwesomeIcon icon={faTag} className="me-2" /> New Product
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </div>
                        </div>
                        {createSalesQuotation && (
                            <>
                                <Row className='align-items-start mb-3'>
                                    <Col md={3} className="mb-3">
                                        <Form.Group id="quotationClients">
                                            {generateLabel('Bill To', 'quotationBillingClient')}
                                            {isEditMode ? (
                                                <Select
                                                    options={clients}
                                                    value={selectedClient}
                                                    onChange={handleClientSelectChange}
                                                    placeholder="Select Client"
                                                    isClearable
                                                />
                                            ) : (
                                                <p className="mb-0 bold-700">{quotationData?.clientData?.displayName || ''}</p>
                                            )}
                                            
                                            {errors.quotationBillingClient?.message && <small className="invalid-fields">{errors.quotationBillingClient.message}</small>}
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col md={3} className="mb-3">
                                        <Form.Group id="quotation-issue-date">
                                            {generateLabel('Issue Date', 'quotationIssueDate')}
                                            {isEditMode ? (
                                                <Datetime
                                                    timeFormat={false}
                                                    onChange={handleIssueDateChange}
                                                    closeOnSelect={true}
                                                    renderInput={(props, openCalendar) => (
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            name="quotationIssueDate"
                                                            value={issueDate ? moment(issueDate).format('MM/DD/YYYY') : ''}
                                                            placeholder="Select date"
                                                            onFocus={openCalendar}
                                                            autoComplete="off"
                                                            onChange={(e) => setIssueDate(e.target.value)}
                                                        />
                                                    </InputGroup>
                                                    )}
                                                />
                                            ) : (
                                                <p className="mb-0 bold-700">{moment(quotationData?.issueDate).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY')}</p>
                                            )}
                                            
                                            {errors.quotationIssueDate?.message && <small className="invalid-fields">{errors.quotationIssueDate.message}</small>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} className="mb-3">
                                        <Form.Group id="quotation-valid-until">
                                            {generateLabel('Valid Until', 'quotationValidUntil')}
                                            {isEditMode ? (
                                                <Datetime
                                                    timeFormat={false}
                                                    onChange={handleValidDateUntilChange}
                                                    closeOnSelect={true}
                                                    renderInput={(props, openCalendar) => (
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            name="quotationValidUntil"
                                                            value={validUntilDate ? moment(validUntilDate).format('MM/DD/YYYY') : ''}
                                                            placeholder="Select date"
                                                            onFocus={openCalendar}
                                                            onChange={(e) => setValidUntilDate(e.target.value)}
                                                            autoComplete="off"
                                                        />
                                                    </InputGroup>
                                                    )}
                                                />
                                            ) : (
                                                <p className="mb-0 bold-700">{moment(validUntilDate?.issueDate).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY')}</p>
                                            )}
                                            
                                            {errors.quotationValidUntil?.message && <small className="invalid-fields">{errors.quotationValidUntil.message}</small>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3"></h3>
                                <Table hover className="quotation-data-table align-items-center mb-3">
                                    <thead>
                                        <tr>
                                            <th className="border-bottom">Product</th>
                                            <th className="border-bottom">Model</th>
                                            <th className="border-bottom">Quantity</th>
                                            <th className="border-bottom">Price</th>
                                            <th className="border-bottom">Mark Up Price</th>
                                            <th className="border-bottom">Total Price</th>
                                            <th className="border-bottom">Total Mark Up Price</th>
                                            <th className="border-bottom">Remarks</th>
                                            <th className="border-bottom"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesQuotationProducts && salesQuotationProducts.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Group id="quotation-product">
                                                            <Select
                                                                options={productList}
                                                                value={selectedOption}
                                                                onChange={handleSelect}
                                                                placeholder="Select Product"
                                                                isDisabled={editRowIndex !== null}
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                <a href="#!" className="user-avatar"
                                                                    onClick={() => handleShowProductDetail(product.productId)}
                                                                >
                                                                    <Image src={product.photo} className="rounded-circle" />
                                                                </a>
                                                            </Col>
                                                            <Col className="ms--2 quotation-product-photo">
                                                                <h4 className="h6 mb-0">
                                                                    <a href="#!" 
                                                                        onClick={() => handleShowProductDetail(product.productId)}
                                                                    >{product.product}</a>
                                                                </h4>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </td>
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Group id="quotation-product-model">
                                                            <Form.Control
                                                                type="text"
                                                                name="quotationProductModel"
                                                                placeholder="Product Model"
                                                                {...register('quotationProductModel')}
                                                            />
                                                            {errors.quotationProductModel?.message && (
                                                                <small className="invalid-fields">{errors.quotationProductModel.message}</small>
                                                            )}
                                                        </Form.Group>
                                                    ) : (
                                                        <span className="fw-normal">{product.model}</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Group id="quotation-quantity">
                                                            <Form.Control
                                                                type="number"
                                                                name="quotationQuantity"
                                                                placeholder="0"
                                                                {...register('quotationQuantity', { onChange: (e) => handleNumberChange(e, 'quotationQuantity') })}
                                                                min="1"
                                                                step="0.01"
                                                            />
                                                            {errors.quotationQuantity?.message && (
                                                                <small className="invalid-fields">{errors.quotationQuantity.message}</small>
                                                            )}
                                                        </Form.Group>
                                                    ) : (
                                                        <span className="fw-normal">{product.quantity}</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Group id="quotation-product-price">
                                                            <Form.Control
                                                                type="number"
                                                                name="quotationProductPrice"
                                                                placeholder="00.00"
                                                                {...register('quotationProductPrice', { onChange: (e) => handleNumberChange(e, 'quotationProductPrice') })}
                                                                min="0"
                                                                step="0.01"
                                                            />
                                                            {errors.quotationProductPrice?.message && (
                                                                <small className="invalid-fields">{errors.quotationProductPrice.message}</small>
                                                            )}
                                                        </Form.Group>
                                                    ) : (
                                                        <span className="fw-normal">{product.price.toFixed(2)}</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Group id="quotation-mark-up-price">
                                                            <Form.Control
                                                                type="number"
                                                                name="quotationMarkUpPrice"
                                                                placeholder="00.00"
                                                                {...register('quotationMarkUpPrice', { onChange: (e) => handleNumberChange(e, 'quotationMarkUpPrice') })}
                                                                min="0"
                                                                step="0.01"
                                                            />
                                                            {errors.quotationMarkUpPrice?.message && (
                                                                <small className="invalid-fields">{errors.quotationMarkUpPrice.message}</small>
                                                            )}
                                                        </Form.Group>
                                                    ) : (
                                                        <span className="fw-normal">{product.markUpPrice.toFixed(2)}</span>
                                                    )}
                                                </td>
                                                {editRowIndex === index ? (
                                                    <>
                                                        <td>
                                                            <span className="fw-normal">{(totals?.totalAmount || 0).toFixed(2)}</span>
                                                        </td>
                                                        <td>
                                                            <span className="fw-normal">{(totals?.totalMarkUpAmount || 0).toFixed(2)}</span>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>
                                                            <span className="fw-normal">{product.totalAmount.toFixed(2)}</span> 
                                                        </td>
                                                        <td>
                                                            <span className="fw-normal">{product.totalMarkUpAmount.toFixed(2)}</span>
                                                        </td>
                                                    </>
                                                )}
                                                
                                                <td>
                                                    {editRowIndex === index ? (
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={1}
                                                            placeholder="Description"
                                                            name="quotationDescription"
                                                            {...register('quotationDescription', { onChange: () => handleChangeRemarks() })}
                                                        />
                                                    ) : (
                                                        <span className="fw-normal word-break">{product.description}</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {isEditMode && (
                                                        <ButtonGroup>
                                                            {editRowIndex === index ? (
                                                                <>
                                                                    <Button 
                                                                        variant="primary" 
                                                                        size="sm" 
                                                                        onClick={handleSave} 
                                                                        disabled={isSaveDisabled}
                                                                    >
                                                                        <FontAwesomeIcon icon={faSave} title="Save" />
                                                                    </Button>
                                                                    <Button variant="danger" size="sm" onClick={handleCancelEdit}>
                                                                        <FontAwesomeIcon icon={faTimes} title="Cancel" />
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button variant="primary" size="sm" onClick={() => handleEditRow(index)}>
                                                                        <FontAwesomeIcon icon={faEdit} title="Edit" />
                                                                    </Button>
                                                                    <Button variant="danger" size="sm" onClick={() => handleDeleteRow(index)}>
                                                                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </ButtonGroup>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        {isAddingNewLine && editRowIndex === null && (
                                            <tr>
                                                <td>
                                                    <Form.Group id="quotation-product">
                                                        <Select
                                                            options={transformProductOptions(productList)}
                                                            value={selectedOption}
                                                            onChange={handleSelect}
                                                            placeholder="Select Product"
                                                        />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group id="quotation-product-model">
                                                        <Form.Control
                                                            type="text"
                                                            name="quotationProductModel"
                                                            placeholder="Product Model"
                                                            {...register('quotationProductModel', { onChange: (e) => handleModelChange(e, 'quotationProductModel') })}
                                                            disabled={!selectedOption}
                                                        />
                                                        {errors.quotationProductModel?.message && (
                                                            <small className="invalid-fields">{errors.quotationProductModel.message}</small>
                                                        )}
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group id="quotation-quantity">
                                                        <Form.Control
                                                            type="number"
                                                            name="quotationQuantity"
                                                            placeholder="0"
                                                            {...register('quotationQuantity', { onChange: (e) => handleNumberChange(e, 'quotationQuantity') })}
                                                            min="0"
                                                            step="0.01"
                                                            disabled={!selectedOption}
                                                        />
                                                        {errors.quotationQuantity?.message && (
                                                            <small className="invalid-fields">{errors.quotationQuantity.message}</small>
                                                        )}
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group id="quotation-product-price">
                                                        <Form.Control
                                                            type="number"
                                                            name="quotationProductPrice"
                                                            placeholder="00.00"
                                                            {...register('quotationProductPrice', { onChange: (e) => handleNumberChange(e, 'quotationProductPrice') })}
                                                            min="0"
                                                            step="0.01"
                                                            disabled={!selectedOption}
                                                        />
                                                        {errors.quotationProductPrice?.message && (
                                                            <small className="invalid-fields">{errors.quotationProductPrice.message}</small>
                                                        )}
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group id="quotation-mark-up-price">
                                                        <Form.Control
                                                            type="number"
                                                            name="quotationMarkUpPrice"
                                                            placeholder="00.00"
                                                            {...register('quotationMarkUpPrice', { onChange: (e) => handleNumberChange(e, 'quotationMarkUpPrice') })}
                                                            min="0"
                                                            step="0.01"
                                                            disabled={!selectedOption}
                                                        />
                                                        {errors.quotationMarkUpPrice?.message && (
                                                            <small className="invalid-fields">{errors.quotationMarkUpPrice.message}</small>
                                                        )}
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <span className="fw-normal">{(totals?.totalAmount || 0).toFixed(2)}</span>
                                                </td>
                                                <td>
                                                    <span className="fw-normal">{(totals?.totalMarkUpAmount || 0).toFixed(2)}</span>
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="Description"
                                                        name="quotationDescription"
                                                        {...register('quotationDescription', { onChange: () => handleChangeRemarks() })}
                                                        disabled={!selectedOption}
                                                    />
                                                </td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button 
                                                            variant="primary" 
                                                            size="sm" 
                                                            onClick={() => handleSave()} 
                                                            disabled={isSaveDisabled}
                                                        >
                                                            <FontAwesomeIcon icon={faSave} title="Save" />
                                                        </Button>
                                                        <Button variant="danger" size="sm" onClick={handleCancelEdit}>
                                                            <FontAwesomeIcon icon={faTimes} title="Cancel" />
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {isEditMode && (
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Button size="sm" 
                                                onClick={() => handleAddNewLine()}
                                                disabled={isAddingNewLine}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add New Line
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={6} className="mb-3">
                                        {generateLabel('Quotation Product Notes', 'quotationProductNotes')}
                                        {isEditMode ? (
                                            <Form.Group id="quotationProductNotes">
                                                <Form.Control as="textarea" placeholder='Add a note' rows="3" {...register('quotationProductNotes')} />
                                            </Form.Group>
                                        ) : (
                                            <p className="mb-0 bold-700 word-break">{quotationData?.quotationProductNotes || ''}</p>
                                        )}
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Card border="light" className="shadow-sm">
                                            <Card.Body>
                                                <div className="d-flex align-items-center border-bottom justify-content-between border-light pb-md-3 mb-md-3">
                                                    <div>
                                                        <h5>Total Amount</h5>
                                                    </div>
                                                    <div>
                                                        <h5>{(overAllTotal?.overAllTotalAmount || 0).toLocaleString()}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between border-light">
                                                    <div>
                                                        <h3>Total Mark-up Amount</h3>
                                                    </div>
                                                    <div>
                                                    <h5>{(overAllTotal?.overAllTotalMarkUpAmount || 0).toLocaleString()}</h5>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {showTermsAndCondition && (
                                    <>
                                        <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Terms & Condition</h3>
                                        <Row className='align-items-start mb-3'>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-payment-terms">
                                                    {generateLabel('Payment Term', 'quotationPaymentTerms')}
                                                    {isEditMode ? (
                                                        <Form.Select 
                                                            name="quotationPaymentTerms" 
                                                            defaultValue={quotationData?.paymentTermData?._id || ""}
                                                            {...register('quotationPaymentTerms')}
                                                        >
                                                            <option value="">Select Payment Terms</option>
                                                            {Array.isArray(clientPaymentTerms) && clientPaymentTerms.length > 0 && (
                                                                clientPaymentTerms.map((item, i) => (
                                                                    <option key={i} value={item._id}>{item.name}</option>
                                                                ))
                                                            )}
                                                        </Form.Select>
                                                    ) : (
                                                        <p className="mb-0 bold-700">{quotationData?.paymentTermData?.name || ''}</p>
                                                    )}
                                                    {errors.quotationPaymentTerms?.message && <small className="invalid-fields">{errors.quotationPaymentTerms.message}</small>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-shipment">
                                                    {generateLabel('Shipment Type', 'quotationShipmentType')}
                                                    {isEditMode ? (
                                                        <Form.Select 
                                                            name="quotationShipmentType" 
                                                            defaultValue={quotationData?.shipmentType || ""}
                                                            {...register('quotationShipmentType')}
                                                        >
                                                            <option value="">Select Shipment Type</option>
                                                            {Object.entries(shipmentTypes).map(([key, value]) => (
                                                                <option key={key} value={key}>{`( ${key} ) ${value}`}</option>
                                                            ))}
                                                        </Form.Select>
                                                    ) : (
                                                        <p className="mb-0 bold-700">
                                                            {quotationData?.shipmentType 
                                                                ? `( ${quotationData.shipmentType} ) ${shipmentTypes[quotationData.shipmentType]}`
                                                                : ''
                                                            }
                                                        </p>
                                                    )}
                                                    {errors.quotationShipmentType?.message && (
                                                        <small className="invalid-fields">
                                                            {errors.quotationShipmentType.message}
                                                        </small>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-delivery">
                                                    {generateLabel('Delivery Terms', 'quotationDeliveryTerms')}
                                                    {isEditMode ? (
                                                        <Form.Control as="textarea" placeholder='Add delivery terms' rows="1" {...register('quotationDeliveryTerms')} />
                                                    ) : (
                                                        <p className="mb-0 bold-700 word-break">{quotationData?.deliveryTerm || ''}</p>
                                                    )}
                                                    {errors.quotationDeliveryTerms?.message && <small className="invalid-fields">{errors.quotationDeliveryTerms.message}</small>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='align-items-start mb-3'>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-shipment-notes">
                                                    {generateLabel('Shipment Notes', 'quotationShipmentNotes')}
                                                    {isEditMode ? (
                                                        <Form.Control as="textarea" placeholder='Add shipment notes' rows="1" {...register('quotationShipmentNotes')} />
                                                    ) : (
                                                        <p className="mb-0 bold-700 word-break">{quotationData?.shipmentNotes || ''}</p>
                                                    )}
                                                    
                                                    {errors.quotationShipmentNotes?.message && <small className="invalid-fields">{errors.quotationShipmentNotes.message}</small>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-delivery-period">
                                                    {generateLabel('Delivery Period (days)', 'quotationDeliveryPeriod')}
                                                    {isEditMode ? (
                                                        <Form.Control
                                                            type="number"
                                                            step="1"
                                                            placeholder="0"
                                                            {...register('quotationDeliveryPeriod', { onChange: (e) => handleNumberChange(e, 'quotationDeliveryPeriod') })}
                                                            min="0"
                                                        />
                                                    ) : (
                                                        <p className="mb-0 bold-700">{quotationData?.deliveryPeriod || ''}</p>
                                                    )}
                                                    {errors.quotationDeliveryPeriod?.message && <small className="invalid-fields">{errors.quotationDeliveryPeriod.message}</small>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-package">
                                                    {generateLabel('Package Description', 'quotationPackage')}
                                                    {isEditMode ? (
                                                        <Form.Control as="textarea" placeholder='Add package description' rows="1" {...register('quotationPackage')} />
                                                    ) : (
                                                        <p className="mb-0 bold-700 word-break">{quotationData?.packageDescription || ''}</p>
                                                    )}
                                                    
                                                    {errors.quotationPackage?.message && <small className="invalid-fields">{errors.quotationPackage.message}</small>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='align-items-start mb-3'>
                                            <Col md={4} className="mb-3">
                                                <Form.Group id="quotation-other-term-and-condition">
                                                    {generateLabel('Others', 'quotationOtherTermAndCondition')}
                                                    {isEditMode ? (
                                                        <Form.Control as="textarea" placeholder='Add other term and condition notes' rows="1" {...register('quotationOtherTermAndCondition')} />
                                                    ) : (
                                                        <p className="mb-0 bold-700 word-break">{quotationData?.otherNotesForTermsAndCondition || ''}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}
                    </Card.Body>
                    {createSalesQuotation && (
                        <Card.Footer>
                            {isEditMode && (
                                <Button type="submit" variant="secondary" className="me-1">
                                    Save Quotation
                                </Button>
                            )}
                            <Button variant="primary" className="ms-auto" onClick={() => handleCancelCreateSalesQuotation()}>
                                Close
                            </Button>
                        </Card.Footer>
                    )}
                </Form>
            </Card>
        </>
    );
};

export default SalesQuotation;
