import React from 'react';
import { Col, Row, Nav, Tab } from '@themesberg/react-bootstrap';
import ClientBillingTransactions from './clientbillingtransactions';
import ClientSalesQuotation from './clientsalesquotation';

const ClientTransactions = (props) => {

    const { clientId } = props;
    
    return (
        <Tab.Container defaultActiveKey="billingTransaction">
            <Row>
                <Col lg={12}>
                    <Nav className="nav-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="billingTransaction" className="mb-sm-3 mb-md-0">
                                Billing Transactions
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="salesQuotation" className="mb-sm-3 mb-md-0">
                                Sales Quotation
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col lg={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="billingTransaction" className="py-4">
                            <ClientBillingTransactions clientId={clientId} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="salesQuotation" className="py-4">
                            <ClientSalesQuotation clientId={clientId} />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default ClientTransactions;