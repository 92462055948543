import React, { useEffect, useCallback, useState } from 'react';
import { Card, Table } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setClientBillingCount } from '../actions/clientTransactions';
import { CANCELLED, defaultPageNumber } from '../utils/constants';
import clientApi from '../services/client-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { formatCurrency } from "../utils/formatter";
import PortalPagination from '../components/PortalPagination';

const ClientBillingTransactions = (props) => {
    const { clientId } = props;
    const dispatch = useDispatch();
    const { getBillingCount, billingTransactions } = clientApi;
    const totalRecords = useSelector((state) => state.clientTransactionState.billingCount) || 0;
    const [billingList, setBillingList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);

    const handlePageEvent = (pageNumber) => {
        handleFetchClientBillings(clientId, pageNumber);
    };

    const handleFetchClientBillings = useCallback(
        async (clientIdValue, pageNumber = 1) => {
          setSelectedPage(pageNumber);
    
          try {
            const countRes = await getBillingCount(clientIdValue);
    
            dispatch(setClientBillingCount(countRes.result));
    
            const listRes = await billingTransactions(clientIdValue);
    
            setBillingList(listRes.result);
          } catch (error) {
            console.error('Error while fetching client billing transactions', error);
          }
        },
        [getBillingCount, dispatch, billingTransactions],
    );

    useEffect(() => {
        getBillingCount(clientId).then((res) => {
          dispatch(setClientBillingCount(res.totalCount));
          billingTransactions(clientId, 1).then((res) => {
            setBillingList(res.results);
          });
        });
    }, [getBillingCount, dispatch, billingTransactions, clientId]);

    const TableRow = (document) => {
        const {
          sumOfCBM, 
          sumOfSensitiveCharge, 
          sumOfForklift, 
          sumOfInland, 
          sumOfGrossWeight,
          grossAmount,
          totalBillableAmount,
         } = document;

         const cancelReason = document.generatedBillingData.cancelReason;
         const status = document.generatedBillingData.status;
         let rate = 0;

         if(document.appliedRateData) rate = document.appliedRateData.rate;

         if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
          const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
          rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
        }

         const lowerCaseStatus = status.toLowerCase();

        const statusVariant = lowerCaseStatus === "approved" ? "success"
          : lowerCaseStatus === "pending" ? "warning"
            : lowerCaseStatus === "cancelled" ? "danger" : "primary";
    
        return (
            <tr>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(sumOfCBM)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(sumOfForklift)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(sumOfInland)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(sumOfGrossWeight)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(rate)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(grossAmount)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {loading ? (
                            <Skeleton wrapper={SkeletonWrapper} />
                        ) : (
                            formatCurrency(parseFloat(totalBillableAmount)) || 0.00
                        )}
                    </span>
                </td>
                <td>
                    {loading ? (
                        <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                        <span className={`fw-normal text-${statusVariant}`}>
                            {status}
                        </span>
                    )}
                </td>
                <td>
                    {status === CANCELLED && (
                        <span className="fw-normal">
                            {loading ? (
                                <Skeleton wrapper={SkeletonWrapper} />
                            ) : (
                                <div className="truncated-text" title={cancelReason}>
                                    {cancelReason.length > 10 ? cancelReason.substring(0, 10) + '...' : cancelReason}
                                </div>
                            )}
                        </span>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <Table className="billing-table-list align-items-center mt-3">
                        <thead>
                            <tr>
                                <th className="border-bottom">Sum of CBM</th>
                                <th className="border-bottom">Sum of Sensitive Charge</th>
                                <th className="border-bottom">Sum of Forklift</th>
                                <th className="border-bottom">Sum of Inland</th>
                                <th className="border-bottom">Sum of Gross Wt</th>
                                <th className="border-bottom">Rate</th>
                                <th className="border-bottom">Freight Amount</th>
                                <th className="border-bottom">Billable Amount</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingList && billingList.map((t, i) => <TableRow key={`approved-billing-${i}`} {...t} />)}
                            {billingList.length === 0 && (
                            <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {!loading && (
                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
            )}
        </>
        
    )
}

export default ClientBillingTransactions;