import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from '../actions/blanket';
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileAlt, faFileDownload, faListAlt } from "@fortawesome/free-solid-svg-icons";
import reportApi from '../services/report-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { formatCurrency } from "../utils/formatter";
import { generatePdfReportAndDownload } from '../pdfGenerator';
import CbmRateCountsPreview from "./cbmratecountspreview";
import CbmRateCountsSummary from "./cbmratecountssummary";
import CbmRateCountsSummaryPreview from "./cbmratecountssummarypreview";

const CbmRateCounts = () => {

    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();

    const dispatch = useDispatch();
    const { getCbmRateCounts } = reportApi;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [reportList, setReportList] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [overallTotalCBM, setOverallTotalCBM] = useState(0);
    const [overallTotalBillableAmount, setOverallTotalBillableAmount] = useState(0);
    const [reportView, setReportView] = useState('list');
    // const [groupedBillableAmounts, setGroupedBillableAmounts] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);

    const formSchema = Yup.object().shape({
        startDate: Yup.string().nullable().required('Start date is required')
            .test('start-date-test', 'Start date cannot be greater than end date', function(value) {
                const { endDate } = this.parent;
                return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
            }),
        endDate: Yup.string().nullable().required('End date is required')
            .test('end-date-test', 'End date cannot be earlier than start date', function(value) {
                const { startDate } = this.parent;
                return !value || !startDate || moment(value).isSameOrAfter(moment(startDate));
            }),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    };

    const { getValues, trigger, formState, setValue, reset } = useForm(formOptions);
    const { errors } = formState;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValue('startDate', date, { shouldValidate: true });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue('endDate', date, { shouldValidate: true });
    };

    const resetFilters = () => {
        reset({
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setReportList([]);
    };

    const groupByAppliedRate = (data, year, month) => {
        // Filter the data based on the specified year and month
        const filteredData = data.filter(item => {
            const itemYear = moment(item.createdDate).year();
            const itemMonth = moment(item.createdDate).month() + 1; // +1 because months are zero-based in moment.js
            return itemYear === year && itemMonth === month;
        });

        const hasResult = filteredData.length > 0;
    
        // Group the filtered data by appliedRate
        const grouped = filteredData.reduce((acc, item) => {
            const { appliedRate } = item;
            const existing = acc.find(entry => entry.appliedRate === appliedRate);
    
            if (existing) {
                existing.count += 1;
            } else {
                acc.push({ 
                    appliedRate, 
                    count: 1
                });
            }
    
            return acc;
        }, []);
    
        return { hasResult, grouped };
    };        
    

    const calculateSelectedMonths = (start, end) => {
        let months = [];
        let current = moment(start).startOf('month');

        while (current.isSameOrBefore(moment(end).startOf('month'))) {
            months.push({
                month: current.month() + 1, // +1 because moment months are zero-based (0 = January)
                label: current.format('MMMM')
            });
            current.add(1, 'month');
        }

        return months;
    };

    const calculateSelectedYears = (start, end) => {
        let years = [];
        let startYear = moment(start).year();
        let endYear = moment(end).year();

        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }

        return years;
    };

    const handleGenerateReport = async () => {
        try {
            const isValid = await trigger();

            if (!isValid) {
                return;
            }

            const { startDate, endDate } = getValues();

            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const response = await getCbmRateCounts(formattedStartDate, formattedEndDate);
            setOverallTotalCBM(response.totalCBM);
            setOverallTotalBillableAmount(response.totalBillableAmount);

            const responseData = response?.data || [];

            setReportList(responseData);

        } catch (error) {
            console.log("Error while generating rate counts report: ", error);
        }
    };

    const handleDownloadPdf = async () => {
        try {
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : 'end';
    
            const fileName = `rate_counts_report_${formattedStartDate}_${formattedEndDate}.pdf`;
            let pdfContent = document.getElementById('element-to-print-rate-counts-report').innerHTML;

            if(reportView === 'summary'){
                pdfContent = document.getElementById('element-to-print-rate-counts-summary-report').innerHTML;
            }
    
            await generatePdfReportAndDownload(pdfContent, fileName);

            setDownloading(false);
    
        } catch (error) {
            console.error('Error in downloading PDF:', error);
        }
    };

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.log("Error while generating rate counts report: ", error);
        }
    };

    const handleSelectReportView = (view) => {
        setReportView(view);
    }

    useEffect(() => {
        if (startDate && endDate) {
            const months = calculateSelectedMonths(startDate, endDate);
            setSelectedMonths(months);

            const years = calculateSelectedYears(startDate, endDate);
            setSelectedYears(years);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        dispatch(setPortalPage('Rate Counts (With Total CBM, Amount & Percentage)'));
    }, [dispatch]);

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <p className="mb-0">Rate Counts Report (With Total CBM, Amount & Percentage)</p>
                        </div>
                    </div>
                    <div className="table-settings mb-2">
                        <Row className="justify-content-between align-items-center">
                            <Col md={6}>
                                <Form.Group id="start-date">
                                    <Form.Label>Start Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="startDate"
                                                    value={startDate ? moment(startDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.startDate?.message && (
                                        <small className="invalid-fields">{errors.startDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group id="end-date">
                                    <Form.Label>End Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="endDate"
                                                    value={endDate ? moment(endDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.endDate?.message && (
                                        <small className="invalid-fields">{errors.endDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="table-settings mb-5">
                        <Row>
                            <Col md={12} className="d-flex justify-content-start">
                                <Button variant="primary" onClick={() => handleGenerateReport()}>
                                    Search
                                </Button>
                                <Button className="ms-2" variant="secondary" onClick={() => resetFilters()}>
                                    Reset
                                </Button>
                                {reportList.length > 0 && (
                                    <Button className="pull-right ms-2" variant="primary" type="button" onClick={() => handleDownloadReport()}>
                                        <FontAwesomeIcon icon={faFileDownload} /> {`${downloading ? 'Downloading...' : 'Download Report'}`}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                    {reportList.length > 0 && (
                        <div className="btn-toolbar mb-2 mb-md-0 result-view">
                            <ButtonGroup>
                                <Button className={`${reportView === 'list' ? 'active' : ''}`} variant="outline-primary" size="sm" onClick={() => handleSelectReportView('list')}>
                                    <FontAwesomeIcon icon={faListAlt} className="me-2" /> List
                                </Button>
                                <Button className={`${reportView === 'summary' ? 'active' : ''}`} variant="outline-primary" size="sm" onClick={() => handleSelectReportView('summary')}>
                                    <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Summary
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                    {reportView === 'list' && (
                        <>
                            <Table hover className="billing-table-list align-items-center mt-5">
                                <thead>
                                    <tr>
                                        <th className="border-bottom">Client Name</th>
                                        <th className="border-bottom">Total CBM</th>
                                        <th className="border-bottom">Gross Amount</th>
                                        <th className="border-bottom">Total Additional Amount</th>
                                        <th className="border-bottom">Total Billable Amount</th>
                                        <th className="border-bottom">CBM Percentage</th>
                                        <th className="border-bottom">Billable Amount Percentage</th>
                                        <th className="border-bottom">Applied Rate</th>
                                        <th className="border-bottom">Approved Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportList.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        item.clientDisplayName
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        parseFloat(item.totalCBM).toFixed(2)
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        formatCurrency(parseFloat(item.grossAmount)) || 0.00
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        formatCurrency(parseFloat(item.totalAdditionalAmount)) || 0.00
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        formatCurrency(parseFloat(item.totalBillableAmount)) || 0.00
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        item.cbmPercentage
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        item.amountPercentage
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        formatCurrency(parseFloat(item.appliedRate)) || 0.00
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        moment(item.createdDate).format('YYYY-MM-DD')
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {reportList.length > 0 && (
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <h4><strong>Overall Total CBM:</strong> {loading ? <Skeleton /> : `${parseFloat(overallTotalCBM).toFixed(2)}`}</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4><strong>Overall Total Billable Amount:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalBillableAmount)) || 0.00}`}</h4>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </>
                    )}
                    {reportView === 'summary' && (
                        <>
                            <CbmRateCountsSummary
                                selectedYears={selectedYears}
                                selectedMonths={selectedMonths}
                                reportList={reportList}
                                overallTotalBillableAmount={overallTotalBillableAmount}
                                groupByAppliedRate={groupByAppliedRate}
                            /> 
                            <div className='report-element-to-print' id='element-to-print-rate-counts-summary-report'>
                                <CbmRateCountsSummaryPreview 
                                    startDate={startDate} 
                                    endDate={endDate} 
                                    selectedYears={selectedYears}
                                    selectedMonths={selectedMonths}
                                    reportList={reportList}
                                    overallTotalBillableAmount={overallTotalBillableAmount}
                                    groupByAppliedRate={groupByAppliedRate}
                                />
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
            <div className='report-element-to-print' id='element-to-print-rate-counts-report'>
                <CbmRateCountsPreview 
                    startDate={startDate} 
                    endDate={endDate} 
                    reportList={reportList}
                    overallTotalCBM={overallTotalCBM}
                    overallTotalBillableAmount={overallTotalBillableAmount}
                />
            </div>
        </>
    );
};

export default CbmRateCounts;
