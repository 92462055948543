import React, { useState } from "react";
import { Card, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { faFilePdf, faEye, faFileDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';import BillingDetail from "./billingdetail";
import BillingPreview from "./billingpreview";
import { hasPermission } from "../auth/UserPermissionTypes";
import { BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING, BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING, BILLING_TRANSACTIONS_AND_HISTORY_VIEW_PREVIEW_BILLING } from "../utils/constants";
;


const BillingHolder = (props) => {
    const {
        hideEvent,
        billingDocumentData, 
        donwloadPdf,
        sendBillingDocument
    } = props;
    const [documentViewState, setDocumentViewState] = useState('Billing Details');

    return (
        <>
            <Card border="light" className="shadow-sm document-details">
                <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">{documentViewState}</h5>
                    <ButtonGroup>
                        <Button className={documentViewState === 'Billing Details' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Billing Details')}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Show Details
                        </Button>
                        {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_VIEW_PREVIEW_BILLING) && (
                            <Button className={documentViewState === 'Billing Preview' ? 'active-view' : ''} variant="outline-primary" size="sm" onClick={() => setDocumentViewState('Billing Preview')}>
                                <FontAwesomeIcon icon={faFilePdf} className="me-2" /> Preview
                            </Button>
                        )}
                        {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING) && donwloadPdf && (
                            <Button variant="outline-primary" size="sm" onClick={() => donwloadPdf(`element-to-print_${billingDocumentData._id}`, billingDocumentData.generatedBillingData._id)}>
                                <FontAwesomeIcon icon={faFileDownload} className="me-2" /> Download
                            </Button>
                        )}
                        {hasPermission(BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING) && sendBillingDocument && (
                            <Button variant="outline-primary" size="sm" onClick={() => sendBillingDocument(billingDocumentData._id)}>
                                <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send
                            </Button>
                        )}
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                {documentViewState === 'Billing Details' && (
                        <BillingDetail billingDocumentData={billingDocumentData} />
                    )}

                    {documentViewState === 'Billing Preview' && (
                        <BillingPreview billingDocumentData={billingDocumentData}/>
                    )}
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" className="ms-auto pull-right" onClick={() => hideEvent()}>
                        Hide
                    </Button>
                </Card.Footer>

                <div className='element-to-print' id={`element-to-print_${billingDocumentData._id}`}>
                    <BillingPreview billingDocumentData={billingDocumentData}/>
                </div>
            </Card>
        </>
    );
};

export default BillingHolder;
