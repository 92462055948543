import React, { useEffect, useState } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RateDatatable from './ratedatatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RateCategoryForm from './ratecategoryform';
import rateCategoryApi from '../services/rate-category-service';
import RateBracketForm from './ratebracketform';
import rateBracketApi from '../services/rate-bracket-service';
import { setRateBracketCount } from '../actions/ratebracket';
import { defaultPageNumber } from '../utils/constants';

function RateManagement() {
  const dispatch = useDispatch();
  const { getRateCategories, deleteRateCategory } = rateCategoryApi;
  const [showAddRateCategory, setShowAddRateCategory] = useState(false);
  const [rateCategories, setRateCategories] = useState([]);
  const [toEditRateCategory, setToEditRateCategory] = useState(null);
  const [showAddRateBracket, setShowAddRateBracket] = useState(false);
  const { getCount, list } = rateBracketApi;
  const [rateBracketList, setRateBracketList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
  const [toEditRateBracket, setToEditRateBracket] = useState(null);

  const handleCancelRateCategory = () => {
    setShowAddRateCategory(false);
    setToEditRateCategory(null);
  }

  const handleAddRateCategory = () => {
    setShowAddRateCategory(true);
    setToEditRateCategory(null);
  }

  const handleEditRateCategory = (rateCategory) => {
    setShowAddRateCategory(true);
    setToEditRateCategory(rateCategory);
  }

  const handleDeleteRateCategory = (id) => {
    deleteRateCategory(id).then(_ => {
      handleRefreshList();
    })
    .catch((e) => console.log(e))
  }

  const handleRefreshList = () => {
    getRateCategories().then((data) => {
      setRateCategories(data)
    })
    .catch(e => console.log(e))
  }

  const handleAddRateBracket = () => {
    setShowAddRateBracket(true);
  }

  const handleCancelRateBracket = () => {
    setShowAddRateBracket(false);
  }

  const handlePageEvent = (pageNumber) => {
    setSelectedPage(pageNumber);
    handlePageDataFetch(pageNumber);
  };

  const handlRefreshRateBracket = () => {
    setSelectedPage(1);
    handlePageDataFetch(1);
  }

  const handlePageDataFetch = (pageNumber) => {
    getCount().then((res) => {
      dispatch(setRateBracketCount(res.result));
      list(pageNumber).then((res) => {
        setRateBracketList(res.result);
      });
    });
  }

  const handleToEditRateBracket = (rateBracket) => {
    setToEditRateBracket(rateBracket);
    setShowAddRateBracket(true);
  }

  useEffect(() => {
    dispatch(setPortalPage('Rate Management'));
  }, [dispatch]);

  useEffect(() => {
    getRateCategories().then((data) => {
      setRateCategories(data)
    })
    .catch(e => console.log(e))
  }, [getRateCategories])

  useEffect(() => {
    getCount().then((res) => {
      dispatch(setRateBracketCount(res.result));
      list(1).then((res) => {
        setRateBracketList(res.result);
      });
    });
  }, [getCount, dispatch, list]);

  return (
    <>
      {showAddRateCategory && (
        <RateCategoryForm rateCategoryData={toEditRateCategory} cancelEdit={handleCancelRateCategory} refreshList={handleRefreshList} />
      )}

      {showAddRateBracket && (
        <RateBracketForm rateBracketData={toEditRateBracket} cancelEdit={handleCancelRateBracket} refreshList={handlRefreshRateBracket} />
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0 search-block-width-400">
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={() => handleAddRateBracket()}>
              <FontAwesomeIcon icon={faPlus} className="me-2" /> New
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Card border="light" className="shadow-sm no-border-radius">
        <RateDatatable 
          selectedPage={selectedPage}
          pagingEvent={handlePageEvent}
          rateBracketList={rateBracketList}
          rateCategories={rateCategories} 
          addCategoryEvent={handleAddRateCategory} 
          handleEditRateCategory={handleEditRateCategory} 
          deleteRateCategory={handleDeleteRateCategory}
          setToEditRateBracket={handleToEditRateBracket}
          refreshList={handlRefreshRateBracket}
        />
      </Card>
    </>
  );
}

export default RateManagement;
