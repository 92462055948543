import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Form } from '@themesberg/react-bootstrap';
import notificationChannelApi from '../services/notification-channel-service';
import clientApi from '../services/client-service';
import { showError, showOk } from "../components/Alerts";

const ClientChannels = (props) => {
    const {clientId, notificationChannelId, isEditMode} = props;
    const { getNotificationChannels } = notificationChannelApi;
    const { clientSetNotificationChannel } = clientApi;

    const [notificationChannels, setNotificationChannels] = useState([]);
    const [clientNotificationChannel, setClientNotificationChannel] = useState(notificationChannelId);

    const getNotificationIcon = (channel) => {
        if(channel === 'SMS') return faMobileAlt;
        return faEnvelope;
    }

    const handleSetNotificationChannel = (notificationId) => {
        setClientNotificationChannel(notificationId);

        const formData = new FormData();
        formData.append('clientId', clientId);
        formData.append('notificationChannelId', notificationId);

        clientSetNotificationChannel(formData)
            .then(() => {
                showOk('Client notification channel updated.');
            })
            .catch((err) => {
                console.log("error: ", err);

                if(err && err.data && err.data.error){
                    showError(err.data.error)
                }
            });
    }

    useEffect(() => {
        getNotificationChannels()
        .then(data => setNotificationChannels(data))
        .catch((e) => console.log(e));
    },[getNotificationChannels, setNotificationChannels]);

    return (
        <Card border="light" className="shadow-sm">
            <Card.Body>
                <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                    <div>
                        <h5>Notification Channels</h5>
                    </div>
                </div>
                {notificationChannels.map((item, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
                        <div>
                            <h6 className="mb-0">
                                <FontAwesomeIcon 
                                    icon={getNotificationIcon(item.channelName)} 
                                className="icon icon-xs me-3 text-purple" /> {item.channelDescription}
                            </h6>
                        </div>
                        <div>
                            {isEditMode ? (
                                <Form.Group id="notify-email-check">
                                    <Form.Check
                                        defaultChecked={clientNotificationChannel === item._id}
                                        type="radio"
                                        name="client-notification-channel"
                                        id={`${item._id}_notification_channel`}
                                        htmlFor={`${item._id}_notification_channel`}
                                        onClick={() => handleSetNotificationChannel(item._id)}
                                    />
                                </Form.Group>
                            ) : (
                                <>
                                    {clientNotificationChannel === item._id && (
                                        <h6 className="fw-normal text-gray">
                                            <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs text-success w-20 me-1 mt-2" />
                                        </h6>
                                    )}
                                </>
                                
                            )}
                        </div>
                    </div>
                ))}
            </Card.Body>
        </Card>
    )
}

export default ClientChannels;