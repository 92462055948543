import { hasPermission } from "../auth/UserPermissionTypes";
import { 
    CANCELLED, 
    CLIENT_NO_RESPONSE, 
    FINAL_QUOTATION, 
    PENDING_CLIENT_SIGNATURE_QUOTATION, 
    PENDING_GENERAL_MANAGER_APPROVAL, 
    PROFORMA_INVOICE, QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER, 
    SIGNED_QUOTATION, QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER,
    QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER 
} from "./constants";

export const checkQuotationStatusChange = (status) => {
    return (status === PROFORMA_INVOICE && hasPermission(QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER))
     || (status === PENDING_GENERAL_MANAGER_APPROVAL && hasPermission(QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER)) 
     || (status === FINAL_QUOTATION && hasPermission(QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER));
}

export const checkQuotationForPreview = (status) => {
    return status === FINAL_QUOTATION 
    || status === PENDING_CLIENT_SIGNATURE_QUOTATION 
    || status === SIGNED_QUOTATION 
    || status === CLIENT_NO_RESPONSE;
}

export const checkQuotationForDownload = (status) => {
    return status === PENDING_CLIENT_SIGNATURE_QUOTATION || status === SIGNED_QUOTATION || status === CLIENT_NO_RESPONSE;
}

export const checkQuotationShowCancel = (status) => {
    return status !== SIGNED_QUOTATION && status !== CANCELLED;
}