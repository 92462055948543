import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserLock, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';

// import NOTIFICATIONS_DATA from './SampleNotification.js';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signoutUser } from '../auth';
import { isAuthenticated } from '../auth';

const AdminNavbar = ({ history }) => {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);


  const portalPage = useSelector((state) => state.blanket.portalPage);

  // const Notification = (props) => {
  //   const { link, sender, image, time, message, read = false } = props;
  //   const readClassName = read ? '' : 'text-danger';

  //   return (
  //     <ListGroup.Item action href={link} className="border-bottom border-light">
  //       <Row className="align-items-center">
  //         <Col className="col-auto">
  //           <Image src={image} className="user-avatar lg-avatar rounded-circle" />
  //         </Col>
  //         <Col className="ps-0 ms--2">
  //           <div className="d-flex justify-content-between align-items-center">
  //             <div>
  //               <h4 className="h6 mb-0 text-small">{sender}</h4>
  //             </div>
  //             <div className="text-end">
  //               <small className={readClassName}>{time}</small>
  //             </div>
  //           </div>
  //           <p className="font-small mt-1 mb-0">{message}</p>
  //         </Col>
  //       </Row>
  //     </ListGroup.Item>
  //   );
  // };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 mb-2">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <h4>{portalPage}</h4>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={
                      isAuthenticated().user.photoUrl
                        ? isAuthenticated().user.photoUrl
                        : process.env.REACT_APP_DEFAULT_IMAGE
                    }
                    alt={isAuthenticated().user.name}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{isAuthenticated().user.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={() => history.push('/myboard/profile')}>
                  <FontAwesomeIcon icon={faUserCircle} className="text-success me-2" /> Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={() => history.push('/myboard/update-password')}>
                  <FontAwesomeIcon icon={faUserLock} className="text-warning me-2" /> Update Password
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={() => signoutUser(() => history.push('/'))}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default withRouter(AdminNavbar);
