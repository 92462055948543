import { ALL, BILLING_TRANSACTIONS_AND_HISTORY, CLIENT_MANAGER, JWT_TOKEN, MEMBER, PROCESS_BILLING_MANAGER, RATE_MANAGER, SUPER_ADMIN, USER_MANAGER } from '../utils/constants';
import { jwtDecode } from 'jwt-decode';

export const isSuperAdmin = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem(JWT_TOKEN)) {
    const token = localStorage.getItem(JWT_TOKEN);

    const decoded = jwtDecode(token);

    const userRoles = decoded.roles;

    return userRoles.includes(SUPER_ADMIN.toLocaleLowerCase());
  } else {
    return false;
  }
};

export const isMember = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem(JWT_TOKEN)) {
    const token = localStorage.getItem(JWT_TOKEN);

    const decoded = jwtDecode(token);

    const userRoles = decoded.roles;

    return userRoles.includes(MEMBER.toLocaleLowerCase());
  } else {
    return false;
  }
};

export const hasRoles = (roles) => {
  // Check if the code is running in a browser environment
  if (typeof window === 'undefined') {
    return false;
  }

  if(isSuperAdmin()) return true;

  if (!roles) return false;

  if (roles.length === 1 && roles[0] === ALL) return true;

  const jwtToken = localStorage.getItem(JWT_TOKEN);

  // Check if JWT_TOKEN exists in localStorage
  if (jwtToken) {
    const token = localStorage.getItem(JWT_TOKEN);

    const decoded = jwtDecode(token);

    // Extract user roles from session data
    const userRoles = decoded.roles.split(',');

    const lowerCaseRoles = roles.map((item) => item?.toLocaleLowerCase());

    return userRoles.some((item) => lowerCaseRoles.includes(item?.toLocaleLowerCase()));
  } else {
    return false;
  }
};

export const hasPermission = (permission) => {
  // Check if the code is running in a browser environment
  if (typeof window === 'undefined') {
    return false;
  }

  if (isSuperAdmin()) {
    return true;
  }

  if (!permission) {
    return false;
  }

  const jwtToken = localStorage.getItem(JWT_TOKEN);

  if (jwtToken) {
    const decoded = jwtDecode(jwtToken);

    // Extract user permissions from the decoded token
    const userPermissions = decoded.permissions ? decoded.permissions?.split(',') : [];

    return userPermissions.includes(permission.toLocaleLowerCase());
  } else {
    return false;
  }
};

export const hasPermissions = (permissions) => {
  // Check if the code is running in a browser environment
  if (typeof window === 'undefined') {
    return false;
  }

  if (isSuperAdmin()) return true;

  // Check if permissions array exists and does not contain only undefined values
  if (!permissions || permissions.length === 0 || permissions.every(item => item == undefined)) {
    return false;
  }

  const jwtToken = localStorage.getItem(JWT_TOKEN);

  // Check if JWT_TOKEN exists in localStorage
  if (jwtToken) {
    const token = localStorage.getItem(JWT_TOKEN);
    const decoded = jwtDecode(token);

    // Extract user permissions from session data
    const userPermissions = decoded.permissions ? decoded.permissions?.split(',') : [];

    // Convert permissions to lowercase
    const lowerCasePermissions = permissions.map((item) => item?.toLocaleLowerCase());

    // Check if user has any of the required permissions
    return userPermissions.some((item) => lowerCasePermissions.includes(item?.toLocaleLowerCase()));
  } else {
    return false;
  }
};

export const regularUserOnly = () => {
  const jwtToken = localStorage.getItem(JWT_TOKEN);

  // Check if JWT_TOKEN exists in localStorage
  if (jwtToken) {
    const token = localStorage.getItem(JWT_TOKEN);

    const decoded = jwtDecode(token);

    //Admin Roles
    const adminRoles = [USER_MANAGER, CLIENT_MANAGER, RATE_MANAGER, PROCESS_BILLING_MANAGER, BILLING_TRANSACTIONS_AND_HISTORY]

    // Extract user roles from session data
    const userRoles = decoded.roles.split(',');

    const lowerCaseRoles = adminRoles.map((item) => item.toLocaleLowerCase());

    const isAdmin = userRoles.some(userRole => lowerCaseRoles.includes(userRole.toLocaleLowerCase()));

    return !isAdmin;

  } else {
    return false;
  }
}

export const getUserCompanyId = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem(JWT_TOKEN)) {
    const token = localStorage.getItem(JWT_TOKEN);

    const decoded = jwtDecode(token);

    return decoded.userCompanyId;
  } else {
    return false;
  }
};
