import React, { useEffect } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { CounterWidget } from '../components/Widgets';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import authApi from '../services/auth-api-service';

function Dashboard() {
  const dispatch = useDispatch();

  const { checkToken } = authApi;

  useEffect(() => {
    checkToken().then(() => {
      dispatch(setPortalPage('Dashboard'));
    });
  }, [dispatch, checkToken]);

  return (
    <>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={6} className="mb-4">
          <CounterWidget
            category="For Review Billings"
            title="2"
            period="Requested By: John Wick"
            icon={faFileExcel}
            iconColor="shape-tertiary"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="mb-4">
          <CounterWidget
            category="Approved Billings"
            title="5"
            period="Approved By: John Wick"
            icon={faFileExcel}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
