import { 
    SALES_AGENT_TRANSACTION_REPORT_COUNT, 
    SALES_AGENT_TRANSACTION_REPORT_TOTAL_PAGES,
    TRANSACTION_PER_LOADING_REPORT_COUNT,
    TRANSACTION_PER_LOADING_REPORT_TOTAL_PAGE
} from './types';

export const setSalesAgentTransactionReportCount = (count) => (dispatch) => {
    try {
      const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  
      const totalPages = Math.ceil(count / limit);
  
      dispatch({
        type: SALES_AGENT_TRANSACTION_REPORT_COUNT,
        payload: count,
      });
  
      dispatch(setSalesAgentTransactionReportTotalPages(totalPages));
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
};

export const setSalesAgentTransactionReportTotalPages = (totalPages) => (dispatch) => {
    try {
      dispatch({
        type: SALES_AGENT_TRANSACTION_REPORT_TOTAL_PAGES,
        payload: totalPages,
      });
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
};

export const setTransactionsPerLoadingReportCount = (count) => (dispatch) => {
  try {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;

    const totalPages = Math.ceil(count / limit);

    dispatch({
      type: TRANSACTION_PER_LOADING_REPORT_COUNT,
      payload: count,
    });

    dispatch(setTransactionsPerLoadingReportTotalPages(totalPages));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setTransactionsPerLoadingReportTotalPages = (totalPages) => (dispatch) => {
  try {
    dispatch({
      type: TRANSACTION_PER_LOADING_REPORT_TOTAL_PAGE,
      payload: totalPages,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};