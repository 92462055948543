import Dashboard from '../core/Dashboard';
import Client from '../client';
import Rates from '../rates';
import AccessManagement from '../accessmanagement';
import BillingProcessor from '../billingprocessor';
import BillingList from '../billinglist';
import UserManager from '../user/management';
import ProductManager from '../products';
import SalesQuotation from '../sales/salesquotation';
import SalesOrderList from '../sales/salesorderlist';
import SalesAgentTransactions from '../reports/salesagenttransactions';
import CommissionCalculation from '../reports/commissioncalculation';
import RateCounts from '../reports/cbmratecounts';
import FlcTransactions from '../reports/flctransactions';
import SourcingTransactions from '../reports/sourcingtransactions';
import TransactionsPerLoading from '../reports/transactionsperloading';
import ContainerCategory from '../reports/containercategory';
import ClientPercentage from '../reports/clientpercentage';

import {
  faChartPie,
  faFileAlt,
  faUser,
  faUserFriends,
  faFileExcel,
  faLock,
  faCoins,
  faCogs,
  faListAlt,
  faShieldAlt,
  faHistory,
  faTags,
  faChartLine,
  faFileInvoice,
  faClipboardList,
  faChartBar,
  faMoneyBill,
  faExchangeAlt,
  faBox,
  faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons';
import { ALL, PROCESS_BILLING_MANAGER, CLIENT_MANAGER, RATE_MANAGER, SUPER_ADMIN, USER_MANAGER, BILLING_TRANSACTIONS_AND_HISTORY, PRODUCT_MANAGER, SALES_QUOTATION, SALES_QUOTATION_LIST_AND_HISTORY, REPORTS_SALES_AGENT_TRANSACTIONS, REPORTS_COMMISSION_CALCULATION, REPORTS_RATE_COUNTS, REPORTS_LIST_OF_FCL_TRANSACTIONS, REPORTS_LIST_OF_SOURCING_TRANSACTIONS, REPORTS_TRANSACTIONS_PER_LOADING, REPORTS_LIST_OF_CONTAINER_CATEGORY, REPORTS_CLIENT_PERCENTAGE, REPORTS } from '../utils/constants';
import UpdatePassword from '../user/updatepassword';
import Profile from '../user/Profile';
import AuditManager from '../auditmanager';

const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboards',
    component: Dashboard,
    layout: '/myboard',
    icon: faChartPie,
    roles: [SUPER_ADMIN],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    layout: '/myboard',
    roles: [ALL],
    hidden: true,
  },
  {
    path: '/update-password',
    name: 'Update Password',
    component: UpdatePassword,
    layout: '/myboard',
    roles: [ALL],
    hidden: true,
  },
  {
    collapse: true,
    name: 'Data Management',
    icon: faFileAlt,
    state: 'data-management',
    views: [
      {
        path: '/data-management/usermanager',
        name: 'User Manager',
        component: UserManager,
        layout: '/myboard',
        icon: faUser,
        roles: [SUPER_ADMIN, USER_MANAGER],
      },
      {
        path: '/data-management/clientmanager',
        name: 'Client Manager',
        component: Client,
        layout: '/myboard',
        icon: faUserFriends,
        roles: [SUPER_ADMIN, CLIENT_MANAGER],
      },
      {
        path: '/data-management/ratemanager',
        name: 'Rates',
        component: Rates,
        layout: '/myboard',
        icon: faCoins,
        roles: [SUPER_ADMIN, RATE_MANAGER],
      },
      // {
      //   path: '/data-management/paymentermsmanager',
      //   name: 'Payment Terms',
      //   component: PaymentTerms,
      //   layout: '/myboard',
      //   icon: faCalendarCheck,
      // },
    ],
  },
  {
    collapse: true,
    name: 'Security',
    icon: faShieldAlt,
    state: 'security',
    views: [
      {
        path: '/security/accessmanagement',
        name: 'Access Management',
        component: AccessManagement,
        layout: '/myboard',
        icon: faLock,
        roles: [SUPER_ADMIN],
      },
    ],
  },
  {
    collapse: true,
    name: 'Billing Management',
    icon: faFileExcel,
    state: 'billing-management',
    views: [
      {
        path: '/billing-management/billing-processor',
        name: 'Process Billing',
        component: BillingProcessor,
        layout: '/myboard',
        icon: faCogs,
        roles: [SUPER_ADMIN, PROCESS_BILLING_MANAGER],
      },
      {
        path: '/billing-management/billing-list',
        name: 'Billing Lists',
        component: BillingList,
        layout: '/myboard',
        icon: faListAlt,
        roles: [SUPER_ADMIN, BILLING_TRANSACTIONS_AND_HISTORY],
      },
    ],
  },
  {
    path: '/audit/manager',
    name: 'Audit Logs',
    component: AuditManager,
    layout: '/myboard',
    icon: faHistory,
    roles: [SUPER_ADMIN],
  },
  {
    path: '/product/manager',
    name: 'Product Manager',
    component: ProductManager,
    layout: '/myboard',
    icon: faTags,
    roles: [PRODUCT_MANAGER],
  },
  {
    collapse: true,
    name: 'Sales Management',
    icon: faChartLine,
    state: 'sales-management',
    views: [
      {
        path: '/sales-management/sales-quotation',
        name: 'Sales Quotation',
        component: SalesQuotation,
        layout: '/myboard',
        icon: faFileInvoice,
        roles: [SUPER_ADMIN, SALES_QUOTATION]
      },
      {
        path: '/sales-management/sale-order-list',
        name: 'Sales Quotation Lists',
        component: SalesOrderList,
        layout: '/myboard',
        icon: faClipboardList,
        roles: [SUPER_ADMIN, SALES_QUOTATION_LIST_AND_HISTORY]
      },
    ],
  },
  {
    collapse: true,
    name: 'Reports',
    icon: faChartBar,
    state: 'reports',
    views: [
      {
        path: '/reports/sales-agent-transactions',
        name: 'Sales Agent Transactions',
        component: SalesAgentTransactions,
        layout: '/myboard',
        icon: faChartLine,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_SALES_AGENT_TRANSACTIONS]
      },
      {
        path: '/reports/commission-calculation',
        name: 'Commission Calculation',
        component: CommissionCalculation,
        layout: '/myboard',
        icon: faMoneyBill,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_COMMISSION_CALCULATION]
      },
      {
        path: '/reports/rate-counts',
        name: 'Rate Counts',
        component: RateCounts,
        layout: '/myboard',
        icon: faFileAlt,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_RATE_COUNTS]
      },
      // This report is dependent in accounting system to generate the actual report
      // {
      //   path: '/reports/cost-matching-revenue',
      //   name: 'Cost Matching Revenue',
      //   component: CostMatchingRevenue,
      //   layout: '/myboard',
      //   icon: faCoins,
      //   roles: [SUPER_ADMIN]
      // },
      {
        path: '/reports/flc-transactions',
        name: 'List of FCL Transactions',
        component: FlcTransactions,
        layout: '/myboard',
        icon: faListAlt,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_LIST_OF_FCL_TRANSACTIONS]
      },
      {
        path: '/reports/sourcing-transactions',
        name: 'List of Sourcing Transactions',
        component: SourcingTransactions,
        layout: '/myboard',
        icon: faListAlt,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_LIST_OF_SOURCING_TRANSACTIONS]
      },
      {
        path: '/reports/transactions-per-loading',
        name: 'Transactions Per Loading',
        component: TransactionsPerLoading,
        layout: '/myboard',
        icon: faExchangeAlt,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_TRANSACTIONS_PER_LOADING]
      },
      {
        path: '/reports/container-category',
        name: 'List of Container Category',
        component: ContainerCategory,
        layout: '/myboard',
        icon: faBox,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_LIST_OF_CONTAINER_CATEGORY]
      },
      {
        path: '/reports/client-percentage',
        name: 'Client Percentage',
        component: ClientPercentage,
        layout: '/myboard',
        icon: faPeopleArrows,
        roles: [SUPER_ADMIN, REPORTS],
        permissions: [REPORTS_CLIENT_PERCENTAGE]
      },
    ],
  },
];

export default adminRoutes;
