import { 
    AUDIT_LOGS_COUNT, 
    AUDIT_LOGS_TOTAL_PAGES
} from './types';

  export const setAuditLogsCount = (count) => (dispatch) => {
    try {
      const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  
      const totalPages = Math.ceil(count / limit);
  
      dispatch({
        type: AUDIT_LOGS_COUNT,
        payload: count,
      });
  
      dispatch(setAuditLogsTotalPages(totalPages));
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const setAuditLogsTotalPages = (totalPages) => (dispatch) => {
    try {
      dispatch({
        type: AUDIT_LOGS_TOTAL_PAGES,
        payload: totalPages,
      });
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };