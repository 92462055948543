import { CLIENT_COUNT, CLIENT_TOTAL_PAGES } from '../actions/types';

const initialState = {
  count: 0,
  totalPages: 0,
};

const clientReducer = (clientState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_COUNT:
      return { ...clientState, count: payload };
    case CLIENT_TOTAL_PAGES:
      return { ...clientState, totalPages: payload };
    default:
      return clientState;
  }
};

export default clientReducer;
