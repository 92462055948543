import { requests } from './api-service';

const reportApi = {
    getTransactionsHandledByAgentCount: (agentId, status, startDate, endDate) => 
        requests.get('transactions-handled-by-sales-agent/count', setTransactionsHandledByAgentCountParams(agentId, status, startDate, endDate)),
    getTransactionsHandledByAgentList: (pageNumber, agentId, status, startDate, endDate) => 
        requests.get('transactions-handled-by-sales-agent', setTransactionsHandledByAgentParams(agentId, status, startDate, endDate, pageNumber)),
    getAllTransactionsHandledByAgentList: (agentId, status, startDate, endDate) => 
        requests.get('all-transactions-handled-by-sales-agent', setTransactionsHandledByAgentCountParams(agentId, status, startDate, endDate)),
    getCommissionCalculation: (startDate, endDate) => 
        requests.get('commission-calculation', setDateParams(startDate, endDate)),
    getCbmRateCounts: (startDate, endDate) => 
        requests.get('cbm-rate-counts', setDateParams(startDate, endDate)),
    getFclTransactions: (startDate, endDate, shipmentType) => 
        requests.get('fcl-transactions', setShipmentAndDatesParams(startDate, endDate, shipmentType)),
    getTransactionsPerLoadingCount: (containerNumber, startDate, endDate) => 
        requests.get('transactions-per-loading/count', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
    getTransactionsPerLoadingList: (pageNumber, containerNumber, startDate, endDate) => 
        requests.get('transactions-per-loading/list', setTransactionsPerLoadingListParams(containerNumber, startDate, endDate, pageNumber)),
    getAllTransactionsPerLoadingList: (containerNumber, startDate, endDate) => 
        requests.get('all-transactions-per-loading/list', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
    getContainerReportByLoadingDate: (startDate, endDate) => 
        requests.get('container-category-by-loading-date', setDateParams(startDate, endDate)),
    getClientPercentageByLoadingDate: (containerNumber, startDate, endDate) => 
        requests.get('client-percentage-by-loading-date', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
};

export const setTransactionsHandledByAgentCountParams = (agentId, status, startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('agentId', agentId.toString());
    params.append('status', status.toString());
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    
    return params;
};

export const setTransactionsHandledByAgentParams = (agentId, status, startDate, endDate, pageNumber = 1) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('agentId', agentId.toString());
    params.append('status', status.toString());
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    return params;
};

export const setDateParams = (startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('startDate', startDate.toString());
    params.append('endDate', endDate.toString());
    
    return params;
};

export const setShipmentAndDatesParams = (startDate, endDate, shipmentType) => {
    const params = new URLSearchParams();
    params.append('startDate', startDate.toString());
    params.append('endDate', endDate.toString());
    params.append('shipmentType', shipmentType);

    return params;
};

export const setTransactionsPerLoadingCountParams = (containerNumber, startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('containerNumber', containerNumber);
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    
    return params;
};

export const setTransactionsPerLoadingListParams = (containerNumber, startDate, endDate, pageNumber = 1) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('containerNumber', containerNumber);
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    return params;
};

export default reportApi;