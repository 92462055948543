import { 
    PRODUCT_COUNT, 
    PRODUCT_TOTAL_PAGES
} from './types';

  export const setProductsCount = (count) => (dispatch) => {
    try {
      const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  
      const totalPages = Math.ceil(count / limit);
  
      dispatch({
        type: PRODUCT_COUNT,
        payload: count,
      });
  
      dispatch(setProductsTotalPages(totalPages));
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const setProductsTotalPages = (totalPages) => (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_TOTAL_PAGES,
        payload: totalPages,
      });
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };