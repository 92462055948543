import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { faBoxes, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Row, Col, InputGroup, Form, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductDatatable from "./productdatatable";
import ProductForm from "./productform";
import ProductCategory from "./productcategory";
import productCategoryApi from '../services/product-category-services';
import { defaultPageNumber, PRODUCT_MANAGER_ADD_PRODUCT, PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY } from "../utils/constants";
import productApi from '../services/product-service';
import { setProductsCount } from "../actions/product";
import { hasPermission } from "../auth/UserPermissionTypes";

const ProductManager = () => {
    const dispatch = useDispatch();

    const { getProductCategoryNames } = productCategoryApi;
    const [searchTerm, setSearchTerm] = useState('');
    const [showProductCategory, setShowProductCategory] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [showProductForm, setShowProductForm] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);

    const { getCount, list } = productApi;

    const cancelProductCategory = () => setShowProductCategory(!showProductCategory);

    const fetchProductCategorNames = useCallback(async () => {
        try {
            const result = await getProductCategoryNames();
            setProductCategories(result);
        } catch (error) {
            console.log(error);
        }
    }, [getProductCategoryNames]);

    const hideProductForm = () => setShowProductForm(false);

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value || '');
    };

    const onkeyPressSearch = (e) => {
        if (e.key === 'Enter') {
            onSearchProducts();
        }
    };

    const handleFilterByCategory = (e) => {
        const selectedCategory = e.target.value;
        fetchProductList(1, searchTerm, selectedCategory);
    };

    const fetchProductList = useCallback(
        async (pageNumber = 1, searchValue = '', category = '') => {
            try {
                setSelectedPage(pageNumber);

                const countRes = await getCount(searchValue, category);

                dispatch(setProductsCount(countRes.totalProducts));
        
                const listRes = await list(pageNumber, searchValue, category);
                
                setProductList(listRes.products);
            } 
            catch (error) {
                console.error('Error while searching products:', error);
            }
    }, [getCount, list, setProductList, dispatch]);

    const handlePageEvent = (pageNumber) => {
        fetchProductList(pageNumber, searchTerm);
    }; 

    const onSearchProducts = async () => {
        fetchProductList(1, searchTerm)
    };

    useEffect(() => {
        dispatch(setPortalPage('Product Manager'));
    }, [dispatch]);

    useEffect(() => {
        fetchProductCategorNames();
    }, [fetchProductCategorNames]);

    useEffect(() => {
        fetchProductList();
    }, [fetchProductList]);

    return (
        <>
            {showProductCategory && (
                <ProductCategory 
                    cancelProductCategory={cancelProductCategory} 
                    refreshCategoryList={fetchProductCategorNames}
                    refreshList={fetchProductList}
                />
            )}
            
            <Card border="light" className="shadow-sm no-border-radius">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <Row className='align-items-start search-and-filter-content'>
                            <Col md={6} className="mb-3">
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        onKeyPress={(e) => onkeyPressSearch(e)}
                                        value={searchTerm}
                                        onChange={onSearchChange}
                                        placeholder="Search"
                                    />
                                    <Button size="sm" onClick={() => onSearchProducts()}>Search</Button>
                                </InputGroup>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group id="filterByCategory">
                                    <Form.Select 
                                        name="filterByCategory" 
                                        defaultValue="" 
                                        onChange={handleFilterByCategory}
                                    >
                                        <option value="">Filter Category</option>
                                        {productCategories.map((item, i) => (
                                            <option key={i} value={item._id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <ButtonGroup>
                                {hasPermission(PRODUCT_MANAGER_ADD_PRODUCT) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => setShowProductForm(true)}>
                                        <FontAwesomeIcon icon={faPlus} className="me-2" /> New Product
                                    </Button>
                                )}
                                {hasPermission(PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY) && (
                                    <Button variant="outline-primary" size="sm" onClick={() => setShowProductCategory(true)}>
                                        <FontAwesomeIcon icon={faBoxes} className="me-2" /> Product Categories
                                    </Button>
                                )}
                            </ButtonGroup>
                        </div>
                    </div>
                    {showProductForm && (
                        <ProductForm 
                            productCategories={productCategories} 
                            cancelEdit={hideProductForm} 
                            refreshList={fetchProductList}
                        />
                    )}
                </Card.Body>
                <ProductDatatable 
                    productList={productList} 
                    productCategories={productCategories} 
                    refreshList={fetchProductList}
                    selectedPage={selectedPage}
                    pagingEvent={handlePageEvent}
                />
            </Card>
        </>
    )
}

export default ProductManager;
