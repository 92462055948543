import React, { useCallback, useEffect, useState } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import { Card, Row, Col, Form, Button } from '@themesberg/react-bootstrap';
import roleApi from '../services/roles-service';
import { confirmArchiveDialog, showError, showOk } from '../components/Alerts';
import './styles.scss'

const UserAccessManagement = (props) => {
    const { userId, handleClose } = props;
    const [rolesAndPermissions, setRolesAndPermissions] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);
    const [isUserRolesAndPermissionsLoaded, setIsUserRolesAndPermissionsLoaded] = useState(false);
    const dispatch = useDispatch();

    const { 
        getRolesAndPermissions, 
        saveUserRole, 
        deleteUserRole, 
        saveUserPermission,
        deleteUserPermission,
        setMultipleUserPermission,
        userRolesAndPermissions
     } = roleApi;

    useEffect(() => {
        dispatch(setPortalPage('Access Management'));
    }, [dispatch]);

    const handleAddUserRole = async (roleId) => {
        const role = rolesAndPermissions.find((r) => r._id === roleId);
        const rolePermissions = role ? role.permissions.map((perm) => perm._id) : [];

        const foundPermissions = rolePermissions.filter((permId) => userPermissions.includes(permId));

        if (userRoles.includes(roleId)) {
            let unloadMessage = "Role unassigned to user";
            if (foundPermissions.length > 0) {
                const text = 'Unassigning this role will unassign all associated permissions as well. Do you want to continue?';
                const confirmBtnText = 'Yes, Unassign it!';
                const callbackTitle = 'Unassigned!';
                const callbackText = 'Role and permissions unassigned from user!';

                const confirmed = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
                if (!confirmed) {
                    return; 
                } else {
                    unloadMessage = callbackText;
                    const updatedPermissions = userPermissions.filter((permId) => !foundPermissions.includes(permId));
                    setUserPermissions(updatedPermissions);
                    await setMultiplePermissionsToUser(userId, updatedPermissions);
                }
            }

            unloadUserRole(userId, roleId, unloadMessage);
            setUserRoles((prevUserRoles) => prevUserRoles.filter((id) => id !== roleId));
        } else {
            addUserRole(userId, roleId);
            setUserRoles((prevUserRoles) => [...prevUserRoles, roleId]);
        }
    };

    const addUserRole = (userId, roleId) =>{
        const userRoleData = {
            userId,
            roleId
        }

        saveUserRole(userRoleData).then(() => {
            showOk("Role assigned to user");
        })
        .catch((e) => {
            console.log(e);
            showError(e?.data?.error)
        })
    }

    const unloadUserRole = (userId, roleId, unloadMessage) =>{
        const userRoleData = {
            userId,
            roleId
        }

        deleteUserRole(userRoleData).then(() => {
            showOk(unloadMessage);
            
        })
        .catch((e) => {
            console.log(e);
            showError(e?.data?.error)
        })
    }

    const handleAddUserPermission = (permissionId) => {
        setUserPermissions((prevUserPermissions) => {
            if (prevUserPermissions.includes(permissionId)) {
                unloadUserPermission(userId, permissionId);
                return prevUserPermissions.filter((id) => id !== permissionId);
            } else {
                addUserPermission(userId, permissionId);
                return [...prevUserPermissions, permissionId];
            }
        });
    };

    const addUserPermission = (userId, permissionId) =>{
        const userPermissionData = {
            userId,
            permissionId
        }

        saveUserPermission(userPermissionData).then(() => {
            showOk("Permission assigned to user");
        })
        .catch((e) => {
            console.log(e);
            showError(e?.data?.error)
        })
    }

    const unloadUserPermission = (userId, permissionId) =>{
        const userPermissionData = {
            userId,
            permissionId
        }

        deleteUserPermission(userPermissionData).then(() => {
            showOk("Permission unassigned to user");
        })
        .catch((e) => {
            console.log(e);
            showError(e?.data?.error)
        })
    }

    const setMultiplePermissionsToUser = async (userId, permissions) => {
        try {
            const userPermissionsData = {
                userId,
                permissions
            };
    
            await setMultipleUserPermission(userPermissionsData);
        } catch (error) {
            console.error(error);
            showError(error?.response?.data?.error);
        }
    };

    const handleFetchRolesAndPermissions = useCallback(() => {
        getRolesAndPermissions()
        .then(data => {
            setRolesAndPermissions(data.rolesAndPermissions);
        })
        .catch(e => console.error(e));
    },[getRolesAndPermissions]);

    useEffect(() => {
        handleFetchRolesAndPermissions();
    }, [handleFetchRolesAndPermissions]);

    useEffect(() => {
        if(userId){
            userRolesAndPermissions(userId).then(data => {
                const roles = data.userRolesAndPermissions.roles.map(role => role._id);
                const permissions = data.userRolesAndPermissions.permissions.map(permission => permission._id);
                setUserRoles(roles);
                setUserPermissions(permissions);
                setIsUserRolesAndPermissionsLoaded(true)
            }).catch(error => {
                console.error("Error fetching user roles and permissions:", error);
            });
        }
    }, [userId, userRolesAndPermissions]);
    return (
        <Card border="light" className="shadow-sm no-border-radius">
            <Card.Body>
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Access Management</h3>
                <Row xs={1} md={2} lg={3} xl={4} className="g-4">
                    {rolesAndPermissions && rolesAndPermissions.map((role, roleIndex) => (
                    <Col key={roleIndex}>
                        <Card border="light" className="h-100">
                        <Card.Header className="border-bottom border-light d-flex justify-content-between role-header">
                            <h5 className="mb-0">{role.name}</h5>
                            {isUserRolesAndPermissionsLoaded && (
                                <Form.Check
                                    defaultChecked={userRoles.includes(role._id)}
                                    type="switch"
                                    id={`userRoleCheck${role._id}`}
                                    htmlFor={`userRoleCheck${role._id}`}
                                    onChange={() => handleAddUserRole(role._id)}
                                />
                            )}
                        </Card.Header>
                        {role.permissions && (
                            <Card.Body>
                            {role.permissions.map((permission, index) => (
                                <Row key={index} className="align-items-center border-bottom border-light mb-2">
                                    <Col className="ms--2">
                                        <div className='permission-name-holder'>
                                            <h4 className="h6 mb-2">{permission.name}</h4>
                                        </div>
                                    </Col>
                                    {isUserRolesAndPermissionsLoaded && userRoles.includes(role._id) && (
                                        <Col className="col-auto">
                                            <Form.Check
                                                defaultChecked={userPermissions.includes(permission._id)}
                                                type="switch"
                                                id={`userPermissionCheck${permission._id}`}
                                                htmlFor={`userPermissionCheck${permission._id}`}
                                                onChange={() => handleAddUserPermission(permission._id)}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            ))}
                            </Card.Body>
                        )}
                        </Card>
                    </Col>
                    ))}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Button variant="primary" className="ms-auto" onClick={handleClose}>
                    Close
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default UserAccessManagement;
