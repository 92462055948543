import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showOk, showError } from '../components/Alerts';
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import userApi from '../services/user-service';
import { ProfileCardWidget } from '../components/Widgets';
import { isAuthenticated, signoutUser } from '../auth';
import moment from 'moment-timezone';

const UpdatePassword = ({ history }) => {
  const dispatch = useDispatch();
  const { getProfileInfo, userResetPassword, userConfirmResetPassword } = userApi;
  const [userProfile, setUserProfile] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [verifyOtp, setVerifyOtp] = useState(false);
  const [accountPasswordResetOk, setAccountPasswordResetOk] = useState(true);

  const formSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .required('Password is required')
      .min(8, 'Password must have at least 8 characters')
      .notOneOf([Yup.ref('oldPassword')], 'New password must be different from old password')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Minimum eight characters, at least one letter, one number and one special character',
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, getValues, setValue, formState, setError } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = () => {
    setLoading(true);
    setErrorMessage('');
    setAccountPasswordResetOk(true);

    const oldPassword = getValues('oldPassword');
    const newPassword = getValues('newPassword');

    let resetPasswordFormData = new FormData();

    resetPasswordFormData.append('oldPassword', oldPassword);
    resetPasswordFormData.append('newPassword', newPassword);

    userResetPassword(resetPasswordFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setVerifyOtp(true);
          setLoading(false);
          setAccountPasswordResetOk(true);
        } else {
          setAccountPasswordResetOk(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage(e.data.error);
        setLoading(false);
      });
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();

    const otp = getValues('yourOTP');

    if (!otp) {
      setError('yourOTP', {
        type: 'manual',
        message: 'OTP is required. Please check your email.',
      });

      return;
    }

    setLoading(true);

    setErrorMessage('');

    let confirmFormData = new FormData();

    const newPassword = getValues('newPassword');

    confirmFormData.append('otp', otp);
    confirmFormData.append('newPassword', newPassword);

    userConfirmResetPassword(confirmFormData)
      .then((data) => {
        if (data.isValidRequest) {
          setLoading(false);
          showOk("Your password update is complete. You'll be signed out of your account shortly.", 2000);
          setTimeout(() => {
            signoutUser(() => history.push('/'));
          }, 3000);
        } else {
          setErrorMessage('The provided OTP is invalid.');
          setValue('yourOTP', '');
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorMessage('The provided OTP is invalid.');
        setValue('yourOTP', '');
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setPortalPage('Change Password'));

    const userId = isAuthenticated().user?._id;

    getProfileInfo(userId).then((data) => {
      if (data.error) {
        showError(data.error);
      }
      setUserProfile(data);
    });
  }, [dispatch, getProfileInfo]);

  const getFullName = () => {
    if (userProfile)
      return `${userProfile.user?.firstname} ${userProfile.user?.middlename} ${userProfile.user?.lastname}`;
    return '';
  };

  const getJobTitle = () => {
    if (userProfile) return userProfile?.jobTitle;
    return '';
  };

  const getDateHired = () => {
    if (userProfile) return moment(userProfile?.dateHired).format('ll');
    return '';
  };

  const isUserActive = () => {
    if (userProfile) return userProfile.user?.isActive;
    return false;
  };

  return (
    <Row>
      <Col xs={12} xl={8}>
        <Card border="dark" className="bg-white shadow-sm mb-4">
          <Card.Body>
            {!accountPasswordResetOk && (
              <Alert variant="danger">
                Oops, looks like there's an issue with your reset password request. Just get in touch with your HR
                manager, and they'll sort it out for you.
              </Alert>
            )}
            {errorMessage !== '' && <Alert variant="danger">{errorMessage}</Alert>}
            {verifyOtp ? (
              <Form className="mt-4" onSubmit={onSubmitOtp}>
                <Form.Group id="yourOTP" className="mb-4">
                  <Form.Label>Please enter OTP sent in your email</Form.Label>
                  <InputGroup style={{ width: '40%' }}>
                    <Form.Control autoFocus name="yourOTP" type="text" {...register('yourOTP')} />

                    {errors.yourOTP?.message && <small className="invalid-fields">{errors.yourOTP.message}</small>}
                  </InputGroup>
                </Form.Group>
                <div className="mt-3">
                  <Button variant="primary" disabled={loading} type="submit" className="me-1">
                    Submit
                  </Button>
                </div>
              </Form>
            ) : (
              <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group id="oldPassword" className="mb-4">
                  <Form.Label>Old Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="password"
                      {...register('oldPassword')}
                      placeholder="Old Password"
                    />

                    {errors.oldPassword?.message && (
                      <small className="invalid-fields">{errors.oldPassword.message}</small>
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group id="newPassword" className="mb-4">
                  <Form.Label>New Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="new-password"
                      {...register('newPassword')}
                      placeholder="New Password"
                    />
                    {errors.newPassword?.message && (
                      <small className="invalid-fields">{errors.newPassword.message}</small>
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group id="confirmPassword" className="mb-4">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="confirmpassword"
                      {...register('confirmPassword')}
                      placeholder="Confirm Password"
                    />
                    {errors.confirmPassword?.message && (
                      <small className="invalid-fields">{errors.confirmPassword.message}</small>
                    )}
                  </InputGroup>
                </Form.Group>
                <div className="mt-3">
                  <Button variant="primary" disabled={loading} type="submit" className="me-1">
                    Reset password
                  </Button>
                </div>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} xl={4}>
        <Row>
          <Col xs={12}>
            <ProfileCardWidget
              fullName={getFullName()}
              jobTitle={getJobTitle()}
              dateHired={getDateHired()}
              isActive={isUserActive()}
              photoUrl={isAuthenticated().user.photoUrl}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UpdatePassword;
