import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Image, Row, Col } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { showOk, showError } from '../components/Alerts';
import { Button, Modal } from '@themesberg/react-bootstrap';
import billingApi from '../services/billing-service';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ImportBillingFile = (props) => {
  const { showImportBillingEvent, refreshList } = props;
  const billingExcelImg = process.env.REACT_APP_EXCEL_IMAGE;
  const [showDefault, setShowDefault] = useState(true);
  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [imgExcelData, setImgExcelData] = useState('');
  const [billingExcelFile, setBillingExcelFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { uploadBillingDocument } = billingApi;

  const formSchema = Yup.object().shape({
    invoiceNumber: Yup.string().required('Invoice is required'),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'all',
  };
  
  const { register, handleSubmit, getValues, formState } = useForm(formOptions);

  const { errors } = formState;

  const handleClose = () => {
    setShowDefault(false);
    showImportBillingEvent(false);
  };

  const onUploadDataChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      if (!file.name.toLowerCase().match(/\.(xls|xlsx)$/i)) {
        showError('File is not supported. You must select excel file.');
        return false;
      }

      setBillingExcelFile(e.target.files[0]);

      setImgExcelData(billingExcelImg);
    }
  };

  const handleUploadBillingDocument = () => {
    const formData = new FormData();

    var formValues = getValues();

    for (const key in formValues) {
        if (key === 'field') {
            let formValue = formValues[key][1];
            formData.append(key, formValue);
        } else {
            let formValue = formValues[key];
            formData.append(key, formValue);
        }
    }

    formData.append('file', billingExcelFile);
    uploadBillingDocument(formData)
      .then(() => {
        showOk('Billing import successful');
        handleClose();
        refreshList();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal as={Dialog} backdropClassName="" centered show={showDefault}>
        <Header>
          <Title className="h6">Please upload billing excel document</Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Header>
        <Form onSubmit={handleSubmit(handleUploadBillingDocument)}>
          <Body>
            <Row className='align-items-start'>
              <Col md={12} className="mb-3">
                <Form.Group id="invoiceNumber">
                    <Form.Label className="light-font">Invoice Number</Form.Label>
                    <Form.Control 
                        type="text" 
                        name="invoiceNumber" 
                        placeholder="Billing Invoice Number"
                    {...register('invoiceNumber')} />
                    {errors.invoiceNumber?.message && <small className="invalid-fields">{errors.invoiceNumber.message}</small>}
                </Form.Group>
              </Col>
            </Row>
            <div className="d-xl-flex align-items-center">
              {imgExcelData && (
                <div className="user-avatar xl-avatar">
                  <Image fluid rounded src={imgExcelData} />
                </div>
              )}
              <div className="file-field">
                <div className="d-flex justify-content-xl-center ms-xl-3">
                  <div className="d-flex">
                    <span className="icon icon-md">
                      <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                    </span>
                    <input type="file" accept=".xls,.xlsx" name="uploadDtrFile" onChange={onUploadDataChange} />
                    <div className="d-md-block text-start">
                      <div className="fw-normal text-dark mb-1">Choose Excel File</div>
                      <div className="text-gray small">xls or xlsx file</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Body>
          <Footer>
            <Button type="submit" disabled={!billingExcelFile || loading} variant="secondary">
              Upload
            </Button>
            <Button variant="primary" className="ms-auto" onClick={handleClose}>
              Close
            </Button>
          </Footer>
        </Form>
    </Modal>
  );
};

export default ImportBillingFile;
