import React, { useEffect, useState } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card, Row, Col, InputGroup, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faSearch } from '@fortawesome/free-solid-svg-icons';
import BillingDatatable from './billingdatatable';
import ImportBillingFile from './importbillingfile';
import billingApi from '../services/billing-service';
import { hasPermission } from '../auth/UserPermissionTypes';
import { PROCESS_BILLING_MANAGER_IMPORT_BILLING_DOCUMENT } from '../utils/constants';

function BillingProcessor() {
  const dispatch = useDispatch();

  const { getPendingGeneratedBillings } = billingApi;

  const [showImportData, setShowImportData] = useState(false);
  const [pendingBillingList, setPendingBillingList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const onkeyPressSearch = (e) => {
    if (e.key === 'Enter') {
      onSearchGeneratedBilling();
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  const onSearchGeneratedBilling = async () => {

    const listRes = await getPendingGeneratedBillings(searchTerm);

    setPendingBillingList(listRes.results);
  };

  useEffect(() => {
    getPendingGeneratedBillings().then((res) => {
      setPendingBillingList(res.results);
    });
  }, [getPendingGeneratedBillings, setPendingBillingList]);

  useEffect(() => {
    dispatch(setPortalPage('Billing Processor'));
  }, [dispatch]);

  return (
    <>
      {showImportData && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <ImportBillingFile showImportBillingEvent={setShowImportData} refreshList={onSearchGeneratedBilling}/>
        </div>
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0 search-block-width-400">
          <p className="mb-0">Generate billing documents by uploading excel file.</p>
        </div>
        {hasPermission(PROCESS_BILLING_MANAGER_IMPORT_BILLING_DOCUMENT) && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Button variant="outline-primary" size="sm" onClick={() => setShowImportData(true)}>
                <FontAwesomeIcon icon={faFileImport} className="me-2" /> Import Excel File
              </Button>
            </ButtonGroup>
          </div>
        )}        
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                onKeyPress={(e) => onkeyPressSearch(e)}
                value={searchTerm}
                onChange={onSearchChange}
                placeholder="Search"
              />
              <Button size="sm" onClick={() => onSearchGeneratedBilling()}>
                Search
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <Card border="light" className="shadow-sm no-border-radius">
        <BillingDatatable 
          pendingGeneratedBillings={pendingBillingList} 
          search={searchTerm}
          refreshList={onSearchGeneratedBilling}
        />
      </Card>
    </>
  );
}

export default BillingProcessor;
