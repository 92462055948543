import React, { useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import adminRoutes from './routes';
import PrivateRoute from '../auth/PrivateRoute';
import NotFound from '../components/NotFound';
import Dashboard from '../core/Dashboard';
import AdminNavbar from './AdminNavbar';
import AdminSideBar from './AdminSideBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import { useSelector } from 'react-redux';
import AppFooter from '../components/Footer';
import { hasRoles } from '../auth/UserPermissionTypes';

function Admin() {
  const location = useLocation();
  const showAdminLoader = useSelector((state) => state.blanket.showAdminLoader);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/myboard') {
        if(hasRoles(prop.roles)) return <PrivateRoute path={prop.layout + prop.path} component={prop.component} key={key} />
        return null;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {showAdminLoader && <span className="loader"></span>}
      <ToastContainer theme="colored" position="bottom-right" hideProgressBar />
      <AdminSideBar routes={adminRoutes} showSideMenu={setShowSideMenu}/>
      <main className={`${showSideMenu ? 'content' : 'collapse-content'}`}>
        <AdminNavbar />
        <Switch>
          {location.pathname !== '/myboard' && getRoutes(adminRoutes)}
          {(location.pathname === '/myboard' || location.pathname === '/myboard/') && (
            <PrivateRoute path="/myboard" component={Dashboard} />
          )}
          <PrivateRoute component={NotFound} />
        </Switch>
        <AppFooter />
      </main>
      
    </>
  );
}

export default Admin;
