import { USER_COUNT, USER_TOTAL_PAGES } from '../actions/types';

const initialState = {
  count: 0,
  totalPages: 0,
};

const userReducer = (userState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_COUNT:
      return { ...userState, count: payload };
    case USER_TOTAL_PAGES:
      return { ...userState, totalPages: payload };
    default:
      return userState;
  }
};

export default userReducer;
