import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray',
    },
    buttonsStyling: false,
  }),
);

export const showOk = async (message, timeOutInterval = null) => {
  if (timeOutInterval) {
    setTimeout(() => {
      SwalWithBootstrapButtons.close();
    }, timeOutInterval);
  }

  await SwalWithBootstrapButtons.fire({
    icon: 'success',
    text: message,
  });
};

export const showError = async (message) => {
  await SwalWithBootstrapButtons.fire({
    icon: 'error',
    title: message ? 'Opps..' : 'Sorry, something went wrong.',
    text: message,
  });
};

export const confirmDeleteDialog = async () => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Deleted!',
        text: 'Data has been deleted.',
        icon: 'success',
      });

      return true;
    }

    return false;
  });
};

export const confirmArchiveDialog = async (text, confirmButtonText, callbackTitle, callbackText) => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: callbackTitle,
        text: callbackText,
        icon: 'success',
      });

      return true;
    }

    return false;
  });
};

export const confirmArchiveDialogEmptyCallback = async (text, confirmButtonText) => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }

    return false;
  });
};

export const confirmApproveDocumentDialog = async (text, confirmButtonText, callbackTitle, callbackText) => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'question',
    title: 'Billing Approval',
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: callbackTitle,
        text: callbackText,
        icon: 'success',
      });

      return true;
    }

    return false;
  });
};

export const confirmBillingDocumentDialog = async (text, confirmButtonText) => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'question',
    title: 'Billing Approval',
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }

    return false;
  });
};

export const confirmQuotationDocumentDialog = async (text, confirmButtonText) => {
  return await SwalWithBootstrapButtons.fire({
    icon: 'question',
    title: 'Quotation Approval',
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }

    return false;
  });
};
