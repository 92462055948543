import { ADMIN_PORTAL_PAGE, HIDE_ADMIN_PORTAL_LOADER, SHOW_ADMIN_PORTAL_LOADER } from './types';

export const setPortalPage = (selectedPage) => (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PORTAL_PAGE,
      payload: selectedPage,
    });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const showLoader = () => (dispatch) => {
  dispatch({
    type: SHOW_ADMIN_PORTAL_LOADER,
    payload: true,
  });
};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: HIDE_ADMIN_PORTAL_LOADER,
    payload: false,
  });
};
