import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { showOk, showError } from '../components/Alerts';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clientApi from '../services/client-service';

const ClientUploadDocument = (props) => {
  const { 
    clientDocumentData, 
    clientId,
    showUploadDocument, 
    isEditMode, 
    refreshDocuments,
    setClientDocumentData } = props;
  const pdfDocumentImage = process.env.REACT_APP_PDF_DOCUMENT_IMAGE;
  const wordDocumentImage = process.env.REACT_APP_WORD_DOCUMENT_IMAGE;
  const [showDefault, setShowDefault] = useState(true);
  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [imgDocumentData, setImgDocumentData] = useState('');
  const [toUploadDocumentFile, setToUploadDocumentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { uploadDocument, updateDocument } = clientApi;

  const uploadDocumentSchema = Yup.object().shape({
    contractDetail: Yup.string().required('Document name or details is required'),
  });

  const formOptions = {
    resolver: yupResolver(uploadDocumentSchema),
    mode: 'all',
    defaultValues: {
      contractDetail: clientDocumentData?.contractDetail,
    }
  };

  const { register, handleSubmit, getValues, formState } = useForm(formOptions);

  const { errors } = formState;

  const handleClose = () => {
    setShowDefault(false);
    showUploadDocument(false);
    setClientDocumentData(null);
  };

  const onUploadDocumentChange = (e) => {
    if (e.target.files[0]) {
        const file = e.target.files[0];

        if (!file.name.toLowerCase().match(/\.(pdf|doc|docx)$/i)) {
            showError('File is not supported. You must select a PDF or Word document file.');
            return false;
        }

        setToUploadDocumentFile(e.target.files[0]);

        const fileExtension = file.name.toLowerCase().split('.').pop();

        handleSetImageDocument(fileExtension);
    }
  };

  const handleSetImageDocument = useCallback((extension) => {
    if (extension === 'pdf') {
      setImgDocumentData(pdfDocumentImage);
    } else if (extension === 'doc' || extension === 'docx') {
      setImgDocumentData(wordDocumentImage);
    }
  }, [pdfDocumentImage, wordDocumentImage]);

  useEffect(() => {
    if(clientDocumentData){
      const fileType = clientDocumentData.fileType;
      handleSetImageDocument(fileType);
    }
  }, [clientDocumentData, handleSetImageDocument]);

  const handleUploadDocument = () => {
    setLoading(true);
    const formData = new FormData();
    const contractDetail = getValues('contractDetail');

    if(!clientDocumentData){
      formData.append('clientId', clientId);
      formData.append('contractDetail', contractDetail);
      formData.append('file', toUploadDocumentFile);
      uploadDocument(formData)
        .then(() => {
          showOk('Document uploaded successfully');
          refreshDocuments(clientId);
          handleClose();
          setLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error)
          setLoading(false);
        });
    }
    else{
      formData.append('agreementId', clientDocumentData._id);
      formData.append('contractDetail', contractDetail);
      formData.append('file', toUploadDocumentFile);
      updateDocument(formData)
        .then(() => {
          showOk('Document updated successfully');
          refreshDocuments(clientId);
          handleClose();
          setLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error)
          setLoading(false);
        });
    }
  };


  return (
    <Modal as={Dialog} backdropClassName="" centered show={showDefault}>
      <Form onSubmit={handleSubmit(handleUploadDocument)}>
        <Header>
          <Title className="h6">Client document upload</Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Header>
        <Body>
          <Row>
            <Col md={12} className="mb-4">
                <Form.Group id="contractDetail">
                  <Form.Label className="light-font">Document name or description</Form.Label>
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      name="contractDetail"
                      placeholder="Document name or description"
                      {...register('contractDetail')}
                    />
                  ) : (
                    <p className="mb-0 bold-700">{clientDocumentData.contractDetail || ''}</p>
                  )}
                  {errors.contractDetail?.message && (
                    <small className="invalid-fields">{errors.contractDetail.message}</small>
                  )}
                </Form.Group>
            </Col>
          </Row>
          <div className="d-xl-flex align-items-center">
            {imgDocumentData && (
                <div className="user-avatar xl-avatar">
                    <Image fluid rounded src={imgDocumentData} />
                </div>
            )}
            <div className="file-field">
                <div className="d-flex justify-content-xl-center ms-xl-3">
                    <div className="d-flex">
                        <span className="icon icon-md">
                            <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                        </span>
                        <input type="file" accept=".pdf,.doc,.docx" name="uploadClientDocument" onChange={onUploadDocumentChange} />
                        <div className="d-md-block text-start">
                            <div className="fw-normal text-dark mb-1">Choose client document file</div>
                            <div className="text-gray small">PDF or Word document files</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Body>
        <Footer>
          <Button type="submit" disabled={(!clientDocumentData && !toUploadDocumentFile) || loading} variant="secondary">
            {`${clientDocumentData ? 'Save' : 'Upload'}`}
          </Button>
          <Button variant="primary" className="ms-auto" onClick={handleClose}>
            Close
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default ClientUploadDocument;
