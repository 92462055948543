import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Signin from './user/Signin';
import PrivateRoute from './auth/PrivateRoute';
import Admin from './admin/Admin';
import NotFound from './components/NotFound';
import ServerError from './components/ServerError';
import { ActivateAccount } from './user/ActivateAccount';
import ForgotPassword from './user/ForgotPassword';
import ResetPassword from './user/ResetPassword';

function MainRouter() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/active-account" component={ActivateAccount} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route
          path={'/(.+)'}
          render={() => (
            <Switch>
              <PrivateRoute path="/myboard" component={Admin} />
              <Route path="/server-error" component={ServerError} />
              <Route path="/404" component={NotFound} />
              <Redirect to="/404" />
            </Switch>
          )}
        />
      </Switch>
    </>
  );
}

export default MainRouter;
