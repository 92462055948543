import React, { useState } from "react";
import './styles.scss'
import { Card, Button, ButtonGroup, Dropdown } from '@themesberg/react-bootstrap';
import { Col, Row, Table } from "react-bootstrap";
import { faArchive, faCheck, faEdit, faEllipsisH, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmArchiveDialog, confirmArchiveDialogEmptyCallback } from "../components/Alerts";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import PortalPagination from "../components/PortalPagination";
import RateBracketArchiveReason from "./ratebracketarchivereason";
import rateBracketApi from '../services/rate-bracket-service';
import { hasPermission } from "../auth/UserPermissionTypes";
import { RATE_MANAGER_ADD_RATE_CATEGORY, RATE_MANAGER_DELETE_RATE_CATEGORY, RATE_MANAGER_UPDATE_RATE_CATEGORY, RATE_MANAGER_VIEW_RATE_CATEGORY } from "../utils/constants";

const RateDatatable = (props) => {
    const { 
        rateBracketList, 
        selectedPage,
        pagingEvent,
        rateCategories, 
        addCategoryEvent, 
        handleEditRateCategory, 
        deleteRateCategory,
        setToEditRateBracket,
        refreshList
    } = props;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [selectedRateBracketId, setSelectedRateBracketId] = useState(null);
    const totalRecords = useSelector((state) => state.rateBracketState.count) || 0;
    const [showArchiveReason, setShowArchiveReason] = useState(false);
    const [rateBracketToArchive, setRateBracketToArchive] = useState(null);

    const { setRateBracketStatus } = rateBracketApi;

    const handleDeleteRateCategory = async (id) => {
        let text = 'Do you want to delete this rate category?';
        let confirmBtnText = 'Yes, delete it!';
        let callbackTitle = 'Deleted!';
        let callbackText = 'Rate category has been Deleted!';
    
        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
            deleteRateCategory(id)
        }
    };

    const toSetEditBracket = (item) => {
        setToEditRateBracket(item)
        setSelectedRateBracketId(item._id);
    }

    const handleSetRateBracketStatus = async (id) => {
        let text = 'Do you want to archive this rate bracket?';
        let confirmBtnText = 'Yes, archive it!';
    
        var statusConfirm = await confirmArchiveDialogEmptyCallback(text, confirmBtnText);
        if (statusConfirm) {
    
          setShowArchiveReason(true);
          setRateBracketToArchive(id);
        }
    };

    const handleCancelArchive = () => {
        setShowArchiveReason(false);
        setRateBracketToArchive(null);
    }

    const handleReActivateRateBracket = async (id) => {

        let text = 'Do you want to reactivate this rate bracket?';
        let confirmBtnText = 'Yes, reactivate it!';
        let callbackTitle = 'Re-Activate!';
        let callbackText = 'Rate bracket has been Activated!';

        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
            deleteRateCategory(id)

            let rateBracketData = new FormData();
        
            rateBracketData.append('rateBracketId', id);
            rateBracketData.append('status', true);
        
            setRateBracketStatus(rateBracketData).then(() => {
                refreshList();
            });
        }
    }

    return (
        <>
            {showArchiveReason && (
                <RateBracketArchiveReason rateBracketId={rateBracketToArchive} cancelEvent={handleCancelArchive} refreshList={refreshList} />
            )}
            <Row className="spacing-20">
                <Col md={hasPermission(RATE_MANAGER_VIEW_RATE_CATEGORY) ? 8 : 12} className="mb-3 mt-4">
                    <Card border="light" className="table-wrapper table-responsive shadow-sm no-border-radius">
                        <Card.Body className="pt-0">
                            <Table hover className="user-table align-items-center">
                                <thead>
                                    <tr>
                                        <th className="border-bottom">Name</th>
                                        <th className="border-bottom">Rate</th>
                                        <th className="border-bottom">Start Quantity</th>
                                        <th className="border-bottom">End Quantity</th>
                                        <th className="border-bottom">Status</th>
                                        <th className="border-bottom">Rate Category</th>
                                        <th className="border-bottom">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rateBracketList.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading && selectedRateBracketId == null ? (
                                                        <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : (
                                                        item.name
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-bold">
                                                    {loading && selectedRateBracketId == null ? <Skeleton wrapper={SkeletonWrapper} /> : item.rate}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading && selectedRateBracketId == null ? <Skeleton wrapper={SkeletonWrapper} /> : item.startQuantity}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading && selectedRateBracketId == null ? <Skeleton wrapper={SkeletonWrapper} /> : item.endQuantity}
                                                </span>
                                            </td>
                                            <td>
                                                <span className={`fw-normal text-success ${item.isActive ? 'text-success' : 'text-danger'}`}>
                                                    {loading && selectedRateBracketId == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                    ) : item.isActive ? (
                                                    'Active'
                                                    ) : (
                                                    'In-Active'
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">
                                                    {loading && selectedRateBracketId == null ? <Skeleton wrapper={SkeletonWrapper} /> : 
                                                        `${item.rateCategory.name} - ${item.rateCategory.categoryUnit}`
                                                    }
                                                </span>
                                            </td>
                                            <td>
                                            {loading == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                <Dropdown as={ButtonGroup}>
                                                    <Dropdown.Toggle as={Button} split={true} variant="link" className="text-dark m-0 p-0">
                                                        <span className="icon icon-sm">
                                                        <FontAwesomeIcon icon={ faEllipsisH} className="icon-dark" />
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="more-left-dropdown-menu">
                                                        <Dropdown.Item onClick={() => toSetEditBracket(item)}>
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                                        </Dropdown.Item>
                                                        {item.isActive && (
                                                            <Dropdown.Item className="text-danger" onClick={() => handleSetRateBracketStatus(item._id)}>
                                                                <FontAwesomeIcon icon={faArchive} className="me-2" /> Archive
                                                            </Dropdown.Item>
                                                        )}
                                                        {!item.isActive && (
                                                            <Dropdown.Item className="text-success" onClick={() => handleReActivateRateBracket(item._id)}>
                                                                <FontAwesomeIcon icon={faCheck} className="me-2" /> Re-Activate
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </Table>
                            {!loading && (
                                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={pagingEvent} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                {hasPermission(RATE_MANAGER_VIEW_RATE_CATEGORY) && (
                    <Col md={4} className="mb-3 mt-4">
                        <Card border="light" className="shadow-sm">
                            <Card.Header className="border-bottom border-light">
                                <Row>
                                    <Col md={8}>
                                        <h5>Rate Categories</h5>
                                    </Col>
                                    {hasPermission(RATE_MANAGER_ADD_RATE_CATEGORY) && (
                                        <Col md={4}>
                                            <Button variant="primary" size="sm" className="pull-right" onClick={() => addCategoryEvent()}>
                                                <FontAwesomeIcon icon={faPlus} className="me-1" /> Add
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-block">
                                    {rateCategories.map((item, index) => (
                                        <div key={index} className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
                                            <div>
                                                <h6 className="mb-0">{item.name} - {item.categoryUnit}</h6>
                                            </div>
                                            <div>
                                                <ButtonGroup>
                                                    {hasPermission(RATE_MANAGER_UPDATE_RATE_CATEGORY) && (
                                                        <Button variant="primary" size="sm" onClick={() => handleEditRateCategory(item)}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    )}
                                                    {hasPermission(RATE_MANAGER_DELETE_RATE_CATEGORY) && (
                                                        <Button variant="danger" size="sm" onClick={() => handleDeleteRateCategory(item._id)}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </Button>
                                                    )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
}

export default RateDatatable;