import React from "react";
import { Card, Row, Col, Image, Table } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from 'moment-timezone';
import { formatCurrency } from "../utils/formatter";

const SourcingTransactionsPreview = (props) => {
    const { 
        startDate, 
        endDate, 
        reportList, 
        overallTotalRevenue, 
        overallTotalCOS,
        overallTotalGrossProfit
    } = props;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>List of Sourcing Transactions (With Revenue, COS, Gross Profit & Percentage of GP)</h1>
                    <div className="left">
                        <p>Start Date: <b>{formattedStartDate}</b></p>
                        <p>End Date: <b>{formattedEndDate}</b></p>
                    </div>
                </div>
                <Table hover className="billing-table-list align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">Quotation Number</th>
                            <th className="border-bottom">Client Name</th>
                            <th className="border-bottom">Revenue</th>
                            <th className="border-bottom">COS</th>
                            <th className="border-bottom">Gross Profit</th>
                            <th className="border-bottom">GP%</th>
                            <th className="border-bottom">Agent Name</th>
                            <th className="border-bottom">Approved Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="fw-normal">{item.quotationNumber}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{item.clientName}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(parseFloat(item.revenue)) || 0.00}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(parseFloat(item.cos)) || 0.00}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{formatCurrency(parseFloat(item.grossProfit)) || 0.00}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{`${item.grossProfitPercentage}%`}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{item.agentName}</span>
                                </td>
                                <td>
                                    <span className="fw-normal">{moment(item.approvedDate).format('YYYY-MM-DD')}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="mt-4">
                    <div className="mt-4">
                        <Row>
                            <Col md={4}>
                                <h4><strong>Overall Total Revenue:</strong> {`${formatCurrency(parseFloat(overallTotalRevenue)) || 0.00}`}</h4>
                            </Col>
                            <Col md={4}>
                                <h4><strong>Overall Total COS:</strong> {`${formatCurrency(parseFloat(overallTotalCOS)) || 0.00}`}</h4>
                            </Col>
                            <Col md={4}>
                                <h4><strong>Overall Total Gross Profit:</strong> {`${formatCurrency(parseFloat(overallTotalGrossProfit)) || 0.00}`}</h4>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default SourcingTransactionsPreview;