
import React from 'react';
import { createRoot } from 'react-dom/client';
// core styles
import './scss/portal-main.scss';
// vendor styles
import 'react-datetime/css/react-datetime.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AuthVerify from './auth/AuthVerify';

export const history = createBrowserHistory();

const container = document.getElementById('root');

const root = createRoot(container);

const marketingStyle ="font-family: monospace; color: blue;";
console.log(`%c  ++++++++++++++++++ ++++++++++++++++ ++++++            ++++++ ++++++++++++++++        ++++   ++++++           ++++++`, marketingStyle);
console.log(`%c  +                + +              + +     +          +     + +              +       +    +  +     +         +     +`, marketingStyle);
console.log(`%c  +++++++    +++++++ +     ++++++++++   +     +      +     +   +     +++++    +      +      +   +     +      +     + `, marketingStyle);
console.log(`%c        +    +       +     +              +     +  +     +     +     +   +    +     +        +    +     +  +     +   `, marketingStyle);
console.log(`%c        +    +       +     +               +     +     +       +     +++++    +    +     +    +     +     +    +     `, marketingStyle);
console.log(`%c        +    +       +     +                 +       +         +              +   +     + +    +      +       +      `, marketingStyle);
console.log(`%c        +    +       +     +                  +     +          +     ++++++++++  +     +++++    +      +     +       `, marketingStyle);
console.log(`%c        +    +       +     +                  +     +          +     +          +                +     +     +       `, marketingStyle);
console.log(`%c        +    +       +     +                  +     +          +     +         +      ++++++      +    +     +       `, marketingStyle);
console.log(`%c  +++++++    +++++++ +     ++++++++++         +     +          +     +        +      +      +      +   +     +       `, marketingStyle);
console.log(`%c  +                + +              +         +     +          +     +       +      +        +      +  +     +       `, marketingStyle);
console.log(`%c  ++++++++++++++++++ ++++++++++++++++         +++++++          +++++++      ++++++++          ++++++++ +++++++       `, marketingStyle);
console.log(`%c+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++`, marketingStyle);
console.log("%c Web Development Services", marketingStyle);
console.log("%c Visit us at: http://www.services-icypay.com/", marketingStyle);

root.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
    <AuthVerify />
  </Router>,
);
