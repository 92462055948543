import React, {  } from 'react';
import { Modal } from '@themesberg/react-bootstrap';
import './styles.scss';
import ProductForm from '../products/productform';

const SalesAddProduct = (props) => {
    const { productCategories, cancelEdit, refreshList } = props;
    const { Dialog } = Modal;

    return (
        <Modal size="xl" as={Dialog} backdropClassName="" centered show={true}>
            <ProductForm 
                productCategories={productCategories} 
                cancelEdit={cancelEdit} 
                refreshList={refreshList}
            />
        </Modal>
    );
};

export default SalesAddProduct;
