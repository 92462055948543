import { 
    CLIENT_BILLINGS_COUNT, 
    CLIENT_BILLINGS_TOTAL_PAGES,
    CLIENT_SALES_QUOTATION_COUNT,
    CLIENT_SALES_QUOTATION_TOTAL_PAGES
  } from './types';
  
  export const setClientBillingCount = (count) => (dispatch) => {
    try {
      const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  
      const totalPages = Math.ceil(count / limit);
  
      dispatch({
        type: CLIENT_BILLINGS_COUNT,
        payload: count,
      });
  
      dispatch(setClientBillingsTotalPages(totalPages));
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const setClientBillingsTotalPages = (totalPages) => (dispatch) => {
    try {
      dispatch({
        type: CLIENT_BILLINGS_TOTAL_PAGES,
        payload: totalPages,
      });
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const setClientSalesQuotationCount = (count) => (dispatch) => {
    try {
      const limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  
      const totalPages = Math.ceil(count / limit);
  
      dispatch({
        type: CLIENT_SALES_QUOTATION_COUNT,
        payload: count,
      });
  
      dispatch(setClientSalesQuotationTotalPages(totalPages));
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const setClientSalesQuotationTotalPages = (totalPages) => (dispatch) => {
    try {
      dispatch({
        type: CLIENT_SALES_QUOTATION_TOTAL_PAGES,
        payload: totalPages,
      });
  
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
  