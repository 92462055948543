import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEye } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { formatCurrency } from "../utils/formatter";
import BillingDocumentHolder from "./billingdocumentholder";

const BillingDatatable = (props) => {
    const { 
      pendingGeneratedBillings, 
      search, 
      refreshList 
    } = props;

    const loading = useSelector((state) => state.blanket.showAdminLoader);

    const [selectedBillingDocument, setSelectedBillingDocument] = useState(null);

    const [totalValues, setTotalValues] = useState({
        sumOfCBM: 0,
        sumOfSensitiveCharge: 0,
        sumOfForklift: 0,
        sumOfInland: 0,
        sumOfGrossWeight: 0,
        grossAmount: 0,
        totalBillableAmount: 0
    });

    useEffect(() => {
        if (pendingGeneratedBillings && pendingGeneratedBillings.length > 0) {
            const totals = pendingGeneratedBillings.reduce((acc, billing) => {
                acc.sumOfCBM += parseFloat(billing.sumOfCBM) || 0;
                acc.sumOfSensitiveCharge += parseFloat(billing.sumOfSensitiveCharge) || 0;
                acc.sumOfForklift += parseFloat(billing.sumOfForklift) || 0;
                acc.sumOfInland += parseFloat(billing.sumOfInland) || 0;
                acc.sumOfGrossWeight += parseFloat(billing.sumOfGrossWeight) || 0;
                acc.grossAmount += parseFloat(billing.grossAmount) || 0;
                acc.totalBillableAmount += parseFloat(billing.totalBillableAmount) || 0;
                return acc;
            }, {
                sumOfCBM: 0,
                sumOfSensitiveCharge: 0,
                sumOfForklift: 0,
                sumOfInland: 0,
                sumOfGrossWeight: 0,
                grossAmount: 0,
                totalBillableAmount: 0
            });

            setTotalValues(totals);
        }
    }, [pendingGeneratedBillings]);

    const handleSelecteBillingDocument = (id) => {
      setSelectedBillingDocument(id);
    }

    const handleCancelView = () => {
      setSelectedBillingDocument(null);
    }

    const TableRow = (document) => {
        const { displayName } = document.clientData;
        const { 
          _id,
          sumOfCBM, 
          sumOfSensitiveCharge, 
          sumOfForklift, 
          sumOfInland, 
          sumOfGrossWeight,
          grossAmount,
          totalBillableAmount,
          status
         } = document;

         let rate = 0;

         if(document.appliedRateData) rate = document.appliedRateData.rate;

         if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
          const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
          rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
        }

         const lowerCaseStatus = status.toLowerCase();

        const statusVariant = lowerCaseStatus === "approved" ? "success"
          : lowerCaseStatus === "pending" ? "warning"
            : lowerCaseStatus === "canceled" ? "danger" : "primary";
    
        return (
          <>
            <tr>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      displayName || ''
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(sumOfCBM)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(sumOfForklift)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(sumOfInland)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(sumOfGrossWeight)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(rate)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(grossAmount)) || 0.00
                    )}
                </span>
              </td>
              <td>
                <span className="fw-normal">
                    {loading && selectedBillingDocument == null ? (
                      <Skeleton wrapper={SkeletonWrapper} />
                    ) : (
                      formatCurrency(parseFloat(totalBillableAmount)) || 0.00
                    )}
                </span>
              </td>
              <td>
                {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                ) : (
                    <span className={`fw-normal text-${statusVariant}`}>
                        {status}
                    </span>
                )}
              </td>
              <td>
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="more-left-dropdown-menu">
                    <Dropdown.Item onClick={() => handleSelecteBillingDocument(_id)}>
                      <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            {selectedBillingDocument === _id && (
              <tr>
                <td colSpan={11}>
                  <BillingDocumentHolder 
                    pendingBillingList={pendingGeneratedBillings}
                    hideEvent={handleCancelView} 
                    billingDocumentData={document} 
                    refreshList={refreshList} 
                    canModify={true}/>
                </td>
              </tr>
            )}
          </>
        );
    };

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
                <Table hover className="billing-table-list align-items-center">
                    <thead>
                        <tr>
                          <th className="border-bottom">Display Name</th>
                          <th className="border-bottom">Sum of CBM</th>
                          <th className="border-bottom">Sum of Sensitive Charge</th>
                          <th className="border-bottom">Sum of Forklift</th>
                          <th className="border-bottom">Sum of Inland</th>
                          <th className="border-bottom">Sum of Gross Wt</th>
                          <th className="border-bottom">Rate</th>
                          <th className="border-bottom">Freight Amount</th>
                          <th className="border-bottom">Billable Amount</th>
                          <th className="border-bottom">Status</th>
                          <th className="border-bottom"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingGeneratedBillings.map((t, i) => <TableRow key={`transaction-${i}`} {...t} />)}
                        {pendingGeneratedBillings.length > 0 && !search && (
                          <tr className="grand-total-row">
                            <td>
                              <span className="fw-bold">Grand Total</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.sumOfCBM)}</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.sumOfSensitiveCharge)}</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.sumOfForklift)}</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.sumOfInland)}</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.sumOfGrossWeight)}</span>
                            </td>
                            <td></td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.grossAmount)}</span>
                            </td>
                            <td>
                              <span className="fw-bold">{formatCurrency(totalValues.totalBillableAmount)}</span>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                        {pendingGeneratedBillings.length === 0 && (
                          <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default BillingDatatable;
